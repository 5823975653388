
import moment from 'moment'
import {getAvatar} from 'utilis/avatars'


export const formatActivityMessages = (data) => {

    let activities_ = data;
    activities_ = getAllReplies(data)
    activities_ = getAllThreadConversations(activities_)
    activities_ = groupConversationsByChannels(activities_)
    activities_ = splitConversations(activities_)


    const data_ = (activities_.filter(item => item.messages.length > 0).sort((b, a) => new Date(a.messages[0].timestamp) - new Date(b.messages[0].timestamp)).map(item => {

        const author = item.messages[0].member[0];
        const participants = item.messages.map(message => {
                return [message.member[0], ...message.replies.map(item2 => item2.member[0])]
            }).flat().filter(onlyUnique).filter(item => item.memberId !== author.memberId);
        
        const channelName = item.messages[0].channel[0].name;
        const guildName = item.messages[0]?.source[0]?.name;
        const totalMembers = (!author?.metadata?.isTeamMember ? 1 : 0) + participants.filter(item => !(item.metadata && item.metadata.isTeamMember)).length
        const totalTeamMembers = (author?.metadata?.isTeamMember ? 1 : 0) + participants.filter(item => (item.metadata && item.metadata.isTeamMember)).length

        const totalPositive = item.messages.reduce((acc, curr) => {
            let message = (curr.metrics && curr.metrics.sentiment && curr.metrics.sentiment === 'positive' ) ? 1 : 0;
            let replies = (curr.replies && curr.replies.length > 0) ? curr.replies.reduce((acc, curr) => {
                let reply = (curr.metrics && curr.metrics.sentiment && curr.metrics.sentiment === 'positive' ) ? 1 : 0;
                return acc + reply;
            }, 0) : 0;
            return acc + message + replies
        }, 0)
        const totalNegative = item.messages.reduce((acc, curr) => {
            let message = (curr.metrics && curr.metrics.sentiment && curr.metrics.sentiment === 'negative' ) ? 1 : 0;
            let replies = (curr.replies && curr.replies.length > 0) ? curr.replies.reduce((acc, curr) => {
                let reply = (curr.metrics && curr.metrics.sentiment && curr.metrics.sentiment === 'negative' ) ? 1 : 0;
                return acc + reply;
            }, 0) : 0;
            return acc + message + replies
        }, 0)

        const data = {
            activities: item.messages.sort(timestampSort).map(item2 => ({
                title: item2.member[0].username,
                description: item2.content,
                img: getAvatar(item2.member[0].memberId, item2.member[0].avatar, item2.member[0].username),
                time: moment(item2.timestamp).fromNow(),
                activityId: item2.activityId,
                channelId: item2.channelId,
                sourceId: item2.sourceId,
                tags: (item2.metrics && item2.metrics.sentiment) ? [{value: item2.metrics.sentiment, label: item2.metrics.sentiment, color: item2.metrics.sentiment === 'positive' ? 'success': 'danger'}] : [],
                replies: item2.replies.sort(timestampSort).map(item3 => ({
                    title: item3.member[0].username,
                    description: item3.content,
                    img: getAvatar(item3.member[0].memberId, item3.member[0].avatar, item3.member[0].username),
                    time: moment(item3.timestamp).fromNow(),
                    tags: (item3.metrics && item3.metrics.sentiment) ? [{value: item3.metrics.sentiment, label: item3.metrics.sentiment, color: item3.metrics.sentiment === 'positive' ? 'success': 'danger'}] : [],
                })),
            })),
            metadata: {
                channelName: channelName,
                sourceName: guildName,
                sourceLink: 'https://mothership.com',
                totalMembers: totalMembers,
                totalTeamMembers: totalTeamMembers,
                author: {
                    avatar: getAvatar(author.memberId, author.avatar, author.username),
                    name: author.username,
                    tags: (author.metadata && author.metadata.tags) ? author.metadata.tags.map(item => ({value: item._id, label: item.label})) : [], 
                    link: `/app/member/${author._id}`
                },
                participants: participants.map(item2 => ({
                    avatar: getAvatar(item2.memberId, item2.avatar, item2.username),
                    name: item2.username,
                    tags: (item2.metadata && item2.metadata.tags) ? item2.metadata.tags.map(item => ({value: item._id, label: item.label})) : [],
                    link: `/app/member/${item2._id}`
                })),
                sentimentMetrics: {
                    totalPositive: totalPositive,
                    totalNegative: totalNegative
                }
            }
        }
        return data;
    }))    
    return data_
}




const getAllThreadConversations = (data) => {
    let allActivities = [...data]
    for(let i=0; i<allActivities.length; i++){
        let message = allActivities[i];
        if(message.channel[0].type === 11){
            //this is a guild thread
            const parentMessage = message.channel[0].channelId;
            const parentMessageIndex = allActivities.findIndex(item => item.activityId === parentMessage)
            if(parentMessageIndex > -1){
                //this is a thread and considering this as a reply
                allActivities[parentMessageIndex].replies = [...allActivities[parentMessageIndex].replies, message]
                allActivities.splice(i, 1)
            } else{

            }
        }
    }
    return allActivities
}

const getAllReplies = (data) => {
    
    let mainMessages = data.filter(item => item.type === 0);

    for(let i=0; i<mainMessages.length; i++){
        let message = mainMessages[i];
        message.replies = commentTree(message.activityId, data)
    }
    const leftOverReplies = data.filter(item => !mainMessages.map(item2 => ([item2.activityId, ...item2.replies.map(item3 => item3.activityId)])).flat().includes(item.activityId))
    return [...mainMessages, ...leftOverReplies.map(item => ({...item, content: item.content, replies: []}))]
}

const groupConversationsByChannels = (allActivities) => {
    let conversations = [];
    for(let i=0; i<allActivities.length; i++){
        let message = allActivities[i];
        let messageChanneId = message.channelId
        // if(message.channel[0].type === 11){

        // }
        let conversation = conversations.find(item => item.channelId === messageChanneId)
        if(conversation){
            conversation.messages.push(message)
            if(message.channel[0].type) conversation.channelType = message.channel[0].type
        } else{
            conversations.push({
                channelId: messageChanneId, 
                type: message.channel[0].type, 
                messages: [message],
                sourceId: message.sourceId
            })
        }
    }
    return conversations;
}


const splitConversations = (channelWiseConversation) => {
    //write a function that can split an array if timestamp of two consecutive messages is > 60 mins
    let conversations = [];
    for(let i=0; i<channelWiseConversation.length; i++){
        let conversation = channelWiseConversation[i];
        let messages = conversation.messages;
        const newMessages = [...conversation.messages]
        for(let j=0; j<messages.length; j++){
            let message = messages[j];
            if(message.replies > 5){
                conversations.push({
                    channelId: message.channelId,
                    messages: [message]
                })
                newMessages.splice(j, 1)
            }
            if(j>0 && (new Date(messages[j-1].timestamp).getTime() - new Date(messages[j].timestamp).getTime()) > (1000 * 60 * 60)){
                conversations.push({
                    channelId: message.channelId,
                    messages: newMessages.splice(0, j)
                })
            }
        }
        if(newMessages.length > 0){
            conversations.push({
                channelId: conversation.channelId,
                messages: newMessages
            })
        }
    }
    return conversations;

}


const commentTree = (parentId, data) => {
    const finalMessages = []
    for (let i = 0; i < data.length; i++) {
        const message = data[i];
        if(message?.referencedMessage?.activityId === parentId){
            if(message.metrics.replies > 0){
                finalMessages.push([message, ...commentTree(message.activityId, data)])
            }
            else finalMessages.push(message)
        }
    }
    return finalMessages.flat()
}


const onlyUnique = (value, index, self) => {
    return self.findIndex(v => v._id === value._id) === index;
}


const timestampSort = (a, b) => {
    return new Date(a.timestamp) - new Date(b.timestamp)
}