import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import ScrollToTop from "components/HOC/ScrollToTop";
import AuthRoutes from './nestedRoutes/Auth'
import DashboardRoutes from './nestedRoutes/Dashboard'
import {logPageView} from 'utilis/logEvents'



const AppRouter = (props) => {

    const history = useHistory()

    useEffect(() => {
        logPageView()
        history.listen((location) => {
            logPageView()
        })
    }, [])

    return (
        <>
        <ScrollToTop>
            <Switch>
                <Route path = "/app"><DashboardRoutes /></Route>
                <Route path = "/"><AuthRoutes /></Route>
                <Redirect from = "*" to = "/" />
            </Switch>
        </ScrollToTop>
        </>
    );
}

export default AppRouter;


