import React, {useContext, useEffect, useState} from 'react'
import {logoutSuccess} from 'store/actions/auth'
import {logoutUser} from 'services/user/auth'
import {Store} from 'store'
import {Link} from 'react-router-dom'
import { SwitchButton } from 'components/Forms/Switches'
import { UserAvatar } from 'components/Avatar'

const User = () => {

    const {state, dispatch} = useContext(Store);
    const [darkmode, setDarkmode] = useState(localStorage.getItem('darkmode') === 'true' ? true: false);

    useEffect(() => {
        localStorage.setItem('darkmode', darkmode)
        document.querySelector('html').setAttribute('dark-mode', darkmode ? 'true' : false);
        setColors()
    }, [darkmode])

    const toggleDarkmode = () => {
       
        setDarkmode(!darkmode);
    }

    const setColors = () => {
        if(darkmode){
            document.documentElement.style.setProperty('--background', '#e6f5ff');
            document.documentElement.style.setProperty('--border', '#b7b7b76e');
            document.documentElement.style.setProperty('--light-border', '#b7b7b7');
            document.documentElement.style.setProperty('--dark', '#000');
            document.documentElement.style.setProperty('--shadow', 'rgba(140, 152, 164, .075)');
            document.documentElement.style.setProperty('--shadow-md', 'rgba(140, 152, 164, .125)');
            document.documentElement.style.setProperty('--shadow-lg', 'rgba(140, 152, 164, .175)');
            document.documentElement.style.setProperty('--shadow-xl', 'rgba(140, 152, 164, .25)');
        
        } else{
            document.documentElement.style.setProperty('--background', 'rgb(247, 250, 252)');
            document.documentElement.style.setProperty('--light-border', 'rgba(231, 234, 243, .7)');
            document.documentElement.style.setProperty('--dark', '#131022');
            document.documentElement.style.setProperty('--shadow', 'rgba(140, 152, 164, .075)');
            document.documentElement.style.setProperty('--shadow-md', 'rgba(140, 152, 164, .125)');
            document.documentElement.style.setProperty('--shadow-lg', 'rgba(140, 152, 164, .175)');
            document.documentElement.style.setProperty('--shadow-xl', 'rgba(140, 152, 164, .25)');
        }
    }




    const logout = async () => {
        try{

            logoutSuccess(dispatch)
            await logoutUser()

        } catch(err){
            console.log(err)
        }
    }


  return (
    <div>
        <div class="dropdown-item-text">
            <UserAvatar 
                margin="mr-2"
                firstName = {state.user.data.firstName}
                avatar = {state.user.data.avatar}
                discordId = {state.user.data.discordId}
                isBody
                lastName = {state.user.data.lastName}
                email = {state.user.data.email}
            />                    
        </div>
        <div class="dropdown-divider"></div>
        <Link to = "/app/account/settings/?id=account-basic" class="dropdown-item" >
            <span class="text-truncate pr-2" >Profile &amp; account</span>
        </Link>
        <Link to = "/app/account/settings/?id=account-team" class="dropdown-item" >
            <span class="text-truncate pr-2">Manage team</span>
        </Link>
        <div class="dropdown-divider"></div>
        {/* <span  class="dropdown-item" >
            <SwitchButton 
                value = {darkmode}
                setValue = {toggleDarkmode}
                label = "Toggle Dark mode"
                size = "sm"
            />
        </span> */}
        <div class="dropdown-divider"></div>
        <span class="dropdown-item pointer" onClick = {logout}>
            <span class="text-truncate pr-2">Sign out</span>
        </span>
    </div>
  )
}


export default User
