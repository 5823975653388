

export const getCoinDecimals = (chainName) => {
    if(chainName.toLowerCase() === 'polygon') return 10E-19
    if(chainName.toLowerCase() === 'eth') return 10E-19
    if(chainName.toLowerCase() === 'etherium') return 10E-19
    if(chainName.toLowerCase() === 'sol') return 10E-10
    if(chainName.toLowerCase() === 'ronin') return 10E-19
    if(chainName.toLowerCase() === 'solana') return 10E-10
    if(chainName.toLowerCase() === 'avalanche') return 10E-19
} 


export const getCoinName = (chainName) => {
    if(chainName.toLowerCase() === 'polygon') return 'matic'
    if(chainName.toLowerCase() === 'eth') return 'ether'
    if(chainName.toLowerCase() === 'etherium') return 'ether'
    if(chainName.toLowerCase() === 'sol') return 'sol'
    if(chainName.toLowerCase() === 'solana') return 'sol'
    if(chainName.toLowerCase() === 'ronin') return 'RON'
    if(chainName.toLowerCase() === 'avalanche') return 'AVAX'
} 

