import React, {useState} from 'react'
import Tags from 'components/Others/Tags'
import Avatar, { UserAvatar } from 'components/Avatar'
import {Button} from 'components/Forms/Buttons'
import Dropdown, { BigDropdown } from 'components/Forms/Dropdown'
import {AddTag} from 'components/Others/Tags'

const Steps = ({data, isTaggable}) => {
  return (
    <ul class='step'>
        {data.map((item, index) => {
            if(item.divider) return <StepDivider title = {item.title}/>
            else return <StepItem {...item} isTaggable = {isTaggable}/>
        })}
    </ul>
  )
}

export default Steps



export const StepDivider = ({title}) => {
    return (
        <li class="step-item">
            <div class="step-content-wrapper">
                <small class="step-divider">{title}</small>
            </div>
        </li>
    )
}


export const CommentItem = ({comment, setComment, avatar, firstName, discordId, saveComment}) => {

    const [loading, setLoading] = useState(false)

    const save = async () => {
        try{
            setLoading(true)
            await saveComment(comment)
            setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }


    return (
        <li class="step-item">
            <div class="step-content-wrapper">
                <div class='step-avatar step-avatar-md'>
                    <UserAvatar
                        avatar = {avatar}
                        firstName = {firstName}
                        discordId = {discordId}
                    />
                </div>

                <div class='d-block w-100'>
                    <textarea 
                        className = 'form-control' style={{height: '200px'}}
                        value = {comment}
                        onChange = {e => setComment(e.target.value)}
                    />
                    <div class='d-flex mt-3 '>
                        <div class='btn btn-sm btn-soft-danger mr-2 ml-auto' onClick={() => setComment('')}>Cancel</div>
                        <Button 
                            submitResponse={save}
                            text = "Add note"
                            loading = {loading}
                            size = "sm"
                            color = "soft-primary"
                        />
                    </div>
                </div>
            </div>
        </li>
    )
}


export const StepItem = ({icon, img, imgSize, title, url, description, isTaggable, tags, time, replies}) => {

    const [newTag, setNewTag] = useState('')

    return (
        <li class="step-item">
            <div class="step-content-wrapper ">
                {(img && !img.includes('null')) ? <div class={`step-avatar step-avatar-${imgSize ? imgSize : 'sm'}`}>
                    <img class="step-avatar-img" src={img} alt="" />
                </div> : 
                (icon ? <span class="step-icon step-icon-sm step-icon-soft-primary">
                    {icon}
                </span> : <span class="step-icon step-icon-sm step-icon-soft-secondary ">{title.toUpperCase()[0]}</span>)}
                <div class="step-content mb-3">
                    <div class={`${(isTaggable || url) ? 'hover-link' : ''} my-n2 py-2 mx-n2 px-2`}>
                        <div class='d-flex justify-content-between'>
                            <div>
                                <div class='d-flex align-items-end'>
                                    <h5 class="mb-0 mt-0 text-dark">{title}</h5>
                                    {time && <p class="font-size-xs  ml-2 mb-0">{time}</p>}
                                </div>
                                {tags && <div class='mb-2'>
                                    <Tags tags = {tags}/>
                                </div>}
                                <p class="font-size-md mb-0">{description}</p>
                            </div>
                            {isTaggable && <div class='hover-link-show'>
                                <AddTag 
                                    label = {<div class='btn btn-icon btn-soft-secondary btn-xs mr-2'><i class="tio-label"></i></div>}
                                    newTag = {newTag}
                                    setNewTag ={setNewTag} 
                                    createNew = {() => {}} 
                                    tags = {[]}
                                    position = 'right'
                                />
                            </div>}
                            {url && <div class='hover-link-show'>
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                    <div class='btn btn-icon btn-soft-secondary btn-xs mr-2'><i class="tio-link"></i></div>
                                </a>
                            </div>}
                        </div>
                    </div>
                    {(replies && replies.length > 0) && <div class='mt-4 mb-n4'><Steps data = {replies}/></div>}
                </div>
            </div>
        </li>
    )
}


