import { Button } from 'components/Forms/Buttons'
import { InputFieldRow } from 'components/Forms/TextInput'
import React, {useState} from 'react'
import { updatePasswordApi } from 'services/user/auth'
import toaster from 'react-hot-toast'

const Security = () => {

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPassword1, setNewPassword1] = useState('')
    const [loading, setLoading] = useState(false);

    const updatePassword = async () => {
        try{
            setLoading(true)
            const res = await updatePasswordApi(currentPassword, newPassword)
            setCurrentPassword('')
            setNewPassword('')
            setNewPassword1('')
            setLoading(false)
            toaster.success("Your password successfully updated")
        } catch(err){
            setLoading(false)
            toaster.error("Error occured! Please refresh and try again")
            console.log(err)
        }
    }

    return (
        <div id="account-security" class="card mb-3 mb-lg-5">
            <div class="card-header">
                <h4 class="card-title">Change your password</h4>
            </div>
            <div class="card-body">
                <form id="changePasswordForm">
                    <InputFieldRow 
                        type = 'password'
                        value = {currentPassword}
                        setValue = {e => setCurrentPassword(e)}
                        label = 'Current password'
                        marginBotton = "0"
                        columnSize={3}
                    />
                    <InputFieldRow 
                        type = 'password'
                        value = {newPassword}
                        setValue = {e => setNewPassword(e)}
                        label = "New password"
                        marginBotton = "0"
                        columnSize={3}
                    />
                    <InputFieldRow 
                        type = 'password'
                        value = {newPassword1}
                        setValue = {e => setNewPassword1(e)}
                        label = 'Confirm New password'
                        marginBotton = "0"
                        columnSize={3}
                    />
                
                    <div class="d-flex justify-content-end">
                        <Button 
                            loading = {loading}
                            text = "Save Changes"
                            submitResponse = {updatePassword}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Security
