import React, {useState, useEffect, useContext} from 'react'
import Header from './components/Header'
import Details from './components/Details'
import Rewards from './components/Rewards'
import Confirmation from './components/Confirmation'
import Evaluation from './components/Evaluation'
import {useLocation} from 'react-router-dom'
import getTemplate, {typeOptions} from './utilis/getTemplate'
import moment from 'moment'
import {launchQuestApi} from 'services/quests'
import toast from 'react-hot-toast'
import { updateQuests } from 'store/actions/workplace'
import {Store} from 'store'


const AddQuest = (props) => {

    const location = useLocation()
    const {state, dispatch} = useContext(Store);
    const [step, setStep] = useState('details');
    const [quest, setQuest] = useState({
        title: '',
        type: '',
        mission: '',
        guide: '',
        endDate: '',
        maxRecipients: 100,
        reward: {
            xpValue: '10',
            tokenSymbol: '',
            tokenValue: '',
            autoXp: true,
        },
        startQuest: {
            type: 'DISCORD_COMMAND',
            metadata: {}
        },
        evaluation: {
            type: '',
            metadata: {},
        },
        status: '',
        parentQuest: '',
        medium: '',
    })

    useEffect(() => {
        getQueryParams();
    }, [])
    

    const getQueryParams = async () => {
        const data = {};
        const queryParams = new URLSearchParams(location.search)
        queryParams.forEach((value, key) => {
            data[key] = value;
        });
        if(data.templateid){
            const templateData = getTemplate(data.templateid);
            setQuest(prev => ({...prev, ...templateData}))
        }
        if(data.medium){
            setQuest(prev => ({...prev, medium: data.medium}))
        } else{
            history.back()
            toast.error("Please select a medium first")
        }
    }



    const saveAsDraft = async () => {
        try{

        } catch(err){
            console.log(err)
        }
    }

    

    const launchQuest = async () => {
        try{

            const quest_ = await launchQuestApi({
                ...quest,
                startDate: moment(quest.startDate, 'Do MMM YYYY'),
                endDate: moment(quest.endDate, 'Do MMM YYYY'),
            });
            toast.success("Quest created successfully");
            if(state.workplace.quests && quest_){
                updateQuests([...state.workplace.quests, quest_], dispatch)
            }
            props.history.push(`/app/quest/${quest_._id}`)


        } catch(err){
            if(err.response && err.response.data && err.response.data.error === "RESOURCE_ALREADY_EXISTS"){
                toast.error("The command name you have choosen already exists. Please choose a different name")
            }
            toast.error("Error creating quest");
            console.log(err)
        }
    }

    

    return (
        <div class='row justify-content-center mt-5'>
            <div class='col-lg-10'>
                <Header step = {step}/>
                {step === 'details' && <Details 
                    setStep={setStep}
                    quest = {quest}
                    setQuest = {setQuest}
                    nextStep = "evaluation"
                />}
                {step === 'evaluation' && <Evaluation 
                    setStep={setStep}
                    quest = {quest}
                    setQuest = {setQuest}
                    prevStep = "details"
                    nextStep = "rewards"
                />}
                {step === 'rewards' && <Rewards 
                    setStep={setStep}
                    quest = {quest}
                    setQuest = {setQuest}
                    prevStep = "evaluation"
                    nextStep = "confirm"
                />}
                {step === 'confirm' && <Confirmation 
                    setStep={setStep}
                    quest = {quest}
                    setQuest = {setQuest}
                    saveAsDraft = {saveAsDraft}
                    launchQuest = {launchQuest}
                    prevStep = "rewards"
                />}
            </div>
        </div>
    )
}

export default AddQuest



