import React, {useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'


export const InputField = ({
    errorText, 
    placeholder, 
    disabled, 
    label, 
    error, 
    type, 
    value, 
    setValue, 
    labelClass, 
    tooltip, 
    setError,
    required, 
    size,
    light,
    description,
    textAreaHeight,
    margin
}) => {

    return (
        <div class={`form-group ${margin && margin}`}>
            {label && <label class={`input-label ${labelClass}`}>
                {label}
                {tooltip && <OverlayTrigger overlay = {<Tooltip>{tooltip}</Tooltip>}>
                    <i class="tio-info ml-2 " ></i>
                </OverlayTrigger>}
                {required && <i class='text-danger'>*</i>}
            </label>}
            {description && <p class = 'mt-n2 fs-sm'>{description}</p>}
            {(!type || type === 'input' || type === 'text' || type === 'number') && <input 
                class={`form-control form-control-${size ? size : 'md'} ${error ? 'is-invalid' : ''} ${light ? 'form-control-light' : ''}`}
                type={type || "text" }
                placeholder = {placeholder || ""}
                value = {value}
                onChange = {e => setValue(e.target.value)}
                autocomplete="off" 
                disabled = {disabled}
            />}
            {(type === 'textarea') && <textarea 
                class={`form-control form-control-${size ? size : 'md'} ${error ? 'is-invalid' : ''} ${light ? 'form-control-light' : ''}`}
                type={type || "text" }
                placeholder = {placeholder || ""}
                value = {value}
                onChange = {e => setValue(e.target.value)}
                autocomplete="off" 
                style={{height: textAreaHeight ? textAreaHeight : '100px'}}
            />}
            {errorText && <div class = 'invalid-feedback'>{errorText}</div>}
        </div>
    )
}


export const PasswordField = ({
    errorText, 
    error, 
    setError, 
    value, 
    setValue, 
    label, 
    placeholder,
    required,
    sideLabel,
    size
}) => {

    const [view, setView] = useState(false);

    return(
        <div class="form-group">
            {label && <label class="input-label">
                <span class="d-flex justify-content-between align-items-center">
                    {label}
                    {sideLabel && sideLabel}
                </span>
            </label>}
            <div class="input-group input-group-merge">
                <input 
                    type={view ? "text" : "password"}
                    class={`form-control ${size === "lg" ? "form-control-lg" : ""}  ${error ? 'is-invalid' : ''}`}
                    name="password" 
                    placeholder = {placeholder || ""}
                    required = {required}
                    value = {value}
                    onChange = {e => setValue(e.target.value)}
                />
                {errorText && <div class = 'invalid-feedback'>{errorText}</div>}
                <div  class="input-group-append">
                    <span class="input-group-text pointer" onClick = {() => setView(prev => !prev)}>
                        <i className={!view ? "fas tio-visible-outlined":"fas tio-hidden-outlined"}></i>
                    </span>
                </div>
            </div>
        </div>
    )
}





export const InputFieldRow = ({icon, label, tooltip, disabled, textAreaHeight, type, value, description, name, error, setValue, setError, placeholder, size, errorText, marginBotton, columnSize}) => {
    return (
        <div class={`row align-items-center form-group mb-${marginBotton}`}>
            <div class={`col-sm-${columnSize ? columnSize : 4} col-form-label  mb-sm-0`}>
                <div class="d-flex align-items-center ">
                    {icon && <i class={`${icon} nav-icon`}></i>}
                    <div class="input-label">{label}</div>
                    {tooltip && <OverlayTrigger overlay = {<Tooltip>{tooltip}</Tooltip>}>
                        <i class="tio-help text-secondary ml-2" ></i>
                    </OverlayTrigger>}
                </div>
                {description && <p class="">{description}</p>}
            </div>
            <div class="col-sm">
            {(!type || type === 'text' || type === 'number') && <InputField 
                name = {name}
                value = {value}
                error = {error}
                setValue = {setValue}
                setError = {setError}
                placeholder = {placeholder}
                errorText = {errorText}
                size = {size}
                disabled = {disabled}
            />}
            {(type === 'textarea') && <InputField 
                name = {name}
                value = {value}
                error = {error}
                setValue = {setValue}
                setError = {setError}
                placeholder = {placeholder}
                errorText = {errorText}
                size = {size}
                type = 'textarea'
                textAreaHeight = {textAreaHeight}
                disabled = {disabled}
            />}
            {(type === 'password') && <PasswordField 
                name = {name}
                value = {value}
                error = {error}
                setValue = {setValue}
                setError = {setError}
                placeholder = {placeholder}
                errorText = {errorText}
                size = {size}
            />}
            </div>
        </div>
    )
}






export const PhoneField = ({
    errorText, 
    placeholder, 
    disabled, 
    label, 
    error, 
    type, 
    value, 
    setValue, 
    labelClass, 
    tooltip, 
    setError,
    required, 
    size,
    light
}) => {

    return (
        <div class="form-group">
            {label && <label class={`input-label ${required ? 'required' : ''} ${labelClass}`}>
                {label}
                {tooltip && <OverlayTrigger overlay = {<Tooltip>{tooltip}</Tooltip>}>
                    <i class="tio-error ml-1 text-secondary" ></i>
                </OverlayTrigger>}
            </label>}
            <input 
                class={`form-control ${size === "lg" ? "form-control-lg" : ""} ${error ? 'is-invalid' : ''} ${light ? 'form-control-light' : ''}`}
                type='number'
                placeholder = {placeholder || ""}
                value = {value}
                onChange = {e => setValue(e.target.value)}
                autocomplete="off" 
            />
            {errorText && <div class = 'invalid-feedback'>{errorText}</div>}
        </div>
    )
}