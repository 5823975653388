const moment = require('moment')

export function abbreviateNumber(value_) {
    let value = parseFloat(value_).toFixed(0)
    var newValue = value;
    if (value >= 1000) {
        
        var suffixes = ["", "k", "m", "b","t"];
        var suffixNum = Math.floor( (""+value).length/3 );
        console.log(suffixNum)
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0)  shortValue = parseFloat(shortValue).toFixed(1);
        newValue = shortValue+suffixes[suffixNum];

    } else{
        newValue = parseFloat(value).toFixed(2);
    }
    return newValue.toString().toUpperCase();
}



export function getNumberWithCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}



export const splitName = (name) => {
    const names = name.split(' ')
    const firstName = names.slice(0, names.length > 1 ? names.length-1 : 1).join(' ')
    const lastName = names.length > 1 ? names[names.length-1] : ''
    return {firstName, lastName}
}


export const shortenText = (text, number) => {
    if(!text) return ''
    if(text.length <= number) return text;
    else return text.slice(0, number) + '...'
}




export const getTitleCase = (text) => {
    if(!text || text.length === 0 || typeof text !== 'string') return ''
    return text[0].toUpperCase() + text.slice(1).toLocaleLowerCase()
}




export const parseDateRange = (startDate, endDate) => {
    const today = moment();
    const start = moment(startDate)
    const end = moment(endDate)
    if(start.diff(today.startOf('week')) === 0 && end.diff(today.endOf('week')) === 0) return 'This Week'
    if(start.diff(today.startOf('week'), 'day') === -7 && end.diff(today.endOf('week'), 'day') === -7) return 'Last Week'
    if(start.diff(today.startOf('month'), 'day') === 0 && end.diff(today.endOf('month'), 'day') === 0) return 'This Month'
    if(start.diff(today.startOf('month'), 'day') === -30 && end.diff(today.endOf('month'), 'day') === -30) return 'Last Month'
    if(start.diff(today.startOf('month'), 'day') === -31 && end.diff(today.endOf('month'), 'day') === -31) return 'Last Month'
    if(start.diff(today.startOf('month'), 'day') === -31 && end.diff(today.endOf('month'), 'day') === -30) return 'Last Month'
    if(start.diff(today.startOf('month'), 'day') === -30 && end.diff(today.endOf('month'), 'day') === -31) return 'Last Month'
    if(start.diff(today.startOf('day'), 'day') === 0 && end.diff(today.endOf('day'), 'day') === 0) return 'Today'
    if(start.diff(today.startOf('day'), 'day') === -1 && end.diff(today.endOf('day'), 'day') === -1) return 'Yesterday'
    return start.format('Do MMM YY') + ' - ' + end.format('Do MMM YY')
}


export const getCurrencySymbol = (currency) => {
    var currency_symbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫', // Vietnamese Dong
        'INR': '₹'
    };
    if(currency_symbols[currency.toUpperCase()]){
        return currency_symbols[currency.toUpperCase()]
    } else return currency
}



export const getMaxValue = (arr) => {
    if(!arr || arr.length === 0) return 0
    return arr.reduce((max, val) => max > val ? max : val) + (arr.reduce((max, val) => max > val ? max : val)*0.2)
}

export const getAverage = (arr) => {
    if(!arr || arr.length === 0) return 0
    return parseInt(arr.reduce((acc,v) => acc + v) / arr.length)
}

export const getPercentageChange = (arr) => {
    if(!arr || arr.length === 0) return 0
    return parseInt((arr[arr.length-1] - arr[0])/arr[0]*100)
}


export const getDifference = (arr) => {
    if(!arr || arr.length === 0) return 0
    return parseInt((arr[arr.length-1] - arr[0]))
}



export const getApiImage = (name) => {

    const api_images = [
        {name: "google", img: "https://www.freepnglogos.com/uploads/google-logo-png/google-logo-icon-png-transparent-background-osteopathy-16.png"}
    ]
    
    for(let i=0; i<api_images.length; i++){
        if(name.toLowerCase().includes(api_images[i].name)) return api_images[i].img
    }
    return name ? name[0].toUpperCase() : ""
}