import { PageHeader } from 'components/Text'
import React from 'react'
import {Link} from 'react-router-dom'

const CreateAirdrop = () => {
  return (
    <div>
        <div class='row justify-content-center mt-5'>
            <div class='col-8'>
                <PageHeader 
                    title = "Select reward type"
                    description="Choose from the methods below to reward your community members with tokens."
                />
                <UniqueLinkMode />
                <WalletMode />
            </div>
        </div>
    </div>
  )
}

export default CreateAirdrop



const UniqueLinkMode = () => {
    return (
        <Link 
            to = "/app/rewards/add/link" 
            class="card card-hover-shadow pointer card-hover-change-bg link-unstyled" 
            style ={{borderBottomLeftRadius:'0rem', borderBottomRightRadius:'0rem'}} 
        >
            <div class='card-body'>
                <div class='row align-items-center'>
                    <div class='col-4 text-right'>
                        <img src = "/assets/svg/1.svg" class='img-fluid' />
                    </div>
                    <div class='col'>
                        <h2>By unique link</h2>
                        <p class='mb-2'>Your custom claim page to share links with community members </p>
                        <div class='badge badge-primary '>Recommended</div>
                    </div>
                </div>
            </div>
        </Link>
    )
}



const WalletMode = () => {
    return (
        <Link 
            to = "/app/rewards/add/direct" 
            class="card card-hover-shadow pointer card-hover-change-bg link-unstyled" 
            style ={{borderTopLeftRadius:'0rem', borderTopRightRadius:'0rem'}} 
        >
            <div class='card-body'>
            <div class='row align-items-center'>
                <div class='col-4 text-right'>
                    <img src = "/assets/svg/2.svg" class='img-fluid' />
                </div>
                <div class='col'>
                    <h2>By wallet addresses</h2>
                    <p>Upload addresses & transfer tokens to them at once from your wallet</p>
                </div>
            </div>
            </div>
        </Link>
    )
}