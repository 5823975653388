import React from 'react'
import Criteria from './Criteria'


const SegmentComponent = ({criterias, setCriterias, errors, setErrors}) => {

	const addRule = (type) => {
		const id = new Date().getTime()
		setCriterias([...criterias, {
			type: type,
			id,
			attribute: '',
			operation: '',
			value: '',
		}])
		setErrors({...errors, [id]: false})
	}


	const deleteCriteria = (id) => {
		const newCriterias = [...criterias]
		const index = newCriterias.findIndex(item => item.id === id);
		newCriterias.splice(index, 1)
		setCriterias(newCriterias)
	}


	return (
		<div>
			<div>
				<p  class='input-label mb-0'>Segment Criteria</p>
				<div>
					{criterias.map(item => <Criteria 
						criteria = {item} 
						criterias = {criterias} 
						setCriterias = {setCriterias} 
						deleteCriteria = {deleteCriteria}
						error = {errors[item.id]}
					/>)}
				</div>
			</div>
			<div class='d-flex mt-2'>
				<div 
					class='btn btn-soft-primary btn-sm mr-2'
					onClick={() => addRule('user')}
				>+ User attributes</div>
				<div 
					class='btn btn-soft-primary btn-sm mr-2'
					onClick={() => addRule('activity')}
				>+ Activity attributes</div>
				<div 
					class='btn btn-soft-primary btn-sm'
					onClick={() => addRule('wallet')}
				>+ Wallet attributes</div>
			</div>
		</div>
	)
}


export default SegmentComponent