import polygonTokens from './topPoylgonTokens.json'
import ethTokens from './topErc20Tokens.json'


const findToken = (addresses) => {
    const ethTokens_ = ethTokens.tokens.filter(token => addresses.includes(token.address))
    const polyTokens = polygonTokens.tokens.filter(token => addresses.includes(token.address))
    return [...ethTokens_, ...polyTokens]
}


export default findToken;