import React, {useEffect, useState, useContext} from 'react'
import { PageHeader } from 'components/Text'
import { getAllQuestsApi } from 'services/quests';
import { Button } from 'components/Forms/Buttons';
import HorizontalNav from 'components/Nav/HorizontalNav';
import {updateQuests} from 'store/actions/workplace'
import {Store} from 'store'
import DataTable from 'components/Table';
import QuestTypes from './components/QuestTypes';
import Templates from './components/Templates';
import { Link } from 'react-router-dom';
import {AvatarGroup} from 'components/Avatar'


const Quests = (props) => {

    const {state, dispatch} = useContext(Store)
    const [quests, seQuests] = useState([]);
    const [selectedNav, setSelectedNav] = useState('started');
	const [showAdd, setShowAdd] = useState();
	const [showTemplates, setShowTemplates] = useState();
    const [newQuestType, setNewQuestType] = useState();


    useEffect(() => {
        if(state.workplace.quests){
            seQuests(state.workplace.quests.map(item => formatQuest(item)))
        } else getAllQuests()
    }, [state.workplace.quests])

     
    const getAllQuests = async () => {
        try{
            
            const allQuests = await getAllQuestsApi();
            updateQuests(allQuests, dispatch)

        } catch(err){
            console.log(err)
        }
    }


    const selectType = (type) => {
        setNewQuestType(type)
        setShowAdd(false)
        setShowTemplates(true)
    }

    return (
        <>
            <div class="mb-5">
                <div class="row align-items-end mb-3">
                    <div class="col-sm mb-sm-0">
                        <h1 class="page-header-title mb-0">Quests</h1>
                        <p class="page-header-text mt-2">Excite your community to take actions & power your growth.</p>
                    </div>
                    <div class="col-sm-auto">
                        <div class='d-flex'>
                            <Button 
                                submitResponse = {() => setShowAdd(true)} 
                                text = "Add new quest" 
                                color = "primary" 
                                size = "ls"
                            />
                        </div>
                    </div>
                </div>
                <HorizontalNav
                    navItems = {[{
                        value: 'started',
                        label: 'Started'
                    }, {
                        value: 'completed',
                        label: 'Completed'
                    }]}
                    selected = {selectedNav}
                    setSelected = {setSelectedNav}
                />
            </div>
            <div class='card'>
                <DataTable
                    data = {quests.filter(item => {
                        if(selectedNav === 'started') return item.status === 'STARTED'
                        if(selectedNav === 'completed') return item.status === 'COMPLETED'
                    })}
                    headers = {headers}
                    searchPlaceholder = "Search your quests here"
                    isSort={true}
            
                />
            </div>

			<Templates 
				show = {showTemplates}
				setShow = {setShowTemplates}
                type = {newQuestType}
			/>
            <QuestTypes
                show = {showAdd}
                setShow = {setShowAdd}
                selectType = {selectType}
            />
        </>
    )
}

export default Quests




const headers = [
    {
        value: 'titleLabel',
        label: 'Title'
    },
    {
        value: 'mediumLabel',
        label: 'Medium'
    },
    {
        value: 'statusLabel',
        label: 'Status'
    },
    {
        value: 'rewardLabel',
        label: 'Reward'
    },
    {
        value: 'submissionsLabel',
        label: 'Submissions'
    },
]








const formatQuest = (data) => ({
    ...data,
    statusLabel: {
        label: <div class='badge badge-soft-success'>{data.status}</div>,
        value: 'Completed'
    },
    titleLabel: {
        label: <Link to = {`/app/quest/${data._id}`}>{data.title}</Link>,
        value: data.title
    },
    rewardLabel: {
        label: <RewardTags xpValue={data.reward.xpValue} tokenValue = {data.reward.tokenValue}/>,
        value: (data.reward.xpValue +'XPs') + (data.reward.tokenValue ? (' | ' + data.reward.tokenValue + ' '+data.reward.tokenSymbol) : ''),
    },
    mediumLabel: data.medium,
    submissionsLabel: {
        // label: <AvatarGroup 
        //     users = {data.submissions.slice(0, 5).map(item => ({
        //         name: data.memberProfiles.find(item2 => item2._id === item.memberId)?.metadata?.username,
        //         avatar: data.memberProfiles.find(item2 => item2._id === item.memberId)?.metadata?.avatar,
        //     }))}
        //     maxLength = {4}
        // />,
        value: '',
        label: <></>
    }
})



const RewardTags = ({xpValue, tokenValue}) => {
    return (
        <div>
            {xpValue ? <span className='badge badge-soft-primary mr-2'>{xpValue} XP</span> : ''}
            {tokenValue ? <span className='badge badge-soft-primary'>{tokenValue} tokens</span> : ''}
        </div>
    )
}
