import axiosInstance from '../axios';



export const updateWorkplaceApi = async (workplace) => {
    try{
        const {data: {success, data}} = await axiosInstance.put('/workplace/', {
            changes: workplace
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const uploadWalletAddressesApi = async (body) => {
    try{
        const {data: {success, data}} = await axiosInstance.post('/workplace/integrations/upload', body)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const getUploadsApi = async () => {
    try{
        const {data: {success, data}} = await axiosInstance.get('/workplace/integrations/upload')
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}




export const getAllTagsApi = async () => {
    try{
        const {data: {success, data}} = await axiosInstance.get(`/workplace/tags`)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const updateTagsApi = async (body) => {
    try{
        const {data: {success, data}} = await axiosInstance.put(`/workplace/tags`, body)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const deleteTagsApi = async (body) => {
    try{
        const params = new URLSearchParams()
        params.append('tags', body.tags.join(','))
        const {data: {success, data}} = await axiosInstance({
            method: 'delete',
            params,
            url: '/workplace/tags'
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const addIntegrationApi = async (body) => {
    try{
        const {data: {success, data}} = await axiosInstance.post('/auth/discord/add/redirect', body)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const getCryptoPriceApi = async () => {
    try{
        const {data: {success, data}} = await axiosInstance.get('/workplace/crypto-prices')
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const getDiscordAuthLinkApi = async () => {
    try{
        const {data: {success, data}} = await axiosInstance.get(`/auth/discord/add`)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const submitEarlyAccessCodeApi = async (code) => {
    try{
        const {data: {success, data}} = await axiosInstance.post(`/auth/early-access-code/`, {
            code
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}