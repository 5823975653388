import { EVENT_NAMES } from "config/constants";
import moment from 'moment'

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
const tzAdd = -(new Date().getTimezoneOffset())/60;

export const formatReport = (data, startDate, endDate_) => {

    const {
        tsData,
        totalCounts,
        channel,
        activityTime,
        responsiveness
    } = data;

    let endDate = moment(endDate_).diff(moment(), 'days') > 0 ? new Date() : endDate_


    let engagement = data.engagement.map(item => ({
        ...item,
        uniqueMembers: item.totalMembers.length,
        day: item._id.day
    }))


    let totalCount = {}
    totalCounts.map(item => totalCount[item._id] = item._count)
    
    let totalMembers = (totalCount[EVENT_NAMES.MEMBER_CREATE] || 0) - (totalCount[EVENT_NAMES.MEMBER_DELETE] || 0)
    let totalMessages = (totalCount[EVENT_NAMES.MESSAGE_CREATE] || 0) 

    let numOfDays = moment(endDate).diff(moment(startDate), 'days')+1

    //for members
    
    const formattedData = Array(moment(endDate).diff(startDate, 'days') + 1).fill({}).map((item, index) => {
        
        const totalMetricsOfTheDay = tsData.filter(item => moment(item.day).format('YYYY-MM-DD') === moment(startDate).add(index, 'days').format('YYYY-MM-DD'))
        
        const memberEvents = totalMetricsOfTheDay.filter(item => item.name === EVENT_NAMES.MEMBER_CREATE || item.name === EVENT_NAMES.MEMBER_DELETE)
        const memberEventsCount = memberEvents.reduce((acc, item) => {
            if(item.name === EVENT_NAMES.MEMBER_CREATE) return acc + item.count;
            else return acc - item.count
        }, 0)
        
        const messageEvents = totalMetricsOfTheDay.filter(item => item.name === EVENT_NAMES.MESSAGE_CREATE)
        const messageEventsCount = messageEvents.reduce((acc, item) => acc + item.count, 0)

        const repliesEvents = totalMetricsOfTheDay.filter(item => item.name === EVENT_NAMES.MESSAGE_CREATE)
        const repliesEventsCount = repliesEvents.reduce((acc, item) => acc + item.count, 0)
        
        const positiveMessagesCount = messageEvents.filter(item => item.sentiment).reduce((acc, item) => {
            if(item.sentiment === 'positive') return acc + item.count
            else return acc
        }, 0)
        const negativeMessagesCount = messageEvents.filter(item => item.sentiment).reduce((acc, item) => {
            if(item.sentiment === 'negative') return acc + item.count
            else return acc
        }, 0)

        const messagesWithNoReplies = responsiveness.filter(item => moment(item.day).format('YYYY-MM-DD') === moment(startDate).add(index, 'days').format('YYYY-MM-DD'))
        
        
        const uniqueActiveMembers = engagement.filter(item => moment(item.day).format('YYYY-MM-DD') === moment(startDate).add(index, 'days').format('YYYY-MM-DD'))

        const membersWhoMessaged = (uniqueActiveMembers && uniqueActiveMembers.length > 0) ? uniqueActiveMembers[0].uniqueMembers : 0
        
        return {
            date: moment(startDate).add(index, 'days').format('Do MMM'),
            totalMembers: memberEventsCount,
            totalMessages: messageEventsCount,
            totalReplies: repliesEventsCount,
            totalPositiveMessages: positiveMessagesCount,
            totalNegativeMessages: negativeMessagesCount,
            totalMessagesWithNoReplies: (messagesWithNoReplies && messagesWithNoReplies.length > 0) ? messagesWithNoReplies[0].count : 0,
            membersWhoMessaged: membersWhoMessaged > memberEventsCount ? memberEventsCount : membersWhoMessaged
        }
    })



    const aggregatedMemberData = getAggregatedData(formattedData.map(item => item.totalMembers), totalMembers)
    const aggregatedMessageData = getAggregatedData(formattedData.map(item => item.totalMessages), totalMessages)
    
    const memberMetrics = {
        legends: ['Per day', 'Accumulated'],
        yAxis: [
            formattedData.map(item => item.totalMembers),
            aggregatedMemberData
        ],
        xAxis: formattedData.map(item => item.date),
    }
    
    const activiyMetrics = {
        legends: ['Per day'],
        yAxis: [
            formattedData.map(item => item.totalMessages),
        ],
        xAxis: formattedData.map(item => item.date),
        yPrefix: '',
        stepsize: 2,
    }
    

    const sentimentMetrics = {
        legends: ['Positive', 'Negative'],
        yAxis: [
            formattedData.map(item => item.totalPositiveMessages),
            formattedData.map(item => item.totalNegativeMessages),
        ],
        xAxis: formattedData.map(item => item.date),
        yPrefix: '',
        stepsize: 2,
        customColors: ['#00c9a7', '#ed4c78'],
        customGradientColors: ['rgba(0, 201, 167, .1)', 'rgba(237, 76, 120, .1)'],
    }

    
    const responsivenessMetrics = {
        legends: ['Replies', 'No Replies'],
        yAxis: [
            formattedData.map(item => parseFloat((item.totalMessagesWithNoReplies/item.totalMessages)*100).toFixed(2)),
            formattedData.map(item => 100-parseFloat((item.totalMessagesWithNoReplies/item.totalMessages)*100).toFixed(2)),
        ],
        xAxis: formattedData.map(item => item.date),
        yPrefix: '',
        stacked: true,
    }

    const channelMetrics = {
        legends: ['Messages'],
        yAxis: [
            channel.map(item => item.metrics.messages + item.metrics.replies),
        ],
        xAxis: channel.map(item => item.name),
        yPrefix: '',
        barWidth: 100
    }

    const timeOfActivityMetrics = {
        legends: ['Messages'],
        yAxis: [
            activityTime.map(item => (item.count + tzAdd)),
        ],
        xAxis: activityTime.map(item => getHour(item.hour + tzAdd)),
        yPrefix: '',
        stepsize: 2,
        barWidth: 100,
    }

    const engagementMetrics = {
        legends: ['Messaged', 'No message'],
        yAxis: [
            formattedData.map((item, index) => parseInt((item.membersWhoMessaged/aggregatedMemberData[index])*100)),
            formattedData.map((item, index) => 100-parseInt((item.membersWhoMessaged/aggregatedMemberData[index])*100)),
        ],
        xAxis: formattedData.map(item => item.date),
        yPrefix: '',
        stepsize: 10,
        stacked: true,
    }


    const memberGrowthRate =  parseInt((totalMembers-aggregatedMemberData[0])/(aggregatedMemberData[0])*100)
    const messageGrowthRate = parseInt((totalMessages-aggregatedMessageData[0])/(aggregatedMessageData[0])*100)
    
    const totalUniqueMembersWhoMessaged = engagement.map(item => item.totalMembers).flat().filter(onlyUnique).length;
    const avgEngagementRate = parseFloat(100*totalUniqueMembersWhoMessaged/totalMembers).toFixed(2);


    const memberMetadata = metadataMaker({
        type: 'members', 
        title: 'Total members growth', 
        metric: memberGrowthRate+'%', 
        metricType: 'members growth', 
        data: [],
        helper: 'Reflects range in overall no of members during the chosen date range'
    })

    const activityMetadata = metadataMaker({
        type: 'activity', 
        title: 'Total activities', 
        metric: messageGrowthRate+'%', 
        metricType: 'growth rate', 
        data: [],
        helper: 'Reflects change in overall no of activities during the chosen date range'
    })

    const engagementMetadata = metadataMaker({
        type: 'engagement', 
        title: 'Avg Engagement', 
        metric: avgEngagementRate + '%',
        metricType: '', 
        data: [],
        helper: 'Ratio of no of unique members who were responsive atleast once to total members averaged during the chosen date range'
    })

    const timeOfActivityMetadata = metadataMaker({
        type: 'timeOfActivity', 
        title: 'Most Active Time to interact', 
        metric: getMostFrequentHour(activityTime.map(item => ({...item, hour: (item.hour + tzAdd)}))),
        metricType: '', 
        data: []
    })

    const channelMetadata = metadataMaker({
        type: 'channelWiseEngagement', 
        title: 'Most frequent channel', 
        metric: getMostFrequentChannel(channel),
        metricType: '', 
        data: []
    })

    const responsivenessMetadata = metadataMaker({
        type: 'responsiveness', 
        title: 'Avg Responsiveness', 
        metric: getAverage(formattedData.map(item => parseFloat((item.totalMessagesWithNoReplies/item.totalMessages)*100))) + '%',
        metricType: '', 
        data: [],
        helper: 'Ratio of no of messages with replies to total messages per day averaged during the chosen date range'
        // data: [{
        //     type: 'progress',
        //     legends: [],
        //     yAxis: [24, 76],
        //     xAxis: ["24%", "has replies 5"],
        //     colors: ["primary", "warning"],
        //     yPrefix: '',
        // }, {
        //     type: 'progress',
        //     legends: [],
        //     yAxis: [34, 66],
        //     xAxis: ["34%", "No replies: 5"],
        //     colors: ["primary", "warning"],
        //     yPrefix: '',
        // }]
    })

    let totalPositiveMessages = formattedData.reduce((acc,item ) => acc+item.totalPositiveMessages, 0)
    let totalNegativeMessages = formattedData.reduce((acc,item ) => acc+item.totalNegativeMessages, 0)

    const sentimentMetadata = metadataMaker({
        type: 'sentiment', 
        title: 'Sentiment analysis', 
        metric: parseInt((totalPositiveMessages/(totalPositiveMessages+totalNegativeMessages))*100) + '%', 
        metricType: ' positive', 
        data: [{
            type: 'progress',
            title: 'Positive Messages ' + formattedData.reduce((acc,item ) => acc+item.totalPositiveMessages, 0),
            yAxis: [
                formattedData.reduce((acc,item ) => acc+item.totalPositiveMessages, 0),
                formattedData.reduce((acc,item ) => acc+item.totalMessages, 0),
            ],
            isColorNotSoft: [true, false],
            xAxis: ["", ""],
            colors: ["success", "secondary"],
            yPrefix: '',
        }, {
            type: 'progress',
            title: 'Negative Messages ' + formattedData.reduce((acc,item ) => acc+item.totalNegativeMessages, 0),
            yAxis: [
                formattedData.reduce((acc,item ) => acc+item.totalNegativeMessages, 0),
                formattedData.reduce((acc,item ) => acc+item.totalMessages, 0),
            ],
            isColorNotSoft: [true, false],
            xAxis: ["", ""],
            colors: ["danger", "secondary"],
            yPrefix: '',
        }]
    })


    const healthMetrics = [{
        label: 'Total members',
        value: totalMembers,
        growth: memberGrowthRate,
        numOfDays,
        tooltip: 'Total members as of today'
    }, {
        label: 'Engagement rate',
        value: avgEngagementRate ,
        numOfDays,
        description: 'last ' + numOfDays + ' days (avg per day)',
        tooltip: 'This is the percentage of unique members who engaged at least once in last one week'
    }, {
        label: 'Total activity',
        value: totalMessages,
        growth: messageGrowthRate,
        numOfDays,
        tooltip: 'Total activity is defined as sum of messages & replies as of today'
    }, {
        label: 'Sentiment meter',
        type: 'progress',
        totalPositive: totalPositiveMessages,
        totalNegative: totalNegativeMessages,
        numOfDays
    }]


    return {
        metrics : {
            members: memberMetrics,
            activity: activiyMetrics,
            sentiment: sentimentMetrics,
            responsiveness: responsivenessMetrics,
            channelWiseEngagement: channelMetrics,
            timeOfActivity: timeOfActivityMetrics,
            engagement: engagementMetrics,
            healthMetrics: healthMetrics
        },
        metadata: {
            members: memberMetadata,
            activity: activityMetadata,
            sentiment: sentimentMetadata,
            responsiveness: responsivenessMetadata,
            channelWiseEngagement: channelMetadata,
            timeOfActivity: timeOfActivityMetadata,
            engagement: engagementMetadata,
        }
    }
}







const metadataMaker = ({type, title, metric, metricType, data, helper}) => {
    
    const finalData = {
        title: title,
        metric: metric ,
        metricType: ' ' + metricType,
        data: data,
        helper: helper
    }
    
    // if(type === 'sentiment'){
    //     finalData.data = [{
    //         type: 'progress',
    //         legends: [],
    //         yAxis: [24, 76],
    //         xAxis: ["Negative", "Positive"],
    //         colors: ["danger", "success"],
    //         yPrefix: '',
    //     }]
    // }
    

    return finalData;
}




const healthMetrics = [{
    label: 'Total members',
    value: null,
    growth: null,
}, {
    label: 'Engagement rate',
    value: null,
    growth: null,
    description: "of last 7 days"
}, {
    label: 'Total activity',
    value: null,
    growth: null,
}, {
    label: 'Sentiment meter',
    value: null,
    growth: null,
    type: 'progress',
    totalPositive: null,
    totalNegative: null,
}]





const timeseriesDataa = {
    legends: ['Total', 'Responded'],
    yAxis: [],
    xAxis: [],
    yPrefix: '',
    stepsize: 10,
}




export const sampleMetrics = {
    responsiveness: timeseriesDataa,
    sentiment: timeseriesDataa,
    engagement: timeseriesDataa,
    members: timeseriesDataa,
    mostActiveMembers: {},
    activity: timeseriesDataa,
    timeOfActivity: timeseriesDataa,
    channelWiseEngagement: timeseriesDataa,
    roles: timeseriesDataa,
    rolesAssigned: timeseriesDataa,
    healthMetrics: healthMetrics,
}



export const sampleMetadata = {
    responsiveness: metadataMaker({type: 'responsiveness', title: 'Total response rate', metricType: 'response rate'}),
    sentiment: metadataMaker({type: 'sentiment', title: 'Total sentiment rate', metricType: 'response rate'}),
    engagement: metadataMaker({type: 'engagement', title: 'Total engagement rate', metricType: 'engagement rate'}),
    members: metadataMaker({type: 'members', title: 'Total members growth', metricType: 'members growth'}),
    activity: metadataMaker({type: 'activity', title: 'Total activities', metricType: 'engagement rate'}),
    channelWiseEngagement: metadataMaker({type: 'channelWiseEngagement', title: 'Total channel wise engagement', metricType: 'response rate'}),
    roles: metadataMaker({type: 'roles', title: 'Most active roles', metricType: 'number of messages'}),
    rolesAssigned: metadataMaker({type: 'roles', title: 'Most assigned roles', metricType: 'number of members'}),
}




const getAggregatedData = (data, _total) => {
    let total = _total
    const finalData = [_total]
    for(let i=data.length-1; i>0; i--){
        total = total-data[i]
        finalData.push(total)
    }
    return finalData.reverse()
}



const getHour = (hour_) => {
    // console.log()
    let hour = parseInt(hour_)
    if(hour === 0){
        return '12 AM'
    }
    if(hour === 12){
        return '12 PM'
    }
    if(hour > 12){
        return parseInt(hour-12) + ' PM'
    }
    return parseInt(hour) + ' AM'
}


const getAverage = (data) => {
    let sum = 0
    for(let i=0; i<data.length; i++){
        sum = sum + (isNaN(data[i]) ? 0 : data[i])
    }
    return parseInt(sum/data.length)
}

const getHigest = (data) => {
    let max = 0
    for(let i=0; i<data.length; i++){
        if(data[i] > max){
            max = data[i]
        }
    }
    return max
}



const getMostFrequentHour = (data) => {
    let max = 0
    let hour = 0
    for(let i=0; i<data.length; i++){
        if((data[i].count ) > max){
            max = data[i].count
            hour = data[i].hour 
        }
    }

    return getHour(hour)
}

const getMostFrequentChannel = (data) => {
    let max = 0
    let channel = 0
    for(let i=0; i<data.length; i++){
        let count = data[i].metrics.messages  +data[i].metrics.replies
        if(count > max){
            max = count
            channel = data[i].name
        }
    }
    return channel
}



const getTimezoneDifference = () => {
    let offset = (new Date().getTimezoneOffset())/60;
    return -offset
}


const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}