import React from 'react'


export const SwitchRow = ({title, svg, value, onChange, description}) => {
    return(
        <>
        <div class="d-flex flex-stack">
            <div class="d-flex">
                {svg && <span class="symbol symbol-50px me-6">
                    <span class="symbol-label">
                        <span class="svg-icon svg-icon-2hx svg-icon-1 svg-icon-gray-600">
                            {svg}
                        </span>
                    </span>
                </span>}
                <div class="d-flex flex-column">
                    <span class="fs-5 text-dark text-hover-primary fw-boldest">{title}</span>
                    <div class="fs-6 fw-bold text-gray-400">{description}</div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <div class="form-check form-check-solid form-switch">
                    <input 
                        class="form-check-input w-45px h-30px" 
                        type="checkbox" 
                        checked = {value}
                        onChange = {() => onChange(!value)}
                    />
                    <label class="form-check-label" ></label>
                </div>
            </div>
        </div>
        </>
    )
}





export const SwitchButton = ({ value, setValue, description, label, size }) => {
    return(
    
        <label class={`toggle-switch toggle-switch-${size ? size: 'md'} d-flex align-items-center`}  >
            <span class="toggle-switch-content">
                <span class="input-label bold">{label}</span>
                {description && <span class="d-block">{description}</span>}
            </span>
            <input type="checkbox" class="toggle-switch-input" checked = {value} onClick = {() => setValue(!value)}/>
            <span class="toggle-switch-label">
                <span class="toggle-switch-indicator"></span>
            </span>
      </label>
    )
}



