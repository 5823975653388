import axiosInstance from '../axios';


export const getAllSourcesApi = async (ids) => {
    try{
        const {data: {success, data}} = await axiosInstance.get('/data-sources/discord/sources')
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const sendAnnouncementApi = async (message, channelId, reward, flowType, type) => {
    try{
        console.log(message, channelId, reward, flowType)
        const {data: {success, data}} = await axiosInstance.post('/data-sources/discord/activities/announcement', {
            message,
            channelId,
            reward,
            flowType,
            type
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}
