import { EVENT_NAMES } from "config/constants";
import moment from 'moment'


const formatTsData = (data) => {

    const startDate = moment().subtract('30', 'd').startOf('d');
    const endDate = moment().endOf('D');

    const formattedData = Array(moment(endDate).diff(startDate, 'days') + 1).fill({}).map((item, index) => {
        
        const totalMetricsOfTheDay = data.filter(item => moment(item.day).format('YYYY-MM-DD') === moment(startDate).add(index, 'days').format('YYYY-MM-DD'))
     
        const messageEvents = totalMetricsOfTheDay.filter(item => item.name === EVENT_NAMES.MESSAGE_CREATE)
        const messageEventsCount = messageEvents.reduce((acc, item) => acc + item.count, 0)

        const positiveMessagesCount = messageEvents.filter(item => item.sentiment).reduce((acc, item) => {
            if(item.sentiment === 'positive') return acc + item.count
            else return acc
        }, 0)
        const negativeMessagesCount = messageEvents.filter(item => item.sentiment).reduce((acc, item) => {
            if(item.sentiment === 'negative') return acc + item.count
            else return acc
        }, 0)
        
        return {
            date: moment(startDate).add(index, 'days').format('Do MMM'),
            totalMessages: messageEventsCount,
            totalPositiveMessages: positiveMessagesCount,
            totalNegativeMessages: negativeMessagesCount,
        }
    })

    
    const activiyMetrics = {
        legends: ['Per day'],
        yAxis: [
            formattedData.map(item => item.totalMessages),
        ],
        xAxis: formattedData.map(item => item.date),
        yPrefix: '',
        stepsize: 2,
    }
    

    const sentimentMetrics = {
        totalPositive: formattedData.reduce((acc, item) => acc + item.totalPositiveMessages, 0),
        totalNegative: formattedData.reduce((acc, item) => acc + item.totalNegativeMessages, 0),
    }



    return {
        messages: activiyMetrics,
        sentiment: sentimentMetrics
    }
}

export default formatTsData