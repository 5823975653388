import React, {useContext, useEffect, useState} from 'react'
import Navbar from './Navbar'
import Header from './Header'
import { DashboardAlert } from 'components/Alerts';
import { logUser } from 'utilis/logEvents';
import { Store } from 'store';

const Dashboard = ({children}) => {

    const {state} = useContext(Store)
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(localStorage.getItem('open') === 'true' ? true : (localStorage.getItem('open') === 'false' ? false:true))
    const [type, setType] = useState('')

    useEffect(() => {
        changeBodyClass()
    }, [width, open])

    useEffect(() => {
        logUser(state.user.data.email)
    }, [])

    const changeBodyClass = () => {
        const ele = document.getElementsByTagName("BODY")[0];
        let finalClass = 'footer-offset has-navbar-vertical-aside navbar-vertical-aside-show-lg'
        const closedView = ' navbar-vertical-aside-closed-mode '
        const miniView = ' navbar-vertical-aside-mini-mode '
        if(width >= screenType.lg && !open){
            finalClass = finalClass + miniView
            setType('miniView')
        }
        else if(width < screenType.lg && !open){
            finalClass = finalClass + closedView;
            setType('closedView')
        }
        else setType('full')
        ele.setAttribute('class', finalClass + ' navbar-vertical-aside-transition-on');
        setTimeout(() => {
            ele.setAttribute('class', finalClass);
        }, 300)
    }

    
    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth < screenType.lg) setOpen(false)
            else setOpen(localStorage.getItem('open') === 'true' ? true : false)
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
            <Header setOpen = {setOpen}/>
            <Navbar setOpen = {setOpen} open = {open} type = {type}/>
            <main class= 'main'>
                <div class="content container-fluid">
                    {children}
                </div>
            </main>
        </>
    )
}

export default Dashboard




const screenType = {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0,
}