import React from 'react'

export const Button = ({loading, submitResponse, img, text, disabled, type, loadingText, className, color, size, block, iconPosition, icon, ...props}) => {
    return (
        <button 
            disabled = {loading || disabled}
            onClick = {e => {
                e.preventDefault();
                submitResponse()
            }}
            className = {`btn btn-${type ? type + '-' : ''}${color || 'primary'} ${className} btn-${size} ${block ? 'btn-block'  : ''} `}
            {...props}
        >
            {(icon && iconPosition === 'left') ? <i class={`${icon} mr-2`}></i> : ''}
            {loading && <span class="spinner-border spinner-border-sm align-middle mr-2"></span>}
            {img && <img class="avatar avatar-xs mr-2" src={img} alt="" /> }
            {loading ? (loadingText ? loadingText : text) : text}
            {(icon && iconPosition === 'right') ? <i class={`${icon} ml-2`}></i> : ''}
        </button>
    )
}





export const HrefButton = ({loading, response, text, img, href, ...props}) => {
    return (
        <div class="mb-4">
            <a class="btn btn-lg btn-white btn-block" href={href}>
                <span class="d-flex justify-content-center align-items-center">
                {img && <img class="avatar avatar-xs mr-2" src={img} alt="" /> }
                {text}
                </span>
            </a>
        </div>
    )
}



export const HrefSmButton = ({loading, response, text, img, href, ...props}) => {
    return (
        <div class="mb-4">
            <a class="btn  btn-white btn-block" href={href}>
                <span class="d-flex justify-content-center align-items-center">
                {img && <img class="avatar avatar-xs mr-2" src={img} alt="" /> }
                {text}
                </span>
            </a>
        </div>
    )
}



export const ButtonGroup = ({options, value, onChange, highPadding}) => {
    return(
        <div class = 'd-flex'>
            <ul class ='nav nav-pills nav-line-pills nav-group nav-group-outline' >
                {options.map(item => <li class = "nav-item me-2">
                    <span onClick = {() => onChange(item.value)} class = {`nav-link ${highPadding ? 'px-10':''} btn  btn-active-primary  py-2 px-6 fs-6 fw-bold ${value === item.value ? 'active' : ''}`} >{item.label}</span>
                </li>)}
            </ul>
        </div>
    )
}



export const TagsGroup = ({values, onChange, isDeleteable, color, nextRow}) => {

    const deleteItem = (index) => {
        const newValues = [...values];
        newValues.splice(index, 1);
        onChange(newValues)
    }

    return (
        <div class = {`d-flex flex-wrap mt-3 ${nextRow ? 'flex-column' : 'flex-row'}`}>
            {values && values.map((item, index) => 
                <div><div class = {`badge me-3 mb-2 badge badge-light-${color} badge-lg border  border-gray-400`}>
                        {typeof item === 'string' ? item : item.label} 
                        {isDeleteable && <span class='pointer text-gray-400 pt-3 ps-3' onClick = {() => deleteItem(index)}>&times;</span>}
                    </div>
                </div>
            )}
        </div>
    )
}
