import React, {useState, useContext, useEffect} from 'react'
import { SimpleModal } from 'components/Models/Modal';
import toaster from 'react-hot-toast'
import { Store } from 'store'
import Dropdown, { BigDropdown } from 'components/Forms/Dropdown'
import { assignBulkDiscordRoleApi, getDiscordRolesApi } from 'services/members/roles';
import { VBColorToHEX, addOpacity } from 'utilis/helpers';
import Select from 'components/Forms/Select2'
import { saveDiscordRoles } from 'store/actions/community'
import { Button } from 'components/Forms/Buttons';
import toast from 'react-hot-toast'
import { assignRoleToSegmentApi } from 'services/workplace/segments';

const AssignRole = ({show, setShow, segmentId}) => {

    const {state, dispatch} = useContext(Store);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(state.community.discordRoles){
            formatRoles(state.community.discordRoles)
        } else getAllRoles();
    }, [state.community.discordRoles])

    
    const getAllRoles = async () =>{
        try{

            const roles_ = await getDiscordRolesApi();
            saveDiscordRoles(roles_, dispatch)

        } catch(err){
            console.log(err)
        }
    }

    const formatRoles = (roles_) => {
        try{
            setRoles(roles_.map(item => ({
                value: item.roleId, 
                label: item.name, 
                color: item.color, 
                sourceId: item.sourceId,
                position: item.position,
                disabled: item.disabled
            })))
        } catch(err){
            console.log(err)
        }
    }
   

    const addRole = async (roleId) => {
        try{
            toast.success('This might take a minute as we are assigning roles to members through our discord gateway')
            setLoading(true);
            const sourceId = roles.find(item => item.value === roleId).sourceId;
            const res = await assignRoleToSegmentApi(segmentId, {
                roleId,
                sourceId
            })
            setLoading(false);
            setShow(false);
            setSelectedRole('')
            
        } catch(err){
            console.log(err)
            setLoading(false);
            toast.error("You are missing permissions! Intract can only manage roles for only those users, bots who are lower in the role hierarchy for your server")
        }
    }


    const createNewRole = () => {

    }
    

    return (
        <SimpleModal 
            show = {show} 
            setShow = {setShow}
            title = "Assign role to your members"
            size = "sm"
            footer={<div>
                <Button 
                    size="sm"
                    text=  "Assign role"
                    loading={loading}
                    submitResponse = {() => addRole(selectedRole)}
                />
            </div>}
        >
            <div>
                <Select 
                    options = {roles.sort((a, b) => (b.position - a.position)).filter(item => !item.disabled).map(item => ({
                        value: item.value,
                        label: item.label,
                        icon: <span>
                            <i class={`tio-circle mr-2`} style = {{color: VBColorToHEX(item.color)}}></i>
                        </span>,
                    }))}
                    value = {selectedRole}
                    setValue = {(value) => setSelectedRole(value)}
                >
                    {selectedRole ? <div class='btn btn-block btn-sm btn-outline-dark-2'>
                        <i class={`tio-circle mr-2`} style = {{color: VBColorToHEX(roles.find(item => item.value === selectedRole).color)}}></i>
                        <span class='text-truncate' >{roles.find(item => item.value === selectedRole).label}</span>
                    </div> : <div class='btn btn-block btn-sm btn-outline-dark-2'>
                        <i class='tio-add-circle'></i> Select a discord role
                    </div>}
                </Select>
            </div>

        </SimpleModal>
    )
}

export default AssignRole






const onlyUnique = (key) => {
    return (prev, next) => {
        return prev[key] === next[key] ? false : true;
    }
}

