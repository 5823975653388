
import { UPDATE_INVITATIONS, UPDATE_TEAM, UPDATE_WORKPLACE, UPDATE_CRYPTO_PRICES, UPDATE_TOKENS, UPDATE_QUESTS } from "../actions/workplace";

const workplaceReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_WORKPLACE:
            return {
                ...state,
                workplace: action.payload.data,
            }
        case UPDATE_QUESTS:
            return {
                ...state,
                quests: action.payload.data,
            }
        case UPDATE_TEAM:
            return {
                ...state,
                team: action.payload.data,
            } 
        case UPDATE_INVITATIONS:
            return {
                ...state,
                invitations: action.payload.data,
            } 
        case UPDATE_CRYPTO_PRICES:
            return {
                ...state,
                cryptoPrices: action.payload.data,
            } 
        case UPDATE_TOKENS:
            return {
                ...state,
                tokens: action.payload.data,
            } 
        default:
        return state;
    }
}

export default workplaceReducer;

