import axiosInstance from "services/axios"

export const importTokenApi = async (body) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/workplace/tokens', body)
        return data

    } catch(err){
        console.log(err)
        throw err;
    }
}


export const getTokenApi = async (id) => {
    try{
        const {data: {success, data}} = await axiosInstance.get(`/workplace/tokens/${id}`)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const getTokensApi = async (body) => {
    try{

        const {data: {success, data}} = await axiosInstance.get('/workplace/tokens')
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}