import React, {useState, useEffect, useContext} from 'react'
import FiltersComponent from 'components/Filters'
import Select from 'components/Forms/Select2'

const Filters = ({filters, appliedFilters, setAppliedFilters, sortBy, setSortBy}) => {


    return (
        <div class = 'card card-sm card-dashed mb-3 mt-n4'>
            <div class = 'card-body'>
                <div class='row no-gutters'>
                    <div class='col'>
                        <FiltersComponent 
                            position="around"
                            position2="start"
                            filters = {filters}
                            colSize = "10"
                            appliedFilters = {appliedFilters}
                            setAppliedFilters = {setAppliedFilters}
                        />
                    </div>
                    <div class='col-auto ml-n2'>
                        <Select 
                            options = {sortOptions}
                            value = {sortBy}
                            setValue = {(values) => setSortBy(values)}
                            position = "right"
                        >
                            <div class='btn btn-sm btn-white'>
                                <i class='tio-expand-all'></i> {sortBy === 'recent' ? 'Recent' : 'Oldest'}
                            </div>
                        </Select>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Filters



const sortOptions = [
    {value: 'recent', label: 'Most recent'},
    {value: 'old', label: 'Old first'},
]

