export const UPDATE_CRYPTO_PRICES = 'UPDATE_CRYPTO_PRICES'
export const UPDATE_INVITATIONS = 'UPDATE_INVITATIONS'
export const UPDATE_TEAM = 'UPDATE_TEAM'
export const UPDATE_WORKPLACE = 'UPDATE_WORKPLACE'
export const UPDATE_QUESTS = 'UPDATE_QUESTS'
export const UPDATE_TOKENS = 'UPDATE_TOKENS'


export const updateInvitations = async (invitations, dispatch) =>{
    return dispatch({
       type: UPDATE_INVITATIONS,
       payload: {
           data: invitations
       }
   })
}

export const updateCryptoPrices = async (prices, dispatch) =>{
    return dispatch({
       type: UPDATE_CRYPTO_PRICES,
       payload: {
           data: prices
       }
   })
}


export const updateTokens = async (tokens, dispatch) =>{
    return dispatch({
       type: UPDATE_TOKENS,
       payload: {
           data: tokens
       }
   })
}


export const updateQuests = async (quests, dispatch) =>{
    return dispatch({
       type: UPDATE_QUESTS,
       payload: {
           data: quests
       }
   })
}



export const updateTeam = async (team, dispatch) =>{
    return dispatch({
       type: UPDATE_TEAM,
       payload: {
           data: team
       }
   })
}




export const updateWorkplace = async (workplace, dispatch) =>{
    return dispatch({
       type: UPDATE_WORKPLACE,
       payload: {
          data: workplace
       }
   })
}