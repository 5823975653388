

export const removeElementfromArrayUsingId = ( arr, id ) => {
    const all = [...arr];
    const index = all.findIndex(item => item._id === id);
    all.splice(index, 1)
    return all;
}


export const clickOutside = () => {
    const ele = document.getElementById('outside-div')
    ele.click()
}



export function VBColorToHEX(i) {
    if(typeof i === 'number'){
        if(discordColorMap[i]){
            return discordColorMap[i]
        }
        var bbggrr =  ("000000" + i.toString(16)).slice(-6);
        var rrggbb = bbggrr.substr(4, 2) + bbggrr.substr(2, 2) + bbggrr.substr(0, 2);
        return "#" + rrggbb;
    } else {
        return '#000'
    }
}

export function HEXToVBColor(rrggbb) {
    var bbggrr = rrggbb.substr(4, 2) + rrggbb.substr(2, 2) + rrggbb.substr(0, 2);
    return parseInt(bbggrr, 16);
}


export function addOpacity(color, opacity) {
    // coerce values so ti is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}




const discordColorMap = {
    0:	'#000000',
    1752220:	'#1ABC9C',
    1146986:	'#11806A',
    3066993:	'#2ECC71',
    2067276:	'#1F8B4C',
    3447003:	'#3498DB',
    2123412:	'#206694',
    10181046:	'#9B59B6',
    7419530:	'#71368A',
    15277667:	'#E91E63',
    11342935:	'#AD1457',
    15844367:	'#F1C40F',
    12745742:	'#C27C0E',
    15105570:	'#E67E22',
    11027200:	'#A84300',
    15158332:	'#E74C3C',
    10038562:	'#992D22',
    9807270:	'#95A5A6',
    9936031:	'#979C9F',
    8359053:	'#7F8C8D',
    12370112:	'#BCC0C0',
    3426654:	'#34495E',
    2899536:	'#2C3E50',
    16776960:	'#FFFF00',
}