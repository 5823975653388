import React from 'react'
import List from 'components/Others/List'

const KPIsCard = ({replies, messages, lastActive}) => {
  return (
    <div className='card mb-4'>
        <div className='card-body'>
            <h5 className='card-title mb-2'>KPIs</h5>
            <List 
                list = {[{
                    label: 'Total messages',
                    value: <span>{messages} messages</span>,
                    icon: 'tio-message'
                }, {
                    label: 'Total reply',
                    value: <span>{replies} replies</span>,
                    icon: 'tio-sms-chat'
                }, {
                    label: 'Last active',
                    value: lastActive,
                    icon: 'tio-date-range'
                }]}
            />
        </div>
    </div>
  )
}

export default KPIsCard



