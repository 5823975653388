
const config = {
    serverLink: process.env.REACT_APP_API_URL,
    clientLink: process.env.REACT_APP_APP_URL,
    // serverLink: 'http://localhost:8000'
    // clientLink:'http://10.5.50.7:3000',
    // serverLink:'http://10.5.50.7:8000',
}

export default config
