import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Tags from 'components/Others/Tags'
import { getUserAvatar } from 'utilis/avatars'





const Avatar = ({name, email, description, tags, icon, time, avatar, link, size}) => {

    const history = useHistory()

    return (
        <a class={`media align-items-center ${link ? 'pointer' : ''}`} onClick={() => {
            if (link) history.push(link)
        }}>
            <div class={`avatar avatar-${size ? size : 'md'} avatar-soft-secondary avatar-circle mr-2`}>
                {avatar ? 
                    <img class="avatar-img" src={avatar} alt="" /> :
                    // <span class="avatar-initials">{name && name[0].toUpperCase()}</span>
                    <img class="avatar-img" src={`${getDefaultAvatar(name)}`} alt="" />
                }
            </div>
            {name && <div class="media-body">
                <span class={`d-block h5 text-truncate ${link ? 'text-hover-primary' : ''} mb-0  `}>{name} {icon && icon}</span>
                {tags && <Tags tags = {tags}/>}
                {email && <span class="d-block font-size-sm text-body">{email}</span>}
                {description && <span class="d-block font-size-sm text-body">{description}</span>}
                {time && <p class="font-size-sm mt-2"><i class='tio-time mr-1'></i>{time}</p>}
            </div>}
        </a>
    )
}

export default Avatar


export const AvatarGroup = ({users, maxLength, size}) => {

    const history = useHistory()


    return (
        <div class="d-flex">
            <div class={`avatar-group ${size && `avatar-group-${size}`} avatar-circle mr-3`}>
                {users.slice(0, maxLength).map(item => <OverlayTrigger overlay = {<Tooltip>{item.name}</Tooltip>}>
                    <span class={`avatar  ${item.link ? 'pointer' : ''}`} onClick={() => {
                        if (item.link) history.push(item.link)
                    }}>
                        {item.avatar ? <img class="avatar-img" src={item.avatar} alt={item.name} />:
                        <span class="avatar-initials">{item.name[0].toUpperCase()}</span>}
                    </span>
                </OverlayTrigger>)}
                {users.length > maxLength && <span class="avatar avatar-light avatar-circle" >
                    <span class="avatar-initials">+{users.length - maxLength}</span>
                </span>}
            </div>
        </div>
    )
}




export const UserAvatar = ({discordId, avatar, link, pointer, firstName, active, size, margin, isBody, lastName, email}) => {

    return (
        <>
        <span class={`media align-items-center ${link ? 'pointer' : ''}`} onClick={() => {
            if (link) history.push(link)
        }}>
            {getUserAvatar(discordId, avatar) ? <div class={`avatar avatar-${size || 'sm'} avatar-circle ${pointer && 'pointer'} ${margin}`}>
                <img class="avatar-img" src={getUserAvatar(discordId, avatar)} alt="User" />
                {active && <span class="avatar-status avatar-sm-status avatar-status-success"></span>}
            </div> : <div class={`avatar avatar-sm avatar-soft-primary avatar-circle  ${margin}`}>
                <span class="avatar-initials" >{firstName[0].toUpperCase()}</span>
                {active && <span class="avatar-status avatar-sm-status avatar-status-success"></span>}
            </div>}
            {isBody &&  <div class="media-body">
                <span class={`d-block h5 ${link ? 'text-hover-primary' : ''} mb-0  `}>{firstName + ' ' + lastName}</span>
                {email && <span class="d-block font-size-sm text-body">{email}</span>}
            </div>}
        </span>
        </>
    )
}


export const getDefaultAvatar = (name) => {
    let a = getUniqueNumberFromString(name)
    let b = a % 3
    return '/assets/img/160x160/bee'+(b+1)+'.png'
}


//write a function to get a unique number from a unique string

const getUniqueNumberFromString = (string) => {
    if(!string) return 0
    let sum = 0
    for (let i = 0; i < string.length; i++) {
        sum += string.charCodeAt(i)
    }
    return sum
}