import axiosInstance from '../axios';




export const getMembersApi = async (params) => {
    try{

        
        const {data: {success, data}} = await axiosInstance({
            method: 'get',
            url: '/members/',
            params: params
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}



export const getTotalMembersApi = async (params) => {
    try{

        
        const {data: {success, data}} = await axiosInstance({
            method: 'get',
            url: '/members/count',
            params: params
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}

export const getTopMembersApi = async () => {
    try{

        
        const {data: {success, data}} = await axiosInstance({
            method: 'get',
            url: '/members/top',
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}


export const getMemberApi = async (memberId) => {
    try{

        const {data: {success, data}} = await axiosInstance.get(`/members/${memberId}`)
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}


export const saveNotesApi = async (note, memberId) => {
    try{
        const {data: {success, data}} = await axiosInstance.post(`/members/${memberId}/notes`, {
            note
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}



export const addTagApi = async (memberId, tag) => {
    try{
        const {data: {success, data}} = await axiosInstance.post(`/members/${memberId}/tags`, {
            tag: tag
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}

export const addMultipleTagApi = async (memberIds, tag) => {
    try{
        const {data: {success, data}} = await axiosInstance.put(`/members/tags`, {
            tag: tag,
            memberIds: memberIds
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}



export const deleteTagApi = async (memberId, tagId) => {
    try{
        const {data: {success, data}} = await axiosInstance.delete(`/members/${memberId}/tags/${tagId}`)
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}


export const editMemberApi = async (memberId, member) => {
    try{
        const {data: {success, data}} = await axiosInstance.put(`/members/${memberId}`, {
            member
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}



export const getDiscordMemberMessagesApi = async (memberId, params) => {
    try{
        const {data: {success, data}} = await axiosInstance({
            method: 'get',
            url: `/members/activities/${memberId}/discord`,
            params: params
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}





export const downloadMembersApi = async (params) => {
    try{
        const {data: {success, data}} = await axiosInstance({
            method: 'get',
            url: `/members/download`,
            params: params
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}


export const remindToConnectWalletApi = async (id) => {
    try{
        const {data: {success, data}} = await axiosInstance({
            method: 'get',
            url: `/members/${id}/message/wallet-reminder`,
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}

export const refreshWalletApi = async (id) => {
    try{
        const {data: {success, data}} = await axiosInstance({
            method: 'put',
            url: `/members/${id}/wallet/refresh`,
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}

export const refreshAllWalletApi = async (id) => {
    try{
        const {data: {success, data}} = await axiosInstance({
            method: 'put',
            url: `/members/web3/refresh`,
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}




export const hideMemberApi = async (ids) => {
    try{
        const {data: {success, data}} = await axiosInstance.put('/members/hide', {
            memberIds: ids
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}



export const searchMemebrApi = async (searchInput) => {
    try{

        const params = new URLSearchParams()
        params.append('search', searchInput)
        const {data: {success, data}} = await axiosInstance({
            method: 'get',
            url: '/members/search',
            params: params
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}


export const getDiscordMemberTsData = async (memberId) => {
    try{
        const {data: {success, data}} = await axiosInstance.get(`/data-sources/discord/members/${memberId}/metrics`)
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}
