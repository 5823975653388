import React, {useState, useEffect, useContext} from 'react'
import ActivityCard from './components/ActivityCard'
import { PageHeader } from 'components/Text'
import Filters from './components/Filters'
import {getActivitiesApi, getAllChannelsApi} from 'services/activities'
import {Store} from 'store'
import {getAllTagsApi} from 'services/workplace'
import {getAllSourcesApi} from 'services/sources'
import filtersJson, {initialFilters} from './utilis/filters'
import {formatActivityMessages} from './utilis/formatter'
import { Button } from 'components/Forms/Buttons'
import { saveMemberTags, saveChannels, saveSources } from 'store/actions/community'


const Activity = () => {

    const {state, dispatch} = useContext(Store)
    const [activities, setActivities] = useState([])
    const [appliedFilters, setAppliedFilters] = useState(initialFilters);
    const [filters, setFilters] = useState(filtersJson)
    const [currentPage, setCurrentPage] = useState(1)
    const [sortBy, setSortBy] = useState('recent');
    const [fetchComplete, setFetchComplete] = useState(false);
    const [loading, setLoading] = useState(false)

    const [allTags, setAllTags] = useState([]);

    useEffect(() => {
        applyFilters();
    }, [appliedFilters])

    
    useEffect(() => {
        if(state.community.tags){
            formatTags(state.community.tags)
        } else getAllTags();
    }, [state.community.tags])

    useEffect(() => {
        if(state.community.sources){
            formatSources(state.community.sources)
        } else getAllSources();
    }, [state.community.sources])

    useEffect(() => {
        if(state.community.channels){
            formatChannels(state.community.channels)
        } else getAllChannels();
    }, [state.community.channels])
    



    const getAllTags = async () => {
        try{
            const allTags_ = await getAllTagsApi();
            saveMemberTags(allTags_, dispatch)
        } catch(err){
            console.log(err)
        }
    }

    const getAllSources = async () => {
        try{
            const allSources_ = await getAllSourcesApi();
            saveSources(allSources_, dispatch) 
        } catch(err){
            console.log(err)
        }
    }

    const getAllChannels = async () => {
        try{
            const allChannels_ = await getAllChannelsApi();
            saveChannels(allChannels_, dispatch) 
        } catch(err){
            console.log(err)
        }
    }

    const formatTags = (data) => {
        try{

            const allFilters = [...filters]
            setAllTags(data.map(item => ({value: item.tag, label: item.tag})))
            const index = allFilters.findIndex(item => item.value === 'memberTags')
            allFilters[index].enums = data.map(item => ({value: item.tag, label: item.tag}))
            setFilters(allFilters)

        } catch(err){
            console.log(err)
        }

    }
    
    const formatSources = async (data) => {
        try{
            
            const allFilters = [...filters]
            const index = allFilters.findIndex(item => item.value === 'source')
            allFilters[index].enums = data.map(item => ({value: item.integrationId, label: item.name}))
            setFilters(allFilters)

        } catch(err){
            console.log(err)
        }
    }

    const formatChannels = async (data) => {
        try{
            
            const allFilters = [...filters]
            const index = allFilters.findIndex(item => item.value === 'channels')
            allFilters[index].enums = data.map(item => ({value: item.channelId, label: item.name}))
            setFilters(allFilters)

        } catch(err){
            console.log(err)
        }
    }


    const applyFilters = async () => {
        try{
            const ac = await filterActivities(1);
            setCurrentPage(1)
            setActivities(ac.map(item => ({
                ...item,
                activities: item.activities.map(item2 => ({
                    ...item2,
                    url: `https://discordapp.com/channels/${item2.sourceId}/${item2.channelId}/${item2.activityId}`
                }))
            })))
            
        } catch (err) {
            console.log(err)
        }
    }

    const loadMore = async () => {
        try{
            setLoading(true)
            const ac = await filterActivities(currentPage+1);
            setActivities(prev => ([...prev, ...ac]))
            setCurrentPage(prev => prev+1)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }

    const filterActivities = async (page) => {
        try{
            const urlParams = new URLSearchParams();
            urlParams.append('page', page)
            urlParams.append('limit', 50)
            if(sortBy){
                urlParams.append('sortBy', 'timestamp')
                if(sortBy === 'recent'){
                    urlParams.append('sortDirection', "true")
                } else{
                    urlParams.append('sortDirection', "false")
                }
            }
            if(appliedFilters.length > 0){
                appliedFilters.forEach(filter => {
                    if(filter.filter.length > 0){
                        urlParams.append(filter.value, filter.filter)
                    }
                    if(new Date(filter.filter.startDate).getTime() > 0){
                        urlParams.append(filter.value + 'StartDate', filter.filter.startDate)
                        urlParams.append(filter.value + 'EndDate', filter.filter.endDate)
                    }
                })
            }
            const formattedActivities = await fetchActivities(urlParams)
            return (formattedActivities)
        } catch(err){
            console.log(err)
        }
    }

    const fetchActivities = async (urlParams) => {
        try {
            
            const fetchedActivities = await getActivitiesApi(urlParams);
            if(!fetchedActivities.hasNextPage) setFetchComplete(true)
            return formatActivityMessages(fetchedActivities.activities)

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>
            <PageHeader 
                title = "Activity"
                description="See everything that's happening across your community"
            /> 
            <Filters 
                filters = {filters}
                appliedFilters = {appliedFilters}
                setAppliedFilters = {setAppliedFilters}
                sortBy = {sortBy}
                setSortBy = {setSortBy}
            />
            {activities.map(item => <ActivityCard 
                activities = {item.activities}
                metadata = {item.metadata}
            />)}
            <Button 
                loading = {loading}
                submitResponse = {loadMore} 
                text = "Load more activities" 
                type = ""
                loadingText = "Loading..."
                className = "mt-4"
                color = "white"
                block = {true}
                icon = "tio-refresh"
                disabled = {fetchComplete}
            />
        </div>
    )
}

export default Activity




