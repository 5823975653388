import React, {useState, useEffect, useContext} from 'react'
import AuthLayout from 'components/Layout/Auth'
import SelectField from 'components/Forms/Select'
import {Button} from 'components/Forms/Buttons'
import {InputField, PasswordField} from 'components/Forms/TextInput'
import {createWorkplace, addEarlyAccessCode} from 'services/user/auth'
import validation from 'utilis/validation'
import {Store} from 'store'
import {updateUser} from 'store/actions/auth'
import {updateWorkplace} from 'store/actions/workplace'
import { getDiscordAuthLinkApi } from 'services/workplace'
import toast from 'react-hot-toast'

const CreateWorkplace = (props) => {

    const {state, dispatch} = useContext(Store)
    const [page, setPage] = useState("overview")
    const [values, setValues] = useState({workplaceName: '', fullName: '', telegram: '', twitter: '',  useCase: '', code: '', password: '', password1: ''});
    const [errors, setErrors] = useState({workplaceName: false, fullName: false, telegram: false, twitter: false, useCase: false, code: false, password: false, password1: false})
    const [loading, setLoading] = useState(false);
    const [codeError, setCodeError] = useState(false);

    
    const submitResponse = async (botRedirect) => {
        try{
            if(!validateData()) return;
            setLoading(true);
            if(page === 'overview'){
                await createWorkplace({
                    ...values
                });
                setPage('code')
                setLoading(false)
                return;
            } 
            if(page === 'code') {
                await addEarlyAccessCode(values.code);
                if(botRedirect){
                    const link = await getDiscordAuthLinkApi()
                    toast.success("Redirecting you to discord to add our bot in your server")
                    window.location = link;
                } else {
                    window.location.reload()
                }
                setLoading(false);
            }
        } catch(err){
            console.log(err);
            if(err.response && err.response.data && err.response.data.error === "INVALID_CODE"){
                setErrors(prev => ({...prev, code: true}))
                toast.error('Please enter the right early access code. If you dont have one, please get in touch with sambhav@intract.ai')
                setCodeError(true)
            } else {
                toast.error('Something went wrong. Please try again')
            }
            setLoading(false);
        }
    }


    const validateData = () => {
        const new_errors = {workplaceName: false, fullName:false, telegram: false, twitter: false, useCase: false, code: false}
        if(page === 'overview'){
            if(validation.isEmpty(values.fullName)) new_errors.fullName = true
            if(validation.isEmpty(values.workplaceName)) new_errors.workplaceName = true
            if(values.password !== values.password1) new_errors.password1 = true
            if(!validation.isPassword(values.password)) new_errors.password = true;
        }
        if(page === 'code'){
            if(validation.isEmpty(values.code)) new_errors.code = true
        }
        setErrors(new_errors)
        if(Object.values(new_errors).includes(true)) return false;
        else return true;
    }


    const editValues = (name) => (value) => {
        setValues(prev => ({...prev, [name]: value }));
    };

    const editErrors = (name) => (value) => {
        setErrors(prev => ({...prev, [name]: value }));
    };

    useEffect(() => {
        setErrors({workplaceName: false, telegram: false, twitter: false, useCase: false})
    }, [values])

    return(
        <AuthLayout>
        <form>
            <div class=" mb-5">
                <h1 class="display-4">Create your workspace</h1>
            </div>
            {page == 'overview' && <div>
                <InputField 
                    name = "workplace"
                    value = {values.fullName}
                    error = {errors.fullName}
                    setValue = {editValues('fullName')}
                    setErrors = {editErrors('fullName')}
                    placeholder = ""
                    label = "What's your full name?"
                    errorText = "Please enter your name"
                    labelClass='h1'
                    required
                />
                <InputField 
                    name = "workplace"
                    value = {values.workplaceName}
                    error = {errors.workplaceName}
                    setValue = {editValues('workplaceName')}
                    setErrors = {editErrors('workplaceName')}
                    placeholder = ""
                    label = "What's the name of your community?"
                    errorText = "Please enter the name of your community"
                    labelClass='h1'
                    required
                />
                <InputField 
                    name = "telegram"
                    value = {values.telegram}
                    error = {errors.telegram}
                    setValue = {editValues('telegram')}
                    setErrors = {editErrors('telegram')}
                    placeholder = ""
                    label = "Your telegram id or your phone number"
                    errorText = "Please enter your telegram id"
                    labelClass='h1 mt-5'
                />
                {!state.user.data.passwordHash && <>
                <PasswordField 
                    name = "password"
                    value = {values.password}
                    error = {errors.password}
                    setValue = {editValues('password')}
                    setErrors = {editErrors('password')}
                    label = "Password"
                    errorText = "Please enter a valid password"
                    size = "lg"
                    labelClass='h1'
                />
                <PasswordField 
                    name = "password"
                    value = {values.password1}
                    error = {errors.password1}
                    setValue = {editValues('password1')}
                    setErrors = {editErrors('password1')}
                    label = "Confirm Password"
                    errorText = "Re-entered Password does not match"
                    size = "lg"
                    labelClass='h1'
                />
                 <Button 
                    text = "Continue"
                    loadingText = "Creating your workplace..."
                    className = "btn-lg btn-block"
                    submitResponse = {submitResponse}
                    loading = {loading}
                    color = "primary"
                />
                </>}
            </div>}
            {page === 'code' && <div>
                <InputField 
                    name = "code"
                    value = {values.code}
                    error = {errors.code}
                    setValue = {(code_) => editValues('code')(code_.toLowerCase())}
                    setErrors = {editErrors('code')}
                    placeholder = ""
                    label = "Your early access code"
                    errorText = "Please enter your early access code."
                    description = {<>You can schedule a call if you don’t have the code using this <a target ="_blank" href ="https://calendly.com/intract/partnership">link</a></>}
                    labelClass='h1 mt-5'
                    required
                />
                {!codeError && <div class='alert alert-soft-info mt-5 mb-5' role='alert'>
                    Once you complete, you will be redirected to Discord. Please grant all the required bot permissions to integrate successfully, else please get in touch if you face any issues at sambhav@intract.ai
                </div>}

                {codeError && <div class='card mb-5'>
                    <div class='card-body'>
                        <h5 class='bold h4'>Schedule a call if you don’t have the code</h5>
                        <p class=''>Interested in getting a free trial of the most advanced web3 marketing platform out there? </p>
				        <a className='btn btn-primary' href = "https://calendly.com/intract/partnership" target="_blank">Request a demo</a>
                    </div>
                </div>}
                <div class = 'row'>
                    <div class='col-lg-12 mt-2'>
                        <Button 
                            text = "Add Discord Integration"
                            loadingText = "Creating your workplace..."
                            className = "btn-lg btn-block"
                            submitResponse = {() => submitResponse(true)}
                            loading = {loading}
                            color = "primary"
                        />
                    </div>
                    <div class='col-lg-12 mt-2'>
                        <Button 
                            text = "Continue without integration"
                            loadingText = "Creating your workplace..."
                            className = "btn-lg btn-block"
                            submitResponse = {() => submitResponse(false)}
                            loading = {loading}
                            color = "primary"
                            type = "outline"
                        />
                    </div>
                </div>
            </div>}
           
           
      </form>
      </AuthLayout>
    )
}

export default CreateWorkplace




const useCaseOptions = [{
    value: '',
    label: 'Choose one',
},{
    value: 'Protecting against spam / bad actors',
    label: 'Protecting against spam / bad actors',
},{
    value: 'Moderating the conversations / activities',
    label: 'Moderating the conversations / activities',
},{
    value: 'Monetization of your community',
    label: 'Monetization of your community',
},{
    value: 'Recruiting moderators and community managers',
    label: 'Recruiting moderators and community managers',
},{
    value: 'Attracting contributions from members',
    label: 'Attracting contributions from members',
},{
    value: 'Maintaining high engagement levels',
    label: 'Maintaining high engagement levels',
},{
    value: 'Building the community and growing members ',
    label: 'Building the community and growing members ',
}]