import { Button } from "components/Forms/Buttons";
import ActivitySteps from "components/Steps";



const MemberActivity = ({activities, setActivityPage, activitiesLoaded, loading,}) => {
    return (
        <div class="card-body " >
            <ActivitySteps  data = {activities}/>
            <Button 
                loading = {loading}
                submitResponse = {() => setActivityPage(prev => prev+1)} 
                text = "Load more activities" 
                loadingText = "Loading..."
                color = "white"
                block = {true}
                icon = "tio-refresh"
                disabled = {activitiesLoaded}
            />
        </div>
    )
} 


export default MemberActivity;