import React, {useState, useEffect} from 'react'
import {CheckBox} from 'components/Forms/Checkbox'
import {HrefButton, Button} from 'components/Forms/Buttons'
import {InputField, PasswordField} from 'components/Forms/TextInput'
import {Divider} from 'components/Others/Dividers'
import {Link} from 'react-router-dom'
import { forgotPassword } from 'services/user/auth'
import toast from 'react-hot-toast';

const Form = () => {

    const [values, setValues] = useState({email: ''});
    const [errors, setErrors] = useState({email: false})
    const [loading, setLoading] = useState(false);

    
    const submitResponse = async () => {
        try{
            if(!validateData()) return;
            setLoading(true);

            const response = await forgotPassword({
                email: values.email.trim().toLowerCase(),
            });
            toast.success('We have sent the reset link to your email')
            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err);
        }
    }


    const validateData = () => {
        const new_errors = {email: false};
        if(values.email.length === 0) new_errors.email = true;
        setErrors(new_errors)
        if(Object.values(new_errors).includes(true)) return false;
        else return true;
    }


    const editValues = (name) => (value) => {
        setValues(prev => ({...prev, [name]: value }));
    };

    const editErrors = (name) => (value) => {
        setErrors(prev => ({...prev, [name]: value }));
    };

    useEffect(() => {
        setErrors({email: false})
    }, [values])


    return(
        <form>
            <div class="text-center mb-5">
                <h1 class="display-4">Forgot password?</h1>
                <p>Enter the email address you used when you joined and we'll send you instructions to reset your password.</p>
            </div>
            <InputField 
                name = "email"
                value = {values.email}
                error = {errors.email}
                setValue = {editValues('email')}
                setErrors = {editErrors('email')}
                placeholder = "bzz@bee.com"
                label = "Email"
                errorText = "Please enter a valid email address"
                size = "lg"
            />
            <Button 
                text = "Submit"
                loadingText = "Signing in..."
                submitResponse = {submitResponse}
                loading = {loading}
                size = "lg"
                block = {true}
                color = "primary"
            />
            <div class="text-center mt-2">
                <Link class="btn btn-link" to = "/">
                    <i class="tio-chevron-left"></i> Back to Sign in
                </Link>
            </div>
      </form>
    )
}

export default Form
