export const segmentFilters = {
	'user': {
		options: [{
			value: '',
			label: 'Please select',
		}, {
			value: 'discord_username',
			label: 'Discord Username',
			type: 'text',
		}, {
			value: 'tags',
			label: 'Tags',
			type: 'select',
			options: []
		}, {
			value: 'discord_roles',
			label: 'Discord Roles',
			type: 'select',
			options: [],
		}, {
			value: 'notes',
			label: 'Notes',
			type: 'select',
			options: [{
				label: 'Has notes', 
				value: 'has_notes'
			}, {
				label: 'Does not have notes', 
				value: 'no_notes'
			}],
		}, {
			value: 'joinedOn',
			label: 'Joined On',
			type: 'date',
		}]
	},
	'activity': {
		options: [{
			value: '',
			label: 'Please select',
		}, {
			value: 'messages',
			label: 'Number of messages',
			type: 'number',
		}, {
			value: 'replies',
			label: 'Number of replies',
			type: 'number',
		}, {
			value: 'xps',
			label: 'XPs earned',
			type: 'number',
		}]
	},
	'wallet': {
		options: [{
			value: '',
			label: 'Please select',
		}, {
			value: 'wallet_native_balance',
			label: 'Native Balance',
			type: 'number',
			placeholder: 'Please enter amoun in USD',
			placeholder: '100',
		}, {
			value: 'wallet_erc_token',
			label: 'Hold a ERC20 token',
			type: 'text',
			placeholder: '0x0000000000000000000000000000000000000000'
		}, {
			value: 'wallet_nft',
			label: 'Hold a NFT',
			type: 'text',
			placeholder: '0x0000000000000000000000000000000000000000'
		}]
	}
}



export const operatorOptions = [
	{
		value: 'contains',
		label: 'Contains',
		type: 'text'
	},
	{
		value: 'do_not_contain',
		label: 'Do not contain',
		type: 'text'
	},
	{
		value: 'equals',
		label: 'Equals',
		type: 'text'
	},
	{
		value: 'exists',
		label: 'Exists',
		type: 'text'
	},
	{
		value: 'does_not_exists',
		label: 'Does not exists',
		type: 'text'
	},
	{
		value: 'greater_than',
		label: 'Greater than',
		type: 'number'
	},
	{
		value: 'greater_than_equal_to',
		label: 'Greater than equal to',
		type: 'number'
	},
	{
		value: 'less_than',
		label: 'Less than',
		type: 'number'
	},
	{
		value: 'less_than_equal_to',
		label: 'Less than equal to',
		type: 'number'
	},
	{
		value: 'equal_to',
		label: 'Equal to',
		type: 'number'
	},
	{
		value: 'equal_to',
		label: 'Equal to',
		type: 'date'
	},
	{
		value: 'greater_than',
		label: 'Greater than',
		type: 'date'
	},
	{
		value: 'less_than',
		label: 'Less than',
		type: 'date'
	},
	{
		value: 'includes',
		label: 'Includes',
		type: 'select'
	},
	{
		value: 'not_includes',
		label: 'Does not Includes',
		type: 'select'
	},
]