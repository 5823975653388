import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import toast from 'react-hot-toast'

const Address = ({address, size}) => {

  const copyText = () => {
    navigator.clipboard.writeText(address)
    toast.success("Address has been copied")
  }

  return (
    <div class=' d-flex align-items-center'>
        <span class={`text-truncate text-truncate-${size || 'lg'}`}>{address} </span>
        {address.length > 10 && <OverlayTrigger overlay={<Tooltip>Copy</Tooltip>}>
          <div class='btn btn-xs btn-icon btn-soft-dark' onClick={() =>copyText()}>
            <i class='tio-copy'></i>
          </div>
        </OverlayTrigger>}
    </div>
  )
}

export default Address