export const FETCH_USER = 'FETCH_USER'
export const FETCH_WORKPLACE = 'FETCH_WORKPLACE'
export const LOGOUT = 'LOGOUT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'


export const updateUser = async (user, changes, dispatch) =>{
    return dispatch({
       type: FETCH_USER,
       payload: {
           data: {
               ...user, 
               ...changes
            },
       }
   })
}





export const loginSuccess = async (user, dispatch) =>{
    return dispatch({
       type: LOGIN_SUCCESS,
       payload: {
           data: {
               ...user
            },
           isLoggedIn: true, 
       }
   })
}



export const loginFailure =  (dispatch) =>{
    const data = {
        type: LOGOUT_SUCCESS,
        payload: {
            isLoggedIn: false, 
        }
    }
    return dispatch(data)
}



export const logoutSuccess = (dispatch) =>{
    dispatch({
        type: LOGOUT_SUCCESS,
        payload: {
            isLoggedIn: false, 
        }
    })
}
