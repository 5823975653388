import React, {useContext, useEffect, useState} from 'react'
import { InputField } from 'components/Forms/TextInput'
import { Button } from 'components/Forms/Buttons'
import { updateUser } from 'store/actions/auth'
import { Store } from 'store'
import { submitEarlyAccessCodeApi } from 'services/workplace'

const NoAccess = () => {

	const {state, dispatch} = useContext(Store)
	const [code, setCode] = useState('')
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		setError(false)
	}, [code])

	const submitCode = async () => {
		try{
			if(code.length === 0){
				setError(true)
				return 
			}
			setLoading(true)
			let success = await submitEarlyAccessCodeApi(code)
			updateUser(state.user.data, {
				earlyAccessCode: code
			}, dispatch)
			setLoading(false)
		} catch(err){
			setLoading(false)
			console.log(err)
		}
	}

  	return (
    <div class='container py-6 mt-4'>
    	<div class="text-center">

        	<div class="mb-4">
          		<h2>Looks like you dont have our secret code!</h2>

        	</div>
			<div class="w-lg-75 mx-lg-auto mb-5">
				<figure class="device-browser">
					<div class="device-browser-header">
						<div class="device-browser-header-btn-list">
						<span class="device-browser-header-btn-list-btn"></span>
						<span class="device-browser-header-btn-list-btn"></span>
						<span class="device-browser-header-btn-list-btn"></span>
						</div>
						<div class="device-browser-header-browser-bar">https://dashboard.intract.buzz/</div>
					</div>
					<div class="device-browser-frame" style = {{backgroundColor: '#fefff9'}}>
						<img class="device-browser-img" src="/assets/img/reward.png" alt="" style = {{maxHeight: '300px', objectFit: 'cover'}}/>
					</div>
				</figure>

				<div class='row justify-content-center'>
					<div class='col-9'>
						<p class='bold lead mt-6'>Interested in getting a free trial of the most advanced web3 marketing platform out there? </p>
						<InputField 
							value = {code}
							setValue = {e => setCode(e)}
							placeholder = "Enter the early access code"
							error = {error}
							errorText = "Please enter the correct access code"
							size = "lg"
						/>
						<div class='text-right'>
							<a className='btn btn-outline-primary mr-2' href = "https://calendly.com/intract/partnership" target="_blank">Request a demo</a>
							<Button 
								loading = {loading}
								text = "Submit Code"
								submitResponse={submitCode}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default NoAccess