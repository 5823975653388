import React, {useContext, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { logoutUser } from 'services/user/auth';
import { Store } from 'store';
import { logoutSuccess } from 'store/actions/auth'


const AuthLayout = ({children}) => {

    const {state, dispatch} = useContext(Store)

    useEffect(() => {
        const ele = document.getElementsByTagName("BODY")[0];
        const ele1 = document.getElementById("main-content");
        ele.setAttribute('class', 'd-flex align-items-center min-h-100');
        ele1.setAttribute('class', 'container-fluid');
    }, [])

    const logout = async () => {
        try{
            console.log('Logging out')
            logoutSuccess(dispatch)
            await logoutUser()

        } catch(err){
            console.log(err)
        }
    }



    return (
        <div class="row">
            <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-white px-0">
                <Logo />
                <ValueProps />
            </div>
            {window.location.href.includes('/app') && <a class='position-absolute top-0 right-0 p-4 pointer' onClick={logout} style = {{zIndex: '100000'}}>
                Logout
            </a>}
            <div class="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                <div class="w-100 pt-10 pt-lg-7 pb-7" style={{maxWidth: '25rem'}}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default AuthLayout



const Logo = () => {
    return (
        <div class="position-absolute top-0 left-0 right-0 mt-3 mx-3">
            <div class="d-none d-lg-flex justify-content-between">
                <Link to="/">
                    <img class="w-100" src="/assets/svg/logos/logo.svg" alt="" style={{minWidth: '7rem', maxWidth: '7rem'}} />
                </Link>
            </div>
        </div>
    )
}



const ValueProps = () => {
    return(
        <div style={{maxWidth: '27rem'}}>
            <div class="ml-n7 mb-1">
                <img class="img-fluid" src="/assets/img/bee.png" alt="Bee" style={{width: '16rem'}} />
            </div>
            <div class="mb-5">
                <h2 class="display-4 mb-3">Supercharge your marketing and on-chain analytics</h2>
                <p class="">The first-of-its-kind web3 platform for you to analyze, retain and acquire users </p>
            </div>
            <ul class="list-checked list-checked-lg list-checked-primary list-unstyled-py-4">
                <li class="list-checked-item">
                    <span class="d-block font-weight-bold mb-1">
                    Unified stakeholder profiles
                    </span>
                    Integration with on chain and off chain data sources
                </li>
                <li class="list-checked-item">
                    <span class="d-block font-weight-bold mb-1">
                    Analytics and segmentation
                    </span>
                    Creating cohorts and analyzing funnel for targeted actions
                </li>
            </ul>
        </div>
    )
}