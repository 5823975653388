import { PageHeader } from 'components/Text';
import React, {useState, useEffect} from 'react'
import { getDiscordRolesApi } from 'services/members/roles'

const DiscordRoles = () => {

    const [roles, setRoles] = useState();

    useEffect(() => {
        getDiscordRoles()
    }, [])


    const getDiscordRoles = async (id) => {
        try{
            const data = getDiscordRolesApi('')
            setRoles(data)
        } catch(err){
            console.log(err)
        }
    }

    return (
        <div>
            <PageHeader 
                title = "Discord Roles"
                button = {<div class='btn btn-primary'>Add a discord role</div>}
            />



        </div>
    )

}

export default DiscordRoles




const headers = [
    {
        value: 'name',
        label: 'Name',
    },
    {
        value: 'color',
        label: 'color',
    },
    {
        value: 'tokens',
        label: 'tokens',
    },
    {
        value: 'tokens',
        label: 'tokens',
    },
    {
        value: 'tokens',
        label: 'tokens',
    },
]