import Chart from "components/Charts"
import HalfDoughnut from "components/Charts/HalfDoughnut"

export const OverallActivityChart = ({data}) => {
    return (
        <div class = 'card'>
            <div class="card-header">
                <h4 class="card-header-title">Overall activity</h4>
            </div>
            <div class="card-body text-center">
            {data ? <Chart 
                type ="line"
                labels = {data.xAxis}
                datasets =  {data.yAxis}
                stepsize = {10}
                yprefix = " "
                gradient = {true}
                height = "12rem"
                id = "chartjs-6"
                hideXAxis = {true}
            /> : <div class="d-flex align-items-center justify-content-center" style = {{minHeight: '12rem'}}>
                <span class='spinner-border'></span>    
            </div>}
        </div>
        </div>
    )
}




export const SentimentChart = ({data}) => {
    return(
        <div className="card mt-3">
             <div class="card-header">
                <h4 class="card-header-title">Sentiment</h4>
            </div>
            {data ? <div class="card-body text-center">
                {(data.totalNegative !== 0 || data.totalPositive !== 0) && <div class="h3">
                  {(data.totalPositive > data.totalNegative) ? <span class="badge badge-soft-success badge-pill">
                    <i class="tio-checkmark-circle"></i> Positive
                  </span> : <span class="badge badge-soft-danger badge-pill">
                    <i class="tio-clear-circle"></i> Negative
                  </span>}
                </div>}
                {(data.totalNegative !== 0 || data.totalPositive !== 0) ? <HalfDoughnut 
                    type ="doughnut"
                    labels = {["Positive","Negative"]}
                    datasets =  {[[data.totalPositive, data.totalNegative]]}
                    stepsize = {13}
                    gradient = {true}
                    height = "12rem"
                    id = "chartjs-3"
                    customColors= {['#00c9a7', '#ed4c78']}
                    customStat = {
                        <div class="chartjs-doughnut-custom-stat">
                            <small class="text-cap">Total Sentiment Messages</small>
                            <span class="h3">{data.totalPositive +  data.totalNegative}</span>
                        </div>
                    }
                /> : <div class='text-muted text-uppercase py-6 my-4 h5'>Not enough data</div>}
            </div> : <div class="d-flex align-items-center justify-content-center" style = {{minHeight: '12rem'}}>
                <span class='spinner-border'></span>    
            </div>}
        </div>
    )
}


