import React, { useEffect, useState, useContext } from "react";
import FiltersComponent from "components/Filters";
import SaveReports from './SaveReports'


const Filters = ({filters, isSavedReport, appliedFilters, setAppliedFilters}) => {

    const [showSave, setShowSave] = useState(false);

    return (
        <>
        <div class='card card card-sm card-dashed mb-3 mt-n4'>
            <div class='card-body'>
                <div class='row no-gutters'>
                    <div class='col'>
                        <FiltersComponent
                            position={isSavedReport ? "start":"between"}
                            position2="start"
                            filters = {filters}
                            colSize = "10"
                            appliedFilters = {appliedFilters}
                            setAppliedFilters = {setAppliedFilters}
                            filterButtonPosition = "right"
                            disable = {isSavedReport}
                            clearable = {false}
                        />
                    </div>
                    {!isSavedReport  && <div class='col-auto ml-n2'>
                        {appliedFilters.filter(item => {
                            if(item.filter && item.filter.length > 0) return true;
                            if(item.filter && new Date(item.filter.startDate).getTime() > 0) return true;
                            else return false;
                        }).length > 0 && 
                            <div class='btn btn-sm btn-soft-success ml-2' onClick={() => setShowSave(true)}>Save</div>
                        }
                    </div>}
                </div>
            </div>
        </div>
        <SaveReports 
            show = {showSave}
            setShow = {setShowSave}
            filters = {appliedFilters}
        />
        </>
  )
}

export default Filters