import React, {useState, useContext, useEffect} from 'react'
import { InputField, InputFieldRow } from 'components/Forms/TextInput'
import { SimpleModal } from 'components/Models/Modal'
import { Button } from 'components/Forms/Buttons'
import {Store} from 'store'
import {inviteUser} from 'services/settings/invitations'
import { SelectFieldRow } from 'components/Forms/Select'
import {getTitleCase, splitName} from 'utilis/sanitisers'
import {updateInvitations} from 'store/actions/workplace'
import toaster from 'react-hot-toast'
import {UserRoles} from 'config/constants'
import validations from 'utilis/validation'


const AddUser = ({show, setShow, invitations, setInvitations}) => {

    const {state, dispatch} = useContext(Store)
    const [values, setValues] = useState({email: '', name: '', role: ''});
    const [errors, setErrors] = useState({email: false, name: false, role: false})
    const [loading, setLoading] = useState(false);



    const submitResponse = async () => {
        try{
            if(!validateData()) return;
            setLoading(true);
            const {firstName, lastName} = splitName(values.name);
            const user = await inviteUser({
                email: values.email.trim().toLowerCase(),
                role: values.role,
                firstName,
                lastName
            })
            if(user){
                setValues({email: '', name: '', role: ''})
                toaster.success("Congrats, An invitation has been sent to your team mate!")
                const newInvitations = [...state.workplace.invitations, user]
                updateInvitations(newInvitations, dispatch)
                setShow(false)
            }
            setLoading(false);
        } catch(err){
            toaster.error(`Error occured! ${err.message}`)
            setLoading(false);
        }
    }


    const validateData = () => {
        const new_errors = {email: false, name: false, role: false}
        if(values.role.length === 0) new_errors.role = true;
        if(!validations.isEmail(values.email)) new_errors.email = true;
        if(values.name.length === 0) new_errors.name = true;
        setErrors(new_errors)
        if(Object.values(new_errors).includes(true)) return false;
        else return true;
    }


    const editValues = (name) => (value) => {
        setValues(prev => ({...prev, [name]: value }));
    };

    const editErrors = (name) => (value) => {
        setErrors(prev => ({...prev, [name]: value }));
    };

    useEffect(() => {
        setErrors({email: false, name: false, role: false})
    }, [values])

    return (
        <SimpleModal
            show = {show} 
            setShow = {setShow} 
            title = "Add User"
            footer = {
                <>
                    <Button
                        text = "Add User"
                        loadingText = "Signing in..."
                        submitResponse = {submitResponse}
                        loading = {loading}
                        block = {false}
                        color = "primary"
                    />
                </>
            }
        >
            <p class='mb-1'>
                Invite a new user to your Intract workplace. Provide an email address and choose the desired role.
            </p>

            <InputFieldRow 
                name = "name"
                value = {values.name}
                error = {errors.name}
                setValue = {editValues('name')}
                setErrors = {editErrors('name')}
                placeholder = "Elon Musk"
                errorText = "Please enter a valid name"
                label = "Name"
                icon = "tio-user"
                marginBotton = {2}
                columnSize = {3}
            />
            <InputFieldRow 
                name = "name"
                value = {values.email}
                error = {errors.email}
                setValue = {editValues('email')}
                setErrors = {editErrors('email')}
                placeholder = "elon@tesla.com"
                errorText = "Please enter a valid email"
                label = "Email"
                icon = "tio-email"
                marginBotton = {2}
                columnSize = {3}
            />
            <SelectFieldRow
                name = "name"
                value = {values.role}
                error = {errors.role}
                setValue = {editValues('role')}
                setErrors = {editErrors('role')}
                errorText = "Please enter a valid role"
                label = "Role"
                icon = "tio-format-points"
                options = {[{value: '',label:'Please Specify'}, ...Object.values(UserRoles).map(item => ({value: item, label: getTitleCase(item)}))]}
                marginBotton = {2}
                columnSize = {3}
            />

           
        </SimpleModal>
    )
}

export default AddUser
