import React, {useState, useContext} from 'react'
import {CheckBox} from 'components/Forms/Checkbox'
import {HrefButton, Button} from 'components/Forms/Buttons'
import {InputField, PasswordField} from 'components/Forms/TextInput'
import {acceptInvite} from 'services/settings/team'
import { loginSuccess } from 'store/actions/auth'
import { updateWorkplace } from 'store/actions/workplace'
import {Store} from 'store'


const Form = (props) => {
    
    const {dispatch} = useContext(Store)
    const [values, setValues] = useState({password: '', password1: ''});
    const [errors, setErrors] = useState({password: false, password1: false})
    const [loading, setLoading] = useState(false);

    
    
    const submitResponse = async () => {
        try{
            if(!validateData()) return;
            setLoading(true);
            const hash= new URL(window.location.href).searchParams.get('hash')
            const response = await acceptInvite({
                password: values.password,
                hash: hash
            });
            loginSuccess(response, dispatch)
            updateWorkplace(response.workplaceId, dispatch)
            props.history.push('/app')

            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err);
        }
    }


    const validateData = () => {
        const new_errors = {password: false, password1: false}
        if(values.password === 0) new_errors.password = true;
        if(values.password !== values.password1) new_errors.password1 = true;
        setErrors(new_errors)
        if(Object.values(new_errors).includes(true)) return false;
        else return true;
    }


    const editValues = (name) => (value) => {
        setValues(prev => ({...prev, [name]: value }));
    };

    const editErrors = (name) => (value) => {
        setErrors(prev => ({...prev, [name]: value }));
    };


    return(
        <form>
            <div class="text-center mb-5">
                <h1 class="display-4">Welcome</h1>
                <div class="lead">Create your password for first time login</div>
            </div>
            <PasswordField 
                name = "password"
                value = {values.password}
                error = {errors.password}
                setValue = {editValues('password')}
                setErrors = {editErrors('password')}
                label = "Your new password"
                size = "lg"
            />
            <PasswordField 
                name = "password"
                value = {values.password1}
                error = {errors.password1}
                setValue = {editValues('password1')}
                setErrors = {editErrors('password1')}
                label = "Confirm password"
                size = "lg"
            />
            <Button 
                text = "Login"
                loadingText = "Login..."
                className = "btn-lg btn-block"
                submitResponse = {submitResponse}
                loading = {loading}
                size = "lg"
                block = {true}
                color = "primary"
            />
      </form>
    )
}

export default Form
