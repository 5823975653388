import React, {useEffect, useState, useContext} from 'react'
import useDidMountEffect from 'hooks/useDidMount'
import Avatar from 'components/Avatar'
import PaginatedTable from 'components/Table/PaginatedTable'
import moment from 'moment'
import { PageHeader } from 'components/Text'
import { getSegmentApi, getSegmentMembersApi, downloadSegmentMembersApi } from 'services/workplace/segments'
import AssignRole from './components/AssignRole'
import toast from 'react-hot-toast'
import { BigDropdown } from 'components/Forms/Dropdown'

const Segment = (props) => {

	const [segment, setSegment] = useState({})
	const [members, setMembers] = useState([]);
    const [showRole, setShowRole] = useState(false);
	const [selected, setSelected] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [sortBy, setSortBy] = useState({key: 'joinedDate', asc: true});
    const [totalPages, setTotalPages] = useState(4);
    const [searchInput, setSearchInput] = useState('');
    const [segmentId, setSegmentId] = useState('')
    const rowsPerPage = 10;

	useEffect(() => {
		const id = props.match.params.id;
        setSegmentId(id)
        fetchSegment(id);
	}, [props.match.params])

    const fetchSegment = async (id) => {
        try{

            const segment_ = await getSegmentApi(id);
            setSegment(segment_)

        } catch(err){
            console.log(err)
        }
    }


    useEffect(() => {
        if(segmentId) {
            filterMembers()
        }
    }, [currentPage, segmentId])


    useEffect(() => {
        if(segmentId) {
            setCurrentPage(1)
            filterMembers()
        }
    }, [sortBy, searchInput])



    const filterMembers = async () => {
        await fetchSegmentMembers(getUrlParams())
    }


    const getUrlParams = () => {
        const urlParams = new URLSearchParams();
        urlParams.append('page', currentPage)
        urlParams.append('limit', rowsPerPage)
        urlParams.append('search', searchInput)
        if(sortBy && sortBy.key){
            urlParams.append('sortBy', getSortKey(sortBy.key))
            urlParams.append('sortDirection', sortBy.asc)
        }
        return urlParams
    }



    const fetchSegmentMembers = async (urlParams) => {
        try{

            const members_ = await getSegmentMembersApi(segmentId, urlParams);
            if(members_.docs.length < 10){
                setTotalPages(currentPage)
            } else {
                setTotalPages(members_.totalPages)
            }
            formatMembers(members_.docs)

        } catch(err){
            console.log(err)
        }
    }


    const formatMembers = (fetchedMembers) => {
        setMembers(fetchedMembers.map(item => ({
            username: {
                label: <Avatar 
                    name = {item.metadata.username}
                    avatar = {item.metadata.avatar.includes(null) ? '' : item.metadata.avatar}
                    link = {`/app/member/${item._id}`}
                />,
                value: item.username,
            },
            tags: {
                label: (item.adminInputs && item.adminInputs.tags && item.adminInputs.tags.length > 0) ? <div style = {{maxWidth: '140px'}}>
                    {item.adminInputs.tags.map(item2 => <span class={`badge badge-soft-${item2.color ? item2.color : 'primary'} mr-2`}>
                        {item2.label}
                    </span>)}
                </div> : <></>,
                value: (item.adminInputs && item.adminInputs.tags && item.adminInputs.tags.length > 0) ? item.adminInputs.tags.map(item => item.label).join(', ') : '',
            },
            lastActivity: moment(item.updatedAt).diff(item.createdAt, 'h') === 0 ? '' : moment(item.updatedAt).fromNow(),
            sources: {
                label: <div >
                    {item.sources.map(item2 => <span class={`badge badge-soft-primary mr-2`}>
                        {item2.type.toLowerCase()}
                    </span>)}
                </div>,
                value: '',
            },
            activityCount: item.sources.filter(a => a.type === 'DISCORD').reduce((a, b) => a + b.numMessages, 0),
            tokenHolding: item.web3MemberProfiles && item.web3MemberProfiles.reduce((a, b) => a + b.tokens.length, 0),

        })))
    }
    
    
    const downloadData = async (type) => {
        try{
            const res = await downloadSegmentMembersApi(segmentId)
            toast.success("We have emailed you a copy of your members data")

        } catch(err){
            console.log(err)
        }
    }

  	return (
    <div>
        <PageHeader 
            title = {`Segment: ${segment.name}`}
            description = {segment.description}
			button={
                <BigDropdown
                    wrapperClassName = "" 
                    popoverClassName = "navbar-dropdown-account"
                    popover = {<div>
                        <span class="dropdown-item pointer" onClick={() => setShowRole(true)}>
                            <span class="text-truncate  pr-2" >Assign Discord Role</span>
                        </span>
                    </div>}
                    style = {{width: '14rem'}}
                >
                    <div class='btn btn-primary'>
                        Take action on members
                    </div>
            </BigDropdown>}
        />
         <div class='card mt-4'>
            <PaginatedTable 
                headers = {headers} 
                data = {members}
                searchPlaceholder = "Search by username"
                rowsPerPage = {rowsPerPage}
                currentPage = {currentPage} 
                setCurrentPage = {setCurrentPage}
                sortBy = {sortBy} 
                setSortBy = {setSortBy}
                totalPages = {totalPages} 
                setTotalPages = {setTotalPages}
                searchInput = {searchInput} 
                setSearchInput = {setSearchInput}
                selected = {selected}
                setSelected = {setSelected}
                loadingOnEmpty = {true}
                downloadable
                downloadData={downloadData}
            />
        </div>
        <AssignRole 
            show = {showRole}
            setShow = {setShowRole}
            segmentId = {props.match.params.id}
        />
    </div>
  )
}

export default Segment




const headers = [{
    value: 'username',
    label: 'Username'
}, {
    value: 'tags',
    label: 'Tags'
},  {
    value: 'sources',
    label: 'Connected Sources'
}, {
    value: 'activityCount',
    label: 'Discord Activity'
}, {
    value: 'tokenHolding',
    label: 'Token Holding'
}]




const getSortKey = (value) => {
    switch(value){
        case 'username':
            return 'metadata.username'
        case 'tags':
            return 'adminInputs.tags'
        case 'sources':
            return 'sources'
        case 'activityCount':
            return 'sources.numMessages'
        case 'tokenHolding':
            return 'sources.numTokens'
    }
}
  



const dateFilterNull = {
    startDate: new Date(null), 
    endDate:new Date(null)
}