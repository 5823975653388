import React, {useState, useContext, useEffect} from 'react'
import Tags from "components/Others/Tags"
import {addTagApi, deleteTagApi} from 'services/members'
import Dropdown, { BigDropdown } from 'components/Forms/Dropdown'
import {getAllTagsApi} from 'services/workplace'
import { saveDiscordRoles, saveMemberTags } from 'store/actions/community'
import { Store } from 'store'
import { getDiscordRolesApi, unassignDiscordRoleApi } from 'services/members/roles'
import AssignRole from 'pages/Dashboard/Members/components/AssignRole'
import toast from 'react-hot-toast'

const MemberTags = ({member, setMember}) => {

    const {state, dispatch} = useContext(Store)
    const [roles, setRoles] = useState([]);
    const [show, setShow] = useState(false)

    
    useEffect(() => {
        if(state.community.discordRoles){
            formatRoles(state.community.discordRoles)
        } else getAllRoles();
    }, [state.community.discordRoles])

    
    const getAllRoles = async () =>{
        try{

            const roles_ = await getDiscordRolesApi();
            saveDiscordRoles(roles_, dispatch)

        } catch(err){
            console.log(err)
        }
    }

    const formatRoles = (roles_) => {
        try{
            setRoles(roles_.map(item => ({
                value: item.roleId, 
                label: item.name, 
                color: item.color, 
                sourceId: item.sourceId
            })))
        } catch(err){
            console.log(err)
        }
    }


    const unassignRole = async (value) => {
        try{

            await unassignDiscordRoleApi(member.memberId, member.sourceId, value);
            const newDiscordRoles = member.roles.filter(item => item.roleId !== value);
            setMember(prev => ({...prev, roles: newDiscordRoles, roleIds: newDiscordRoles}))
             
        } catch(err){
            toast.error('Permissions missing')
            console.log(err)
        }
    }


    return (
        <div class='card mb-4'>
            <div class='card-body'>
                <div className=' d-flex justify-content-between align-items-center mb-3'>
                    <div class= 'h4 mb-0'>
                        Discord Roles
                    </div>
                    <div class='btn btn-xs btn-outline-primary' onClick={() => setShow(true)}>Add Role</div>
                </div>
                <div class=''>
                    {roles && <Tags 
                        tags = {member.roleIds.map(item => ({
                            value: roles.find(item2 => item2.value === item.roleId)?.value,
                            label: roles.find(item2 => item2.value === item.roleId)?.label,
                            color: roles.find(item2 => item2.value === item.roleId)?.color,
                        }))} 
                        isClearOption = {true}
                        clearTag = {(value) => unassignRole(value)}
                    />}
                </div>
            </div>
            <AssignRole 
                show = {show}
                setShow = {setShow}
                selected = {[member._id]}
                setSelected = {() => {}}
                members = {[member]}
                setMembers = {(member) => setMember(member[0])}
            />
      </div>
    )
}




export default MemberTags;



