

const getTemplate = (templateId) => {
    if(templateId==='discord::connect-wallet') return connectWalletCampaing;
    if(templateId==='website::retweet') return retweetCampaign;
    else if(templateId===1) return retweetCampaign;
    else return retweetCampaign;
}

export default getTemplate


export const typeOptions = [
    {medium: 'DISCORD', label: 'Connect wallet with discord users', value: 'QUEST::WALLET::CONNECT'}, 
    {medium: 'WEBSITE', label: 'Join Discord Server', value: 'QUEST::DISCORD::JOIN'}, 
    {medium: 'WEBSITE', label: 'Do a retweet', value: 'QUEST::TWITTER::RETWEET'}, 
    {medium: 'WEBSITE', label: 'Follow your twitter account', value: 'QUEST::TWITTER::FOLLOW'}, 
    {medium: 'WEBSITE', label: 'Custom (where submission type is link)', value: 'QUEST::CUSTOM::LINK'}, 
    {medium: 'WEBSITE', label: 'Custom (where submission type is image)', value: 'QUEST::CUSTOM::IMAGE'}, 
    {medium: 'WEBSITE', label: 'Custom (where submission type is text)', value: 'QUEST::CUSTOM::TEXT'}, 
    {medium: 'WEBSITE', label: 'Custom (where submission is just visiting a page)', value: 'QUEST::CUSTOM::VISIT::LINK'}, 
]

export const QUEST_TYPES = {
    QUEST_DISCORD_JOIN: 'QUEST::DISCORD::JOIN', 
    QUEST_TWITTER_RETWEET: 'QUEST::TWITTER::RETWEET', 
    QUEST_TWITTER_FOLLOW: 'QUEST::TWITTER::FOLLOW', 
    QUEST_TWITTER_POST: 'QUEST::TWITTER::POST', 
    QUEST_CUSTOM_LINK: 'QUEST::CUSTOM::LINK', 
    QUEST_CUSTOM_IMAGE: 'QUEST::CUSTOM::IMAGE', 
    QUEST_CUSTOM_TEXT: 'QUEST::CUSTOM::TEXT', 
    QUEST_WALLET_CONNECT: 'QUEST::WALLET::CONNECT', 
    QUEST_CUSTOM_VISIT_LINK: 'QUEST::CUSTOM::VISIT::LINK', 
}


export const QUEST_EVALUATION_TYPES = {
    TWITTER_AUTH: 'TWITTER_AUTH', 
    DISCORD_AUTH: 'DISCORD_AUTH', 
    LINK: 'LINK', 
    IMAGE: 'IMAGE', 
    TEXT: 'TEXT', 
    VISIT_LINK: 'VISIT_LINK', 
    VISIT_LINK: 'VISIT_LINK', 
    WALLET_CONNECT: 'WALLET_CONNECT', 
}


const connectWalletCampaing = {
    title: 'Link your discord users with their wallet addresses',
    type: QUEST_TYPES.QUEST_WALLET_CONNECT,
    mission: 'Connect your wallet and get exclusive rewards.',
    guide: `1. Type the command /wallet-connect and press Enter
2. Click on the link to be redirected to the wallet connect page
3. Choose the wallet to connect . Sign in via your wallet
4. Your quest will be completed and XPs awarded.`,
    endDate: '',
    maxRecipients: 100000,
    evaluation: {
        evaluationType: QUEST_EVALUATION_TYPES.WALLET_CONNECT,
        metadata: {
            walletChain: 'polygon',
        },
    },
    startQuest: {
        type: 'DISCORD_COMMAND',
        metadata: {
            commandName: 'wallet-connect',
            commandDescription: 'Connect your wallet using link in the reply!',
        },
    }
}

const retweetCampaign = {
    title: 'Twitter Retweet Campaign',
    type: QUEST_TYPES.QUEST_TWITTER_RETWEET,
    mission: 'Retweet our twitter post',
    guide: `1. Type the command /wallet-connect and press Enter
    2. Click on the link to be redirected to the wallet connect page
    3. Choose the wallet to connect . Sign in via your wallet
    4. Your quest will be completed and XPs awarded.`,
    endDate: '',
    maxRecipients: 100,
    evaluation: {
        evaluationType: QUEST_EVALUATION_TYPES.TWITTER_AUTH,
        metadata: {
            tweetLink: 'https://twitter.com/intent/retweet?tweet_id=1234',
        },
    },
    startQuest: {
        type: 'LINK',
        metadata: {
            label: 'Retweet this link',
            link: 'https://twitter.com/intent/retweet?tweet_id=1234',
        },
    }
}


