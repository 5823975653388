import {useState} from 'react';
import { SimpleModal } from 'components/Models/Modal';
import VerticalNav from 'components/Nav/VerticalNav';
import { InputFieldRow } from 'components/Forms/TextInput'
import Store from 'store'
import {editMemberApi} from 'services/members'
import { Button } from 'components/Forms/Buttons';
import toaster from 'react-hot-toast'

const EditMemberModel = ({show, setShow, member, setMember}) => {

    const [selected, setSelected] = useState('profile')
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState({
        username: member.metadata.username,
        email: member.metadata.email,
        phone: member.metadata.phone,
        location: member.metadata.organization?.location || '',
        orgName: member.metadata.organization?.name || '',
        title: member.metadata.organization?.title || '',
        orgWebsite: member.metadata.website || '',
        twitter: member.metadata.socialIdentities?.twitter || '',
        telegram: member.metadata.socialIdentities?.telegram || '',
        github: member.metadata.socialIdentities?.github || '',
        website: member.metadata.socialIdentities?.website || '',
    })

    const saveChanges = async () => {
        try{
            setLoading(true)
            await editMemberApi(member._id, {
                'username': profile.username,
                'metadata.email': profile.email,
                'metadata.phone': profile.phone,
                'metadata.organization.location': profile.location,
                'metadata.organization.name': profile.orgName,
                'metadata.organization.title': profile.title,
                'metadata.organization.website': profile.orgWebsite,
                'metadata.socialIdentities.twitter': profile.twitter,
                'metadata.socialIdentities.telegram': profile.telegram,
                'metadata.socialIdentities.github': profile.github,
                'metadata.socialIdentities.website': profile.website,
            })
            toaster.success("Member successfully saved")
            setLoading(false)
            setShow(false)
            setMember(prev => ({
                ...prev,
                username: profile.username,
                metadata: {
                    ...prev.metadata,
                    ...profile
                }
            }))
            
        } catch (err) {
            toaster.error("Some error occured, please refresh and try again")
            setLoading(false)
            console.log(err)
        }
    }
 
    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
            title = 'Edit Member'
            size = "lg"
        >
            <div class='row'>
                <div class='col-4'>
                <VerticalNav 
                    selected={selected}
                    setSelected = {setSelected}
                    list = {editMemberList}
                    size = "sm"
                />
                </div>
                <div class='col-8'>
                    <form>
                        {editMemberProfileLabels.filter(item => item.category === selected).map(item => (
                            <InputFieldRow 
                                label = {item.label}
                                icon = {item.icon}
                                value = {profile[item.value]}
                                setValue = {(val) => {
                                    setProfile(prev => ({
                                        ...prev,
                                        [item.value]: val
                                    }))
                                }}
                                size = "sm"
                                tooltip ={item.tooltip}
                                marginBotton = {0}
                            />))}
                    </form>
                </div>
            </div>
            <div class="d-flex justify-content-end border-top pt-3 mt-3 mb-n2">
                <Button
                    submitResponse = {() => setShow(false)}
                    text = "Cancel" 
                    color = "white"
                    className = 'mr-2'
                    size="sm"
                />
                <Button
                    submitResponse = {saveChanges} 
                    text = "Save changes" 
                    loading={loading}
                    loadingText = "Saving..."
                    color = "primary"
                    size="sm"
                />
            </div>


        </SimpleModal>
    )
}


export default EditMemberModel;


const editMemberList = [
    {value: 'profile', label: 'Profile', icon: 'user'},
    {value: 'organization', label: 'Organization', icon: 'company'},
    {value: 'social', label: 'Social Identities', icon: 'route-dashed'},
]


const editMemberProfileLabels = [{
    value: 'username',
    label: 'Name',
    category: 'profile',
},{
    value: 'email',
    label: 'Email',
    category: 'profile',
},{
    value: 'phone',
    label: 'Phone',
    category: 'profile',
},{
    value: 'location',
    label: 'Location',
    category: 'profile',
}, {
    value: 'orgName',
    label: 'Organization name',
    category: 'organization',
}, {
    value: 'title',
    label: 'Title',
    category: 'organization',
}, {
    value: 'orgWebsite',
    label: 'Website url',
    category: 'organization',
}, {
    value: 'twitter',
    label: 'Twitter',
    category: 'social',
    icon: 'tio-twitter',
}, {
    value: 'telegram',
    label: 'Telegram',
    category: 'social',
    icon: 'tio-telegram',
}, {
    value: 'github',
    label: 'Github',
    icon: 'tio-github',
    category: 'social',
}, {
    value: 'website',
    label: 'Website',
    icon: 'tio-globe',
    category: 'social',
}]
