import axiosInstance from '../axios';




export const getActivitiesApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            method: 'get',
            url: '/community/activities',
            params: params
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}



export const getAllChannelsApi = async () => {
    try{
        const {data: {success, data}} = await axiosInstance.get('/data-sources/discord/channels')
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}





//What is a conversation exactly?
//How to treat thread messages
//How to treat replies
//How to treat single messages
//How do we break activities in difference sections





/*

    Relation and timeframe
    Relation -> topic similarities, thread, reply
    Timeframe -> next message within some timeframe


    Nav -> 
        Activities
            -> top threads
            -> all messages
            



    Normal Message (
        type 0, 
        the parent_id is of an actual channel and not a thread
        the message_id should not be in the channel resources,
        no replies
    )

    Thread conversation (
        Message with message id that is in channel resources as channel id is a thread starter
        every message with channel id of the message id is part of this thread conversation
    )

    Reply conversation (
        type 0,
        the parent_id is of an actual channel and not a thread
        the message_id should not be in the channel resources,
        replies
    )

    Apurv: Message 1
    Abhishek: Message 2
    Sambhav: Message 3 (reply)



    Message (reference id) -> ownwer -> owner
    Message (reference id) -> owner =


    Messages 40
    card 1
        
    card 2
    card 3








    */


