export const authState = {
    data: {},
    workplace: {},
    isLoggedIn: null,
};

export const workplaceState = {
    workplace: {},
    team: null,
    invitations: null,
    quests: null,
    cryptoPrices: {},
    tokens: null,
}

export const communityState = {
    members: null,
    activities: null,
    membersMap: new Map(),
    activitiesMap: new Map(),
    tags: null,
    sources: null,
    channels: null,
    discordRoles: null,
}