import Avatar from 'components/Avatar'
import { Table } from 'components/Table'
import { PageHeader } from 'components/Text'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {getSegmentsApi, deleteSegmentApi} from 'services/workplace/segments'
import moment from 'moment'
import Dropdown from 'components/Forms/Dropdown'

const Segments = () => {

	const [fetchedSegments, setFetchedSegments] = useState([])
	const [segments, setSegments] = useState([]);


	useEffect(() => {
		fetchSegments()
	}, [])


	const deleteSegment = async (allSegments, id) => {
		try{
			console.log(id)
			await deleteSegmentApi(id)
			const newSegments = [...allSegments]
			const index = newSegments.findIndex(segment => segment._id === id)
			console.log(index)
			newSegments.splice(index, 1)
			console.log(newSegments)
			setFetchedSegments(newSegments)
			formatSegments(newSegments)
		} catch(err){
			console.log(err)
		}
	}

	const fetchSegments = async () => {
		try{

			const segments_ = await getSegmentsApi();
			setFetchedSegments(segments_)
			formatSegments(segments_)

		} catch(err){
			console.log(err)
		}
	}

	const formatSegments = (segments_) => {
		console.log(segments_)
		setSegments(segments_.map(item => ({
			...item,
			createdAt: moment(item.createdAt).format('Do MMM YY'),
			name: {
				label: <Link to = {`/app/segments/${item._id}`} >{item.name}</Link>,
				value: item.name,
			}, 
			createdBy: {
				value: item.createdBy.firstName,
				label: <Avatar 
					name = {item.createdBy.firstName + ' ' + item.createdBy.lastName}
					avatar = {item.createdBy.avatar}
				/>
			},
			options: {
				value: item._id,
				label: <Dropdown 
					size = 'sm' 
					position = 'top' 
					minWidth = {140}
					popover = {<>
						<span class="dropdown-item text-danger" onClick={() => deleteSegment(segments_, item._id)}>Delete Segment</span>
					</>}
				>
					<a class="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle">
						<i class="tio-more-vertical"></i>
					</a>
				</Dropdown>
			}
		})))
	}

  	return (
		<div>
			<PageHeader 
				title = "Segments"
				description = "Segments are a way to group your users into groups of users. You can use segments to send rewards to specific groups of users."
				button={<Link class='btn btn-primary' to = "/app/segments/add">Create a segment</Link>}
			/>
			<div class='card mt-4'>
				<Table 
					data = {segments}
					headers = {headers} 
					size="lg"
					searchable = {false}
				/>
			</div>
		</div>
  	)
}

export default Segments




const headers = [{
	value: 'name',
	label: 'Name',
}, {
	value: 'description',
	label: 'Description',
}, {
	value: 'createdBy',
	label: 'Created By',
}, {
	value: 'createdAt',
	label: 'Created On',
}, {
	value: 'options',
	label: '',
}]



