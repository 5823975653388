import React from 'react'

const CenteredCard = ({children}) => {
    return (
        <div>
            <div class="position-fixed top-0 right-0 left-0 bg-img-hero" style={{height: "32rem", backgroundImage: "url(/assets/img/1920x400/img1.jpg)"}}>
                <figure class="position-absolute right-0 bottom-0 left-0">
                    <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
                        <polygon fill="rgb(247, 250, 252)" points="0,273 1921,273 1921,0 "></polygon>
                    </svg>
                </figure>
            </div>
            <div class="container mt-lg-7 py-5 py-sm-7">
                <a class="d-flex justify-content-center mb-5" href="index.html">
                    <img class="z-index-2" src="/assets/svg/logos/logo.svg" alt="" style={{width: "8rem"}} />
                </a>
                <div class="row justify-content-center">
                    <div class="col-md-7 col-lg-5">
                        <div class="card card-lg mb-5">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CenteredCard
