import React, {useState, useContext, useEffect} from 'react'
import SelectField from 'components/Forms/Select'
import {InputField} from 'components/Forms/TextInput'
import { DatePicker } from 'components/Forms/Datepicker'
import {operatorOptions, segmentFilters} from '../utilis/constants'
import { getAllTagsApi } from 'services/workplace'
import { getDiscordRolesApi } from 'services/members/roles';
import { Store } from 'store'
import { saveDiscordRoles, saveMemberTags } from 'store/actions/community'


const Criteria = ({criteria, setCriterias, criterias, deleteCriteria, error}) => {

	const {state, dispatch} = useContext(Store);
	const [operators, setOperators] = useState([])
	const [inputType, setInputType] = useState((criteria.type && criteria.attribute) ? segmentFilters[criteria.type].options.filter(item => item.value === criteria.attribute)[0].type : '')
	const [criteriaDetails, setCriteriaDetails] = useState((criteria.type && criteria.attribute) ? segmentFilters[criteria.type].options.filter(item => item.value === criteria.attribute)[0] : '')
    const [fetchedOptions, setFetchedOptions] = useState({
		tags: [],
		discord_roles: [],
	})
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if(criteria.attribute){
			const criteriaDetails_ = segmentFilters[criteria.type].options.filter(item => item.value === criteria.attribute)[0]
			setCriteriaDetails(criteriaDetails_)
			const inputType_ = criteriaDetails_.type
			setInputType(inputType_)
			setOperators(operatorOptions.filter(item => item.type === inputType_))
            if(inputType_ === 'select'){
				if(criteriaDetails_.options && criteriaDetails_.options.length > 0){
					setOptions(criteriaDetails_.options)
				} else {
					if(fetchedOptions[criteriaDetails_.value].length > 0){
						setOptions(fetchedOptions[criteriaDetails_.value])
					} else {
						fetchOptions(criteriaDetails_.value)
					}
				}
            }
		}
	}, [criteria.attribute])


    const fetchOptions = async (attribute) => {
        try{
            if(attribute === 'tags'){
				if(state.community.tags){
					setFetchedOptions(prev => ({...prev, tags: state.community.tags.map(item => ({value: item._id, label: item.tag}))}))
					setOptions(state.community.tags.map(item => ({value: item._id, label: item.tag})))
				} else getAllTags();
            }
            if(attribute === 'discord_roles'){
				if(state.community.discordRoles){
					setFetchedOptions(prev => ({...prev, discord_roles: state.community.discordRoles.map(item => ({value: item.roleId, label: item.name}))}))
					setOptions(state.community.discordRoles.map(item => ({value: item.roleId, label: item.name})))
				} else getAllRoles();
            }
        } catch(err){
            console.log(err)
        }
    }

	const getAllRoles = async () =>{
        try{
            const roles_ = await getDiscordRolesApi();
            saveDiscordRoles(roles_, dispatch)
			setOptions(roles_.map(item => ({value: item.roleId, label: item.name})))

        } catch(err){
            console.log(err)
        }
    }

	const getAllTags = async () => {
        try{
            const allTags_ = await getAllTagsApi();
            saveMemberTags(allTags_, dispatch)
			setOptions(allTags_.map(item => ({value: item._id, label: item.tag})))
        } catch(err){
            console.log(err)
        }
    }


	const handleAttributeChange = e => {
		const newRules = [...criterias]
		const index = criterias.findIndex(item => item.id === criteria.id);
		newRules[index].attribute = e

		const criteriaDetails_ = segmentFilters[criteria.type].options.filter(item => item.value === e)[0]
		newRules[index].operation = operatorOptions.filter(item => item.type === criteriaDetails_.type)[0].value
		setCriterias(newRules)
	}

	const handleOperationChange = e => {
		const newRules = [...criterias]
		const index = criterias.findIndex(item => item.id === criteria.id);
		newRules[index].operation = e
		setCriterias(newRules)
	}

	const handleValueChange = e => {
		const newRules = [...criterias]
		const index = criterias.findIndex(item => item.id === criteria.id);
		newRules[index].value = e
		setCriterias(newRules)
	}


	
	return (
		<div class='row align-items-end justify-content-between mb-3'>
			<div class='col'>
				<SelectField 
					type = "label"
					options = {segmentFilters[criteria.type].options}
					value = {criteria.attribute}
					setValue = {e => handleAttributeChange(e)}
					error = {!criteria.attribute ? error : false}
					errorText = "Please select attribute"
					margin="mb-0"

				/>
			</div>
			{criteria.attribute && <>
				<div class='col'>
					<SelectField 
						options = {operators}
						value = {criteria.operation}
						setValue = {e => handleOperationChange(e)}
						margin="mb-0"
					/>
				</div>
				<div class='col'>
					{inputType === 'select' && <SelectField 
						options = {options}
						value = {criteria.value}
						setValue = {e => handleValueChange(e)}
						error = {error}
						errorText = "Please select value"
						margin="mb-0"
					/>}
					{inputType === 'text' && <InputField 
						value = {criteria.value}
						setValue = {e => handleValueChange(e)}
						placeholder = {criteriaDetails.placeholder}
						error = {error}
						errorText = "Please enter value"
						margin="mb-0"
					/>}
					{inputType === 'number' && <InputField 
						value = {criteria.value}
						setValue = {e => handleValueChange(e)}
						error = {error}
						type = "number"
						label= {criteriaDetails.label}
						placeholder= {criteriaDetails.placeholder}
						labelClass='mb-0'
						errorText = "Please enter value"
						margin="mb-0"
					/>}
					{inputType === 'date' && <DatePicker 
						value = {criteria.value}
						setValue = {e => handleValueChange(e)}
						placeholder = {criteriaDetails.placeholder}
						error = {error}
						errorText = "Please enter value"
						margin="mb-0"
					/>}
				</div>
			</>}
			<div class='col-auto'>
				<button class='btn  btn-soft-danger btn-icon' onClick={() => deleteCriteria(criteria.id)}>
					<i class='tio-delete'></i>
				</button>
			</div>
		</div>
	)
}

export default Criteria