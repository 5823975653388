import React, { useEffect, useState } from 'react'
import Table from 'components/Table'
import Avatar, { UserAvatar } from 'components/Avatar'
import { useHistory } from 'react-router-dom'
import {getReportsApi} from 'services/reports'
import moment from 'moment'

const SavedReports = () => {

    const history = useHistory()
    const [reports, setReports] = useState([])

    useEffect(() => {
        fetchSavedReports()
    }, [])

    const fetchSavedReports = async () => {
        try{

            const res = await getReportsApi()
            setReports(res.map(item => ({
                createdBy: {
                    value: item.createdBy,
                    label: <UserAvatar 
                        firstName={item.ownerId.firstName} 
                        lastName={item.ownerId.lastName} 
                        avatar = {item.ownerId.avatar} 
                        size="sm" 
                        email = {item.ownerId.email}
                        discordId = {item.ownerId.discordId}
                        isBody
                        margin="mr-2"
                    />
                },
                lastUpdated: moment(item.updatedAt).format('Do MMM'),
                ...item
            })))

        } catch(err){
            console.log(err)
        }
    }

    return (
        <div>
            <div class = 'row'>
                <div class = 'col'>
                    <h2 class='mb-2'>Saved reports</h2>
                </div>
            </div>
            <div class='card mt-4'>
                <div class = ''>
                    <Table 
                        headers = {headers} 
                        data = {reports}
                        rowClick = {(id) => {
                            if(id) history.push(`/app/report/${id}`)
                        }}
                        downloadable = {false}
                    />
                </div>
            </div>
        </div>
  )
}

export default SavedReports



const headers = [{
    value: 'name',
    label: 'Name'
}, {
    value: 'description',
    label: 'Description'
}, {
    value: 'createdBy',
    label: 'Created By'
}, {
    value: 'lastUpdated',
    label: 'Last Updated'
},]


const data = Array(4).fill({
    name: 'Dashboard 1',
    createdBy: {label:<Avatar name = {"Abhishek "} link = "/app/channel/abc" size="xs"/>, value: 'John Doe'},
    lastUpdated: '12th June 2020',
    description: 'No Description',
    _id: 112
})