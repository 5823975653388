import axiosInstance from '../axios';


export const fetchHealthMetricsApi = async () => {
    try{

        const {data: {success, data}} = await axiosInstance.get('/data-sources/discord/reports/metrics/health')
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const fetchReportApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            url: '/data-sources/discord/reports/metrics',
            method: 'get',
            params: params
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const fetchEngagementReportApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            url: '/data-sources/discord/reports/metrics/engagement',
            method: 'get',
            params: params
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const fetchRolesReportApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            url: '/data-sources/discord/reports/metrics/roles',
            method: 'get',
            params: params
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const fetchRolesAssignedReportApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            url: '/data-sources/discord/reports/metrics/roles-assigned',
            method: 'get',
            params: params
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const fetchChannelReportApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            url: '/data-sources/discord/reports/metrics/channels',
            method: 'get',
            params: params
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const fetchActivityTimeApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            url: '/data-sources/discord/reports/metrics/activity/time',
            method: 'get',
            params: params
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const fetchActiveMembersApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            url: '/data-sources/discord/reports/metrics/members/active',
            method: 'get',
            params: params
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const fetchResponsivenessApi = async (params) => {
    try{

        const {data: {success, data}} = await axiosInstance({
            url: '/data-sources/discord/reports/metrics/responsiveness',
            method: 'get',
            params: params
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const saveReportApi = async (name, description, filters) => {
    try{

        const {data: {success, data}} = await axiosInstance.post(`/data-sources/discord/reports/saved/`, {
            name, description, filters
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const getReportApi = async (id) => {
    try{

        const {data: {success, data}} = await axiosInstance.get(`/data-sources/discord/reports/saved/${id}`)

        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const getReportsApi = async () => {
    try{

        const {data: {success, data}} = await axiosInstance.get('/data-sources/discord/reports/saved')
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}