import axiosInstance from '../axios';


export const updateOnboardingGuide = async (newGuide) => {
    try{

        const {data: {success, data}} = await axiosInstance.put('/user/guide/status', {
            ...newGuide
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const updateAvatarApi = async (avatar) => {
    try{
        const formData = new FormData();
        formData.append('avatar', avatar);
        const {data: {success, data}} = await axiosInstance.put('/user/profile/avatar', formData)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}



export const updateUserProfileApi = async (changes) => {
    try{
        const {data: {success, data}} = await axiosInstance.put('/user/profile/update', {
            changes
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const saveMetaMaskApi = async (address, balance) => {
    try{
        const {data: {success, data}} = await axiosInstance.put('/user/profile/metamask', {
            address,
            balance,
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


