import React, {useState, useContext, useEffect} from 'react'
import { SimpleModal } from 'components/Models/Modal';
import toaster from 'react-hot-toast'
import { Store } from 'store'
import Dropdown, { BigDropdown } from 'components/Forms/Dropdown'
import { assignBulkDiscordRoleApi, getDiscordRolesApi } from 'services/members/roles';
import { VBColorToHEX, addOpacity } from 'utilis/helpers';
import Select from 'components/Forms/Select2'
import { saveDiscordRoles } from 'store/actions/community'
import { Button } from 'components/Forms/Buttons';
import toast from 'react-hot-toast'

const AssignRole = ({show, setShow, selected, setSelected, members, setMembers}) => {

    const {state, dispatch} = useContext(Store);
    const [roles, setRoles] = useState([]);
    const [discordId, setDiscordId] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(selected && selected.length > 0) {
            let members_ = members.filter(item => selected.includes(item._id));
            let discordIds = members_.map(item => item.sourceId);
            let uniqueDiscordIds = [...new Set(discordIds)];
            if(uniqueDiscordIds.length > 1) {
                toaster.error("Your selected member belongs from multiple sources, please select members from only one source");
                setShow(false);
            } else {
                setDiscordId(uniqueDiscordIds[0])
            }
        }
    }, [selected])

    useEffect(() => {
        if(state.community.discordRoles){
            formatRoles(state.community.discordRoles)
        } else getAllRoles();
    }, [state.community.discordRoles])

    
    const getAllRoles = async () =>{
        try{

            const roles_ = await getDiscordRolesApi();
            saveDiscordRoles(roles_, dispatch)

        } catch(err){
            console.log(err)
        }
    }

    const formatRoles = (roles_) => {
        try{
            setRoles(roles_.map(item => ({
                value: item.roleId, 
                label: item.name, 
                color: item.color, 
                sourceId: item.sourceId,
                position: item.position,
                disabled: item.disabled,
            })))
        } catch(err){
            console.log(err)
        }
    }
   

    const addRole = async (roleId) => {
        try{
            setLoading(true);
            let members_ = members.filter(item => selected.includes(item._id));
            let memberIds = members_.map(item => item.memberId);
            const res = await assignBulkDiscordRoleApi(
                memberIds, 
                roleId,
                discordId
            )
            
            if(members){
                const newMembers = [...members];
                for (let i = 0; i < selected.length; i++) {
                    const index = newMembers.findIndex(member => member._id === selected[i]);
                    if(index == -1) continue;
                    if(!newMembers[index]) continue;
                    let newRole = roles.find(item => item.value === roleId);
                    let allRoles = [{roleId: newRole.value, _id: new Date().getTime()}]
                    if(newMembers[index].roleIds.map(item => item.roleId).includes(roleId)){
                        continue;
                    }
                    if(newMembers[index].roleIds){
                        allRoles = [...newMembers[index].roleIds, ...allRoles];
                    }
                    newMembers[index].roleIds = allRoles
                    if(newMembers[index].roles &&  newMembers[index].roles.type === 'div'){
                        newMembers[index].roles = <div style = {{maxWidth: '180px'}}>{allRoles.map(item2 => {
                            const role = roles.find(item3 => item3.value === item2.roleId);
                            if(role){
                                return ( 
                                    <span class={`badge mr-2`} style = {{color: VBColorToHEX(role.color), backgroundColor: addOpacity(VBColorToHEX(role.color), 0.1)}}>
                                        {role.label}
                                    </span>)
                            }
                        })}</div>
                    } else {
                        newMembers[index].roles = allRoles
                    }
                }
                setMembers(newMembers)
            }

            setLoading(false);
            setShow(false);
            setSelected([])
            setSelectedRole('')
            
        } catch(err){
            console.log(err)
            setLoading(false);
            toast.error("You are missing permissions! Intract can only manage roles for only those users, bots who are lower in the role hierarchy for your server")
        }
    }


    const createNewRole = () => {

    }
    

    return (
        <SimpleModal 
            show = {show} 
            setShow = {setShow}
            title = "Assign role to your members"
            size = "md"
            footer={<div>
                <Button 
                    size="sm"
                    text=  "Assign role"
                    loading={loading}
                    submitResponse = {() => addRole(selectedRole)}
                />
            </div>}
        >
            <div class='alert alert-soft-primary'>
            Assign a role to a member from the role list defined in your Discord server. Some roles can only be assigned directly from Discord (e.g. bot roles, admin)
            </div>
            <div>
                <Select 
                    options = {roles.sort((a, b) => (b.position - a.position)).filter(item => !item.disabled).filter(item => {
                        return discordId === item.sourceId
                    }).map(item => ({
                        value: item.value,
                        label: item.label,
                        icon: <span>
                            <i class={`tio-circle mr-2`} style = {{color: VBColorToHEX(item.color)}}></i>
                        </span>,
                    }))}
                    value = {selectedRole}
                    setValue = {(value) => setSelectedRole(value)}
                >
                    {selectedRole ? <div class='btn btn-block btn-sm btn-outline-dark-2'>
                        <i class={`tio-circle mr-2`} style = {{color: VBColorToHEX(roles.find(item => item.value === selectedRole).color)}}></i>
                        <span class='text-truncate' >{roles.find(item => item.value === selectedRole).label}</span>
                    </div> : <div class='btn btn-block btn-sm btn-outline-dark-2'>
                        <i class='tio-add-circle'></i> Select a discord role
                    </div>}
                </Select>
            </div>

        </SimpleModal>
    )
}

export default AssignRole






const onlyUnique = (key) => {
    return (prev, next) => {
        return prev[key] === next[key] ? false : true;
    }
}

