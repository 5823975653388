import {useState} from 'react';
import { SimpleModal } from 'components/Models/Modal';
import VerticalNav from 'components/Nav/VerticalNav';
import { InputFieldRow } from 'components/Forms/TextInput'
import Store from 'store'
import {addQuestApi} from 'services/quests'
import { Button } from 'components/Forms/Buttons';
import toaster from 'react-hot-toast'
import { Link, useHistory } from 'react-router-dom';


const AddQuest = ({show, setShow, type}) => {

    const [templates, setTemplates] = useState(templatesData);
    const [filters, setFilters] = useState([]);

    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
            size = "xl"
            >
            <div class='h2 mt-2 mb-0'>Add a quest</div>
            <div class='pt-5 mb-3'>
                {/* <Filters 
                    filters = {filters}
                    setFilters = {setFilters}
                /> */}
                <div class='row'>
                    {templates.filter(item => item.type === type).map(item => (<QuestItem {...item}/>))}
                    {/* <QuestItem {...customQuestOptions}/> */}
                </div>
            </div>
        </SimpleModal>
    )
}

export default AddQuest




const Filters = ({filters, setFilters}) => {
    return (
        <div class='d-flex align-items-center mt-n2 mb-3'>
            {filterOptions.map(item => (
                <div 
                    class={`btn btn-pill px-4 mr-3 btn-sm btn${filters.includes(item.value) ? '':'-outline'}-dark`}
                    onClick = {() => {
                        const index = filters.findIndex(item1 => item1 === item.value)
                        if(index > -1) setFilters(prev => prev.filter(item1 => item1 !== item.value))
                        else setFilters(prev1 => [...prev1, item.value])
                    }}
                >{item.label}</div>
            ))}
        </div>
    )
}






const QuestItem = ({title, description, autoEval, icon, id, status}) => {

    const history = useHistory()

    return (
        <div class="col-4 mb-3 mb-lg-5">
            <div 
                class={`card  ${status !== 'coming-soon' ? 'card-hover-shadow pointer' : 'bg-soft-light'} h-100`}
                onClick = {() => {
                    if(status !== 'coming-soon') {
                        history.push({
                            pathname: "/app/quest/add/",
                            search: `?templateid=${id}+&medium=${id.split('::')[0].toUpperCase()}`
                        })
                    }
                }}
            >
                <div class="card-body pb-0" style = {{minHeight: '130px'}}>
                    <div class="row  no-gutters align-items-center mb-2">
                        <div class="col-auto mr-3">
                            <div class="avatar avatar-sm avatar-soft-success avatar-circle ">
                                <span class='avatar-initials'>
                                    <i class={icon}></i>
                                </span> 
                            </div>
                        </div>
                        <div class="col">
                            <h4 class="mb-1">
                                <div href="#">{title}</div>
                            </h4>
                        </div>
                    </div>
                    <p class='text-dark mb-0'>{description}</p>
                    {status === 'coming-soon' && <span class='badge badge-primary mt-n4 mb-3'>coming soon</span>}
                </div>
                <div class="card-footer border-0 pt-0">
                    <div class="list-group list-group-flush list-group-no-gutters">
                        <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <small class="card-subtitle">Auto Evaluation:</small>
                                </div>
                                <div class="col-auto">
                                    <span class={`badge badge-soft-${autoEval ? 'success' : 'danger'} p-2`}>{autoEval ? 'Yes' : 'No'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



const templatesData = [{
    title: "Link wallet addresses",
    description: "Link discord users with their wallet addresses",
    autoEval: true,
    type: 'DISCORD',
    id: 'discord::connect-wallet',
    icon: 'tio-wallet',

}, {
    title: "Link twitter profiles",
    description: "Link discord users with their twitter profiles",
    autoEval: true,
    status: 'coming-soon',
    type: 'DISCORD',
    icon: 'tio-twitter',

}, {
    title: "Link telegram profiles",
    description: "Link discord users with their telegram profiles",
    autoEval: true,
    status: 'coming-soon',
    type: 'DISCORD',
    icon: 'tio-telegram',

}, {
    title: "Give it your own spin",
    description: "Create a custom quest for your community",
    autoEval: false,
    type: 'WEBSITE',
    id: 'website::custom',
    icon: 'tio-globe',
}]


const filterOptions = [{
    value: 'getting_started',
    label: 'Getting Started'
}, {
    value: 'discord',
    label: 'Discord'
}, {
    value: 'social_media',
    label: 'Social Media'
}, {
    value: 'community',
    label: 'Community'
}]

const customQuestOptions = {
    title: 'Create a custom quest',
    description: "You can choose for members to submit a link, text or an image",
    autoEval: false
}