import axiosInstance from '../axios';


export const getAllQuestsApi = async () => {
    try{

        const {data: {success, data}} = await axiosInstance.get('/quests/')
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const getQuestApi = async (questId) => {
    try{

        const {data: {success, data}} = await axiosInstance.get(`/quests/${questId}`)
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const toggleQuestApi = async (questId, status) => {
    try{

        const urlParams = new URLSearchParams()
        urlParams.append('status', status.toString())
        const {data: {success, data}} = await axiosInstance({
            url: `/quests/${questId}/status`,
            params: urlParams,
            method: 'PUT'
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const editQuestApi = async (questId, body) => {
    try{

        const {data: {success, data}} = await axiosInstance.put(`/quests/${questId}/`, {
            ...body
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}



export const launchQuestApi = async (body) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/quests/', body)
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const getSubmissionsApi = async (questId) => {
    try{

        const {data: {success, data}} = await axiosInstance.get(`/quests/${questId}/submissions`,)
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const changeStatusApi = async (questId, submissionIds, status) => {
    try{

        const {data: {success, data}} = await axiosInstance.put(`/quests/${questId}/submissions/status`, {
            submissionIds,
            status,
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const completeQuestApi = async (questId) => {
    try{

        const {data: {success, data}} = await axiosInstance.put(`/quests/${questId}/status`, {
            status: 'COMPLETED',
        })
        if(success) return data

    }catch(err){
        console.log(err)
        throw err
    }
}

