import React from 'react'
import Report from '../Report'

const PublishedReport = () => {
  return (
    <div class='row mt-5 align-items-center justify-content-center'>
        <div class='col-lg-10'>
            <Report />
        </div>
    </div>
  )
}

export default PublishedReport