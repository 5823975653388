import Swal from 'sweetalert2'


export const fireSwal = async ({text, title, icon, confirmText, cancelText}) => {
    try{
        const response = await Swal.fire({
            icon,
            title,
            text,
            buttonsStyling: !1,
            showDenyButton: cancelText ? 1 : 0,
            confirmButtonText: confirmText,
            denyButtonText: cancelText,
            customClass: {
                icon: 'mt-14',
                title: 'pt-4',
                htmlContainer: '',
                denyButton: "btn btn-soft-danger mb-5 px-5",
                confirmButton: "btn btn-primary mr-3 mb-5 px-5",
            },
            showClass: {
                backdrop: 'swal2-noanimation', 
                popup: '',                     
              },
            hideClass: {
                popup: '',                     
            }
        })
        return response.isConfirmed;

    } catch(err){
        console.log(err)
    }
}