import React, { useEffect, useRef } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, Calendar } from 'react-date-range';
import { DefinedRange } from 'react-date-range';
import moment from 'moment'
import { clickOutside } from 'utilis/helpers';

export const PunctualityDateRangePicker = ({inputClass, disable, label, error, value, onChange, minDate, maxDate, show, setShow}) => {

    const ref = useRef();

    useEffect(() => {
        if(show && ref && ref.current) {
            ref.current.click();
        }
    }, [show])

    const initialization = {
        startDate : null,
        endDate : new Date(""),
    }

    return (
        !disable ? <div >
            <OverlayTrigger 
                trigger="click" 
                rootClose 
                placement="bottom" 
                overlay={
                <Popover id="popover-trigger-click-root-close" title="Choose dates..." style={{maxWidth: "600px"}}>
                    <DateRangePicker
                        ranges={new Date(value.startDate).getTime() === 0 ? [initialization] : [value]}
                        onChange={(a) => {
                            onChange(a.range1)}
                        }
                        showSelectionPreview={false}
                        moveRangeOnFirstSelection={false}
                        showDateDisplay = {false}
                        direction="horizontal"
                        minDate = {minDate}
                        maxDate = {maxDate}
                        verti = {true}
                        scroll = {{enabled: true}}
                        displayMode={true}
                        shownDate = {new Date()}
                    />
                </Popover>}
            >
                <div class='position-relative' ref = {ref}>
                    {!label && <button id="js-daterangepicker-predefined" class="btn btn-sm btn-white dropdown-toggle mb-2 mb-sm-0" >
                        <i class="tio-date-range"></i>
                        <span class="js-daterangepicker-predefined-preview ml-1">{parseDateRange(value.startDate, value.endDate)}</span>
                    </button>}
                    {label && label}
                </div>
            </OverlayTrigger>
        </div> : <div class='position-relative' ref = {ref}>
                    {!label && <button id="js-daterangepicker-predefined" class="btn btn-sm btn-white dropdown-toggle mb-2 mb-sm-0" >
                        <i class="tio-date-range"></i>
                        <span class="js-daterangepicker-predefined-preview ml-1">{parseDateRange(value.startDate, value.endDate)}</span>
                    </button>}
                    {label && label}
                </div>
    )
}


export const PunctualityDatePicker = ({inputClass, disable, label, error, value, onChange, minDate, maxDate, show, setShow}) => {

    // const ref = useRef();

    // useEffect(() => {
    //     console.log(ref)
    //     if(show && ref && ref.current) {
    //         console.log(ref.current)
    //         ref.current.click();
    //     }
    // }, [show])


    return (
        <div >
            <OverlayTrigger 
                trigger="click" 
                rootClose 
                placement="bottom" 
                overlay={
                <Popover id="popover-trigger-click-root-close" title="Choose dates..." style={{maxWidth: "600px"}}>
                    <Calendar
                        // ranges={value}
                        onChange={(a) => {
                            onChange(a)
                            clickOutside()
                        }}
                        // showSelectionPreview={false}
                        // moveRangeOnFirstSelection={false}
                        showDateDisplay = {false}
                        direction="horizontal"
                        minDate = {minDate}
                        maxDate = {maxDate}
                        verti = {true}
                        scroll = {{enabled: true}}
                        displayMode={true}
                        shownDate = {new Date()}
                        // initialFocusedRange = {{
                        //     startDate: new Date(),
                        //     endDate: new Date()
                        // }}
                    />
                </Popover>}
            >
                <div class='position-relative'>
                    {!label && <button id="js-daterangepicker-predefined" class="btn btn-sm btn-white dropdown-toggle mb-2 mb-sm-0" >
                        <i class="tio-date-range"></i>
                        <span class="js-daterangepicker-predefined-preview ml-1">{parseDateRange(value.startDate, value.endDate)}</span>
                    </button>}
                    {label && label}
                </div>
            </OverlayTrigger>
        </div>
    )
}


const PopoverClickRootClose = ({value, onChange, minDate, maxDate}) => (
    <Popover id="popover-trigger-click-root-close" title="Choose dates..." style={{maxWidth: "600px"}}>
        <DateRangePicker
            ranges={[value]}
            onChange={(a) => {onChange(a.range1)}}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            showDateDisplay = {false}
            direction="horizontal"
            minDate = {minDate}
            maxDate = {maxDate}
            verti = {true}
            scroll = {{enabled: true}}
            displayMode={true}
            shownDate = {new Date()}
            // initialFocusedRange = {{
            //     startDate: new Date(),
            //     endDate: new Date()
            // }}
        />
    </Popover>
)






export const DatePicker = ({icon, label, tooltip, minDate, maxDate, value, description, name, error, setValue, setError, size, errorText, marginBotton, margin, columnSize}) => {
    return (
        <div class={`form-group ${margin ? margin: ''}` }>
            {label && <div class="d-flex align-items-center">
                {icon && <i class={`${icon} nav-icon`}></i>}
                {label && label}
                {tooltip && <OverlayTrigger overlay = {<Tooltip>{tooltip}</Tooltip>}>
                    <i class="tio-help text-secondary ml-2" ></i>
                </OverlayTrigger>}
            </div>}
            <PunctualityDatePicker 
                value = {value}
                onChange = {(value) => setValue(new Date(value))} 
                label = {
                    <div class="">
                        <input class='form-control mt-0 mb-0' value = {value ? moment(value).format('Do MMM YY') : ''}/>
                    </div>
                }
                minDate = {minDate? new Date(minDate) : new Date(moment().subtract(2, 'month').format('YYYY-MM-DD'))} 
                maxDate = {maxDate ? new Date(maxDate) : new Date(moment().add(3, 'month').format('YYYY-MM-DD'))}
            />
        </div>
    )
}



export const DateFieldRow = ({icon, label, tooltip, minDate, maxDate, value, description, name, error, setValue, setError, size, errorText, marginBotton, columnSize}) => {
    return (
        <div class={`row align-items-center form-group mb-${marginBotton}`}>
            <div class={`col-sm-${columnSize ? columnSize : 4} col-form-label input-label mb-sm-0`}>
                <div class="d-flex align-items-center">
                    {icon && <i class={`${icon} nav-icon`}></i>}
                    {label}
                    {tooltip && <OverlayTrigger overlay = {<Tooltip>{tooltip}</Tooltip>}>
                    <i class="tio-help text-secondary ml-2" ></i>
                </OverlayTrigger>}
                </div>
            </div>
            <div class="col-sm">
                <PunctualityDatePicker 
                    value = {value}
                    onChange = {(value) => setValue(new Date(value))} 
                    label = {
                        <div class="">
                            <input class='form-control mb-0' value = {value ? moment(value).format('Do MMM YY') : ''}/>
                        </div>
                    }
                    minDate = {minDate? new Date(minDate) : new Date(moment().subtract(2, 'month').format('YYYY-MM-DD'))} 
                    maxDate = {maxDate ? new Date(maxDate) : new Date(moment().add(3, 'month').format('YYYY-MM-DD'))}
                />
            </div>
        </div>
    )
}
















export const DefinedDateRangePicker = ({inputClass, error, value, onChange, minDate, maxDate}) => {


    const popoverClickRootClose = (
        <Popover id="popover-trigger-click-root-close" title="Choose dates..." style={{maxWidth: "600px"}}>
            <DefinedRange
                ranges={[value]}
                onChange={(a) => {onChange(a.range1)}}
            />
        </Popover>
    )

    return (
        <div >
            <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popoverClickRootClose}>
                <div class='position-relative'>
                    <button id="js-daterangepicker-predefined" class="btn btn-sm btn-white dropdown-toggle mb-2 mb-sm-0">
                        <i class="tio-date-range"></i>
                        <span class="js-daterangepicker-predefined-preview ml-1">{parseDateRange(value.startDate, value.endDate)}</span>
                    </button>
                </div>
            </OverlayTrigger>
        </div>
  )
}






export const parseDateRange = (startDate, endDate) => {

    const startDateMoment = moment(startDate)
    const endDateMoment = moment(endDate)

    // if(startDateMoment.diff(moment().startOf('week')) === 0 && endDateMoment.diff(moment().endOf('week')) === 0) return 'This Week'
    // if(startDateMoment.diff(moment().startOf('week'), 'day') === -7 && endDateMoment.diff(moment().endOf('week'), 'day') === -7) return 'Last Week'
    // if(startDateMoment.diff(moment().startOf('month'), 'day') === 0 && endDateMoment.diff(moment().endOf('month'), 'day') === 0) return 'This Month'
    // if(startDateMoment.diff(moment().startOf('month'), 'day') === -30 && endDateMoment.diff(moment().endOf('month'), 'day') === -30) return 'Last Month'
    // if(startDateMoment.diff(moment().startOf('month'), 'day') === -31 && endDateMoment.diff(moment().endOf('month'), 'day') === -31) return 'Last Month'
    // if(startDateMoment.diff(moment().startOf('month'), 'day') === -31 && endDateMoment.diff(moment().endOf('month'), 'day') === -30) return 'Last Month'
    // if(startDateMoment.diff(moment().startOf('month'), 'day') === -30 && endDateMoment.diff(moment().endOf('month'), 'day') === -31) return 'Last Month'
    // if(startDateMoment.diff(moment().startOf('day'), 'day') === 0 && endDateMoment.diff(moment().endOf('day'), 'day') === 0) return 'Today'
    // if(startDateMoment.diff(moment().startOf('day'), 'day') === -1 && endDateMoment.diff(moment().endOf('day'), 'day') === -1) return 'Yesterday'
    return startDateMoment.format('Do MMM YY') + ' - ' + endDateMoment.format('Do MMM YY')
}

