import { SimpleModal } from 'components/Models/Modal'
import React, {useState, useEffect, useContext} from 'react'
import { useMoralis } from 'react-moralis'
import { saveMetaMaskApi } from 'services/user'
import {Store} from 'store'
import { ethers } from "ethers";

const QuestTypes = ({show, setShow, selectType}) => {

    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
        >
            <div class='py-4'>
            <div class='px-6 text-center mb-6'>
                <div class='h2 mb-1'>Create a quest</div>
                <p>Select the medium for your members to check out and complete the quests</p>
            </div>

            <div class='row justify-content-center'>
                <div class='col-10 mb-4'>
                    <div class='card bg-light card-hover-shadow pointer' onClick={() => selectType('DISCORD')}>
                        <div class='card-body d-flex align-items-center justify-content-center'>
                            <img src = "/assets/svg/brands/discord.svg" class='img-fluid' style = {{height:'50px'}}/>
                            <div class='bold lead ml-3'>
                                Launch a quest in your discord server
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-10 mb-4'>
                <div class='card bg-light card-hover-shadow pointer' onClick={() => selectType('WEBSITE')}>
                        <div class='card-body d-flex align-items-center justify-content-center'>
                            <img src = "/assets/svg/brands/website.jpg" class='img-fluid' style = {{height:'50px'}}/>
                            <div class='bold lead ml-3'>
                                Launch a quest on your website
                            </div>
                            <div class='badge badge-primary'>Coming Soon</div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
        </SimpleModal>
    )
}

export default QuestTypes