import React, { useEffect, useState, useContext } from 'react';
import {Route, Redirect, useHistory} from 'react-router-dom'
import {Store} from 'store'
import {getUser} from 'services/user/auth'
import { loginSuccess, loginFailure } from 'store/actions/auth';
import { updateWorkplace } from 'store/actions/workplace';
import BeatSpinner from '../Forms/Spinners'
import Dashboard from 'pages/Dashboard/Layout';


const ProtectedRoute =  ({ component: Component,  role, dashboard, ...rest }) => {
    
    
    const {state, dispatch} = useContext(Store);
    const [loading, setLoading] = useState(true);
    const [didMount, setDidMount] = useState(true);


    useEffect(() => {
        setDidMount(true);
        fetching();
        return () => setDidMount(false);
    }, [])

    const fetching = async () => {
        if(state.user.isLoggedIn === null){
            try{
                const user = await getUser();
                loginSuccess(user, dispatch)
                updateWorkplace(user.workplaceId, dispatch)
            } catch(err){
                loginFailure(dispatch)
            }
        } 
    }

    useEffect(() => {
        if(state.user.isLoggedIn === true){
            setLoading(false)
        } 
        else if(state.user.isLoggedIn === false){
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [state.user])


    if(!didMount) {
        return null;
    }  

    return(
        <React.Fragment>
        {!loading ? <Route {...rest} render = {
            (props) => {
                    if(state.user.isLoggedIn === true){
                        // if(!state.user.data.workplaceId.isActive){
                        //     return <Redirect to={{pathname: `/subscription-ended`, state: props.location}} />
                        // }
                        if(!state.user.data.isWorkplaceCreated  && rest.path !== '/app/create'){
                            return <Redirect to={{pathname: `/app/create`, state: props.location}} />
                        }
                        else if(state.user.data.isWorkplaceCreated && state.user.data.earlyAccessCode !== 'buzz'  && rest.path !== '/app/no-access'){
                            return <Redirect to={{pathname: `/app/no-access`, state: props.location}} />
                        }
                        else if(state.user.data.isWorkplaceCreated &&  state.user.data.earlyAccessCode === 'buzz' && !state.user.data.isEmailVerified && rest.path !== '/app/verify-email' ){
                            return <Redirect to={{pathname: `/app/verify-email`, state: props.location}} />
                        }
                        else if(rest.path === '/app/no-access' && state.user.data.isWorkplaceCreated && state.user.data.earlyAccessCode === 'buzz'){
                            return <Redirect to={{pathname: `/app/`, state: props.location}} />
                        }
                        else if(rest.path === '/app/verify-email' && state.user.data.isEmailVerified){
                            return <Redirect to={{pathname: `/app/`, state: props.location}} />
                        }
                        else if(rest.path === '/app/create' && state.user.data.isWorkplaceCreated){
                            return <Redirect to={{pathname: `/app/`, state: props.location}} />
                        }
                        else {
                            if(dashboard) return <Dashboard><Component {...props} /></Dashboard>
                            else return <Component {...props} />
                        }
                    } else if(state.user.isLoggedIn === false){
                        return <Redirect to={{pathname: '/', state: props.location}} />
                    }
                }
            } 
        /> : <BeatSpinner loading = {true} />}
        </React.Fragment>
    )
}

export default ProtectedRoute;


