import React, {useState, useContext} from 'react'
import { CommentItem, StepItem } from 'components/Steps'
import {saveNotesApi} from 'services/members'
import {Store} from 'store'
import moment from 'moment'
import toaster from 'react-hot-toast'

const Notes = ({notes, memberId, member, setMember}) => {

    const {state, dispatch} = useContext(Store)
    const [note, setNote] = useState('');


    const saveComment = async () => {
        try{

            const note_ = await saveNotesApi(note, memberId)
            note_.authorId = {
                firstName: state.user.data.firstName,
                avatar: state.user.data.avatar
            }
            setMember(prev => ({
                ...prev,
                notes: [...prev.notes, note_]
            }))
            toaster.success("Note successfully saved")
            setNote('');


        } catch(err){
            toaster.error("Some error occured, please refresh and try again")
            console.log(err)
        }
    }

    return (
        <div class="card-body">
            <ul class="step step-icon-sm">
                <CommentItem 
                    comment = {note} 
                    setComment = {setNote} 
                    saveComment = {saveComment}
                    avatar = {state.user.data.avatar}
                    firstName = {state.user.data.firstName}
                    discordId = {state.user.data.discordId}
                />
                {notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => <StepItem 
                    time = {moment(item.createdAt).fromNow()}
                    title = {item.authorId.firstName} 
                    description = {item.note}
                    img = {item.authorId.avatar} 
                />)}
            </ul>
        </div>
    )
}

export default Notes


