import { Button } from 'components/Forms/Buttons'
import { InputFieldRow } from 'components/Forms/TextInput'
import React, {useState, useRef, useContext} from 'react'
import AvatarInpurRow from 'components/Forms/FileAttachment'
import { updateWorkplaceApi } from 'services/workplace'
import {Store} from 'store'
import { updateWorkplace } from 'store/actions/workplace'
import toaster from 'react-hot-toast'

const WorkplaceSettings = () => {

    const {state, dispatch} = useContext(Store)
    const [selected, setSelected] = useState('profile')
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState({
        name: state.workplace?.workplace?.name,
        description: state.workplace?.workplace?.metadata?.description,
        twitter: state.workplace?.workplace?.metadata?.twitter,
        website: state.workplace?.workplace?.metadata?.website,
    })

    const saveChanges = async () => {
        try{
            if(!validate()) return;
            setLoading(true)
            await updateWorkplaceApi({
                'name': profile.name,
                'metadata.website': profile.website,
                'metadata.description': profile.description,
                'metadata.twitter': profile.twitter
            })
            const newWorkplace = state.workplace.workplace;
            newWorkplace.name = profile.name;
            newWorkplace.metadata.description = profile.description;
            newWorkplace.metadata.website = profile.website;
            newWorkplace.metadata.twitter = profile.twitter;
            updateWorkplace(newWorkplace, dispatch)
            toaster.success("Your workplace successfully updated")

            setLoading(false)
            
        } catch (err) {
            setLoading(false)
            toaster.error("Error occured! Please refresh and try again")
            console.log(err)
        }
    }


    const validate = () => {
        return true;
     }

    return (
        <div id="account-workplace" class="card mb-3 mb-lg-5">
            <div class="card-header">
                <h4 class="card-title">Your community</h4>
            </div>
            <div class="card-body">

                {editMemberProfileLabels.map(item => (
                    <InputFieldRow
                        label = {item.label}
                        icon = {item.icon}
                        value = {profile[item.value]}
                        setValue = {(val) => {
                            setProfile(prev => ({
                                ...prev,
                                [item.value]: val
                            }))
                        }}
                        size = "sm"
                        tooltip ={item.tooltip}
                        marginBotton = {0}
                        columnSize = {3}
                        type = {item.type}
                />))}
                <div class="d-flex justify-content-end border-top pt-3 mt-3 mb-n2">
                    <Button
                        submitResponse = {saveChanges} 
                        text = "Save changes" 
                        loading={loading}
                        loadingText = "Saving..."
                        color = "primary"
                    />
                </div>
            </div>
        </div>
    )
}

export default WorkplaceSettings



const editMemberProfileLabels = [{
    value: 'name',
    label: 'Full name',
    icon: 'tio-company'
},{
    value: 'description',
    label: 'Description',
    icon: 'tio-text-left',
    type: 'textarea'
},{
    value: 'website',
    label: 'Website',
    icon: 'tio-website'
},{
    value: 'twitter',
    label: 'Twitter',
    icon: 'tio-twitter'
}]
