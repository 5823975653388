import React from 'react'


const Header = ({step}) => {
    return (
        <ul id="addUserStepFormProgress" class="js-step-progress step step-sm step-icon-sm step step-inline step-item-between mb-3 mb-md-5">
            <li class={`step-item ${step === 'details' && 'active focus'}`}>
                <a class="step-content-wrapper">
                    <span class="step-icon step-icon-soft-dark">1</span>
                    <div class="step-content">
                        <span class="step-title">Quest Details</span>
                    </div>
                </a>
            </li>
            <li class={`step-item ${step === 'evaluation' && 'active focus'}`}>
                <a class="step-content-wrapper">
                    <span class="step-icon step-icon-soft-dark">2</span>
                    <div class="step-content">
                        <span class="step-title">Evaluation details</span>
                    </div>
                </a>
            </li>
            <li class={`step-item ${step === 'rewards' && 'active focus'}`}>
                <a class="step-content-wrapper">
                    <span class="step-icon step-icon-soft-dark">3</span>
                    <div class="step-content">
                        <span class="step-title">Rewards</span>
                    </div>
                </a>
            </li>
            <li class={`step-item ${step === 'confirm' && 'active focus'}`}>
                <a class="step-content-wrapper">
                    <span class="step-icon step-icon-soft-dark">4</span>
                    <div class="step-content">
                      <span class="step-title">Confirmation</span>
                    </div>
                </a>
            </li>
        </ul>
  )
}

export default Header