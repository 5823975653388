import SelectField from 'components/Forms/Select';
import React, {useState, useEffect} from 'react'


const SegmentComponent = () => {

	const [rules, setRules] = useState([]);


	const addRule = (type) => {
		setRules([...rules, {
			type: type,
		}])
	}


	return (
		<div>
			<div>
				<h4  class='mb-3'>Segment Criteria</h4>
				<div>
					{rules.map(item => <Rule {...item}/>)}
				</div>
			</div>
			<div class='d-flex mt-4'>
				<div 
					class='btn btn-soft-primary btn-sm mr-2'
					onClick={() => addRule('user')}
				>+ User attributes</div>
				<div 
					class='btn btn-soft-primary btn-sm mr-2'
					onClick={() => addRule('activity')}
				>+ Activity attributes</div>
				<div 
					class='btn btn-soft-primary btn-sm'
					onClick={() => addRule('wallet')}
				>+ Wallet attributes</div>
			</div>
		</div>
	)
}

export default SegmentComponent;


const Rule = ({type}) => {

	const [attribute, setAttribute] = useState()
	const [operators, setOperators] = useState([])
	const [value, setValue] = useState()

	useEffect(() => {
		if(attribute){
			const type_ = segmentFilters[type].options.filter(item => item.value === attribute)[0].type
			setOperators(operatorOptions.filter(item => item.type === type_))
		}
	}, [attribute])

	return (
		<div class='row align-items-center justify-content-between'>
			<div class='col'>
				<SelectField
					type = "label"
					options = {segmentFilters[type].options}
					value = {attribute}
					setValue = {e => setAttribute(e)}
				/>
			</div>
			{attribute && <>
				<div class='col'>
					<SelectField 
						options = {operators}
						value = {attribute}
						setValue = {e => setAttribute(e)}
					/>
				</div>
				<div class='col'>
					<SelectField 
						options = {[]}
						value = {attribute}
						setValue = {e => setAttribute(e)}
					/>
				</div>
			</>}
			<div class='col-auto'>
				<button class='btn btn-sm btn-soft-danger btn-icon  mb-5'>
					<i class='tio-delete'></i>
				</button>
			</div>
		</div>
	)
}






//user attributes
//activity attributes
//wallet attributes


const segmentFilters = {
	'user': {
		options: [{
			value: '',
			label: 'Please select',
		}, {
			value: 'name',
			label: 'Name',
			type: 'text',
		}, {
			value: 'age',
			label: 'Age',
			type: 'number',
		}]
	},
	'activity': {
		options: [{
			value: '',
			label: 'Please select',
		}, {
			value: 'number_of_messages',
			label: 'Number of messages',
			type: 'number',
		}, {
			value: 'number_of_replies',
			label: 'Number of replies',
			type: 'number',
		}]
	},
	'wallet': {
		options: [{
			value: '',
			label: 'Please select',
		}, {
			value: 'number_of_messages',
			label: 'Hold a token',
			type: 'number',
		}, {
			value: 'number_of_replies',
			label: 'Addresses',
			type: 'number',
		}, {
			value: 'number_of_replies',
			label: 'Hold a POAP',
			type: 'number',
		}, {
			value: 'number_of_replies',
			label: 'Hold a NFT',
			type: 'number',
		}]
	}
}


const operatorOptions = [
	{
		value: 'contains',
		label: 'Contains',
		type: 'text'
	},
	{
		value: 'do_not_contain',
		label: 'Do not contain',
		type: 'text'
	},
	{
		value: 'equals',
		label: 'Equals',
		type: 'text'
	},
	{
		value: 'exists',
		label: 'Exists',
		type: 'text'
	},
	{
		value: 'does_not_exists',
		label: 'Does not exists',
		type: 'text'
	},
	{
		value: 'Greater than',
		label: 'Greater than',
		type: 'number'
	},
	{
		value: 'less_than',
		label: 'Less than',
		type: 'number'
	},
	{
		value: 'equal_to',
		label: 'Equal to',
		type: 'number'
	},


]