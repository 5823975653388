import React from 'react'

const Progress = ({height, legends, colors, yAxis, title, xAxis, isColorNotSoft}) => {

    const color1 = colors && colors[0] ? colors[0] : 'success'
    const color2 = colors && colors[1] ? colors[1] : 'danger'

    const value1 = (yAxis && yAxis.length > 1) ? yAxis[0]/(yAxis[0] + yAxis[1])*100 : 0
    const value2 = (yAxis && yAxis.length > 1) ? yAxis[1]/(yAxis[0] + yAxis[1])*100 : 0

    return (
        <div>
            {title && <div class="mt-3">
                <span class='small bold'>{title}</span>
            </div>}
            <div class="progress bg-white border  mb-2" style = {{height: height ? height : '2rem'}}>
                <div class={`progress-bar bg${isColorNotSoft && isColorNotSoft[0] ? '' : '-soft'}-${color1} text-${color1} bold`}  style={{minWidth: '1%', width: value1 + '%'}}>{(xAxis && xAxis.length > 1) && xAxis[0]}</div>
                <div class={`progress-bar bg${isColorNotSoft && isColorNotSoft[1] ? '' : '-soft'}-${color2} text-${color2} bold`}  style={{minWidth: '1%', width: value2 + '%'}}>{(xAxis && xAxis.length > 1) && xAxis[1]}</div>
            </div>
            {(legends && legends.length > 0) && <div class="d-flex justify-content-between small bold">
                <span>{legends && legends[0] && legends[0]}</span>
                <span>{legends && legends[1] && legends[1]}</span>
            </div>}
        </div>
    )
}
export default Progress