import React, {useState, useEffect, useContext} from 'react'
import { InputFieldRow } from 'components/Forms/TextInput'
import { SelectFieldRow } from 'components/Forms/Select'
import { DateFieldRow } from 'components/Forms/Datepicker'
import {typeOptions} from '../utilis/getTemplate'
import {Store} from 'store'
import {updateQuests} from 'store/actions/workplace'
import { getAllQuestsApi } from 'services/quests';
import moment from 'moment'

const QuestDetails = ({setStep, nextStep, quest, setQuest}) => {

    const {state, dispatch} = useContext(Store)
    const [allQuests, setAllQuests] = useState([])

     useEffect(() => {
        if(state.workplace.quests){
            setAllQuests(state.workplace.quests.filter(item => item.medium === quest.medium).map(item => ({
                label: item.title + ' ||  created on, ' + moment(item.createdAt).format('Do MMM YY'),
                value: item._id
            })))
        } else getAllQuests()
    }, [state.workplace.quests])

     
    const getAllQuests = async () => {
        try{
            
            const allQuests = await getAllQuestsApi();
            updateQuests(allQuests, dispatch)

        } catch(err){
            console.log(err)
        }
    }

    const [errors, setErrors] = useState({
        title: false,
        type: false,
        mission: false,
        guide: false,
        maxRecipients: false
    })

    const handleNext = () => {
        const newErrors = {...errors}
        if(!quest.title){
            newErrors.title = true;
        }
        if(!quest.type){
            newErrors.type = true;
        }
        if(!quest.mission){
            newErrors.mission = true;
        }
        if(!quest.guide){
            newErrors.guide = true;
        }
        if(!quest.maxRecipients || quest.maxRecipients < 1){
            newErrors.maxRecipients = true;
        }
        setErrors(newErrors)
        if(!newErrors.title && !newErrors.type && !newErrors.mission && !newErrors.guide && !newErrors.maxRecipients){
            setStep(nextStep)
        }
    }

    useEffect(() => {
        setErrors({
            title: false,
            mission: false,
            guide: false,
            type: false,
            maxRecipients: false
        })
    }, [quest])


    return (
        <div class="card card-lg active" >
            <div class="card-body">
                <InputFieldRow
                    label = "Title"
                    columnSize={4}
                    value = {quest.title}
                    error = {errors.title}
                    setError = {(error) => setErrors(prev => ({...prev, title: error}))}
                    errorText = "Required field"
                    placeholder = {placeholders.title}
                    disabled
                />
                <SelectFieldRow
                    label = "Type"
                    columnSize={4}
                    value = {quest.type}
                    error = {errors.type}
                    setError = {(error) => setErrors(prev => ({...prev, type: error}))}
                    errorText = "Required field"
                    setValue = {(e) => setQuest(prev => ({...prev, type: e}))}
                    placeholder = {placeholders.type}
                    options = {[{value: '', label: "Please select your quest type"}, ...typeOptions.filter(item => item.medium === quest.medium)]}
                />
                <InputFieldRow
                    type = "textarea" 
                    label = "Mission"
                    columnSize={4}
                    value = {quest.mission}
                    error = {errors.mission}
                    setError = {(error) => setErrors(prev => ({...prev, mission: error}))}
                    errorText = "Required field"
                    setValue = {(e) => setQuest(prev => ({...prev, mission: e}))}
                    placeholder = {placeholders.mission}
                    textAreaHeight="5rem"
                />
                <InputFieldRow
                    type = "textarea" 
                    label = "Guide"
                    columnSize={4}
                    value = {quest.guide}
                    error = {errors.guide}
                    setError = {(error) => setErrors(prev => ({...prev, guide: error}))}
                    errorText = "Required field"
                    setValue = {(e) => setQuest(prev => ({...prev, guide: e}))}
                    placeholder = {placeholders.guidelines}
                    textAreaHeight = "10rem"
                />
                {/* <DateFieldRow
                    label = "Quest End Date"
                    columnSize={4}
                    value = {quest.endDate}
                    setValue = {(e) => setQuest(prev => ({...prev, endDate: e}))}
                    placeholder = ""
                    description="Quest will only be claimable by members fullfilling the conditions."
                    minDate = {quest.startDate ? new Date(quest.startDate) : new Date()}
                /> */}
                {/* <InputFieldRow 
                    label = "Maximum number of Recipents"
                    columnSize={4}
                    value = {quest.maxRecipients}
                    setValue = {(e) => setQuest(prev => ({...prev, maxRecipients: e}))}
                    placeholder = ""
                    description="Quest will only be claimable by members fullfilling the conditions."
                    type= "number"
                    error = {errors.guide}
                    setError = {(error) => setErrors(prev => ({...prev, guide: error}))}
                    errorText = "Required field"
                />
                <SelectFieldRow
                    label = "Parent Quest"
                    description="Quest will only be claimable by members fullfilling the conditions."
                    columnSize={4}
                    value = {quest.parentQuest}
                    setValue = {(e) => setQuest(prev => ({...prev, parentQuest: e}))}
                    options = {[{value: '', label: "Please select your quest"}, ...allQuests]}
                /> */}
            </div>
            <div class="card-footer d-flex justify-content-end align-items-center">
                <button type="button" class="btn btn-primary" onClick={() => handleNext()}>
                    Next <i class="tio-chevron-right"></i>
                </button>
            </div>
        </div>
  )
}

export default QuestDetails


const placeholders =  {
    mission: 'Refer your friends & other Web 3.0 enthusiasts to join our Discord server',
    guidelines: `1. Create your invite link by clicking + icon next to channel
2. Share the invite link with your friends
3. Gently remind in case they forget
4. Separate attributes : number of members to invite`,
}

