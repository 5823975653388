import React, { useEffect, useContext} from "react";
import BeatSpinner  from "components/Forms/Spinners";
import { Store } from "store";
import { updateUser } from "store/actions/auth";
import { updateWorkplace } from "store/actions/workplace";
import {verifyEmail} from 'services/user/auth'

const ConfirmAccount = (props) => {

    const {state, dispatch} = useContext(Store)

    useEffect(() => {
        confirmAccount()
        // eslint-disable-next-line
    }, [])


    const confirmAccount = async () => {
        try {
            const hash= new URL(window.location.href).searchParams.get('hash')
            await verifyEmail(hash)
            updateUser(state.user.data, {isEmailVerified: true}, dispatch)
            updateWorkplace(state.user.workplaceId, dispatch)
            props.history.push('/app/')
        } catch (err) {
            props.history.push('/')
            console.log(err)
        }
    };

    return (
        <BeatSpinner />
    );
};

export default ConfirmAccount;
