import moment from "moment"


const data = [
    {
        "label": "Source",
        "value": "source",
        "enums": [],
        "icon": "company",
        "default": true,
        "isMulti": true
    }, 
    {
        "label": "Event date",
        "value": "time",
        "icon": "calendar",
        "type": "DATE",
        "default": true,
        "defaultValues": {
            "startDate": new Date(moment().startOf('week')),
            "endDate": new Date()
        }
    }
]

export const initialFilters = data.filter(item => item.default).map(item => ({
    value: item.value, 
    filter: item.defaultValues ? item.defaultValues : (item.type === 'DATE' ? dateFilterNull : item.isMulti ? [] : '')
}))

export default data