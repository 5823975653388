import React from 'react'

const Loading = () => {
    return (
        <div class='d-flex align-items-center justify-content-center' style = {{height: '200px'}}>
            <span class='spinner-border spinner-border-lg'></span>
        </div>
    )
}

export default Loading