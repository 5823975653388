export const FETCH_MEMBERS = 'FETCH_MEMBERS'
export const FETCH_TAGS = 'FETCH_TAGS'
export const FETCH_SOURCES = 'FETCH_SOURCES'
export const FETCH_CHANNELS = 'FETCH_CHANNELS'
export const FETCH_ROLES = 'FETCH_ROLES'


export const saveMemberTags = async (data, dispatch) =>{
    return dispatch({
       type: FETCH_TAGS,
       payload: {
           data
       }
   })
}



export const saveSources = async (data, dispatch) =>{
    return dispatch({
       type: FETCH_SOURCES,
       payload: {
           data
       }
   })
}



export const saveChannels = async (data, dispatch) =>{
    return dispatch({
       type: FETCH_CHANNELS,
       payload: {
           data
       }
   })
}

export const saveDiscordRoles = async (data, dispatch) =>{
    return dispatch({
       type: FETCH_ROLES,
       payload: {
           data
       }
   })
}



