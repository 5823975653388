import React, {useEffect, useState} from 'react'
import {Table} from 'components/Table'
import Avatar from 'components/Avatar'
import {getAvatar} from 'utilis/avatars'
import moment from 'moment'
import Tooltip1 from 'components/Others/Tooltip'

const MostActiveMembers = ({data}) => {

    const [activeMembers, setActiveMembers] = useState([])
    
    useEffect(() => {
        formatData()
    }, [data])

    const formatData = () => {
        setActiveMembers(data.map(item => ({
            name: {
                label: <Avatar 
                    name = {item.metadata.username} 
                    link = {`/app/member/${item._id}`}
                    avatar = {item.metadata.avatar.includes(null) ? '' : item.metadata.avatar}
                />,
                value: item.username,
            },
            sources: {
                label: <div >
                    {item.sources.map(item2 => <span class={`badge badge-soft-primary mr-2`}>
                        {item2.type.toLowerCase()}
                    </span>)}
                </div>,
                value: '',
            },
            activityCount: item.discordMemberProfiles && (item.discordMemberProfiles.reduce((a, b) => a + b.metrics.messages, 0) + ' messages'),
            tokenHolding: item.web3MemberProfiles && item.web3MemberProfiles.reduce((a, b) => a + b.tokens.length, 0),
        })))
    }
    return (
        <div class="card mb-3 mb-lg-5">
            <div class="card-header">
                <div class="row align-items-center flex-grow-1">
                    <div class="col-sm mb-2 mb-sm-0">
                        <h4 class="card-header-title">Most active members 
                            <Tooltip1 tooltip = "Leaderboard sorted by total no of messages from high to low"/>
                        </h4>
                        <p class='mb-0'>Find potential ambassadors within your community.</p>
                    </div>
                </div>
            </div>
            <Table 
                headers = {headers} 
                data = {activeMembers}
            />
        </div>
    )
}

export default MostActiveMembers



const headers = [{
    value: 'name',
    label: 'Name'
}, {
    value: 'sources',
    label: 'Sources'
}, {
    value: 'activityCount',
    label: 'Discord Activity Count'
}, {
    value: 'tokenHolding',
    label: 'Num Tokens'
},]
