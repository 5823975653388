import { CheckBox } from 'components/Forms/Checkbox'
import React, {useContext, useEffect, useState} from 'react'
import {Table} from 'components/Table'
import Select from 'components/Forms/Select2'
import { getAllTagsApi, updateTagsApi, deleteTagsApi } from 'services/workplace'
import moment from 'moment'
import { Button } from 'components/Forms/Buttons'
import toaster from 'react-hot-toast'
import { Store } from 'store'
import { saveMemberTags } from 'store/actions/community'

const Tags = () => {

    const {state, dispatch} = useContext(Store)
    const [selected, setSelected] = useState([])
    const [tags, setTags] = useState([])
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)

    useEffect(() => {
        if(state.community.tags){
            formatTags(state.community.tags)
        }
        else fetchTags()
    }, [state.community.tags])

    const fetchTags = async () => {
        try{

            const data = await getAllTagsApi()
            saveMemberTags(data, dispatch)

        } catch(err){
            console.log(err)
        }
    }

    const formatTags = data => {
        setTags(data.map(item => ({
            ...item,
            name: {
                value: item.tag,
                label: <span class={`badge badge-${item.color ? item.color : 'primary'}`}>{item.tag}</span>
            },
            createdAt: moment(item.createdAt).format('Do MMM'),
        })))
    }

    const updateTags = async (selected_, color) => {
        try{
            setUpdateLoading(true)
            const res = await updateTagsApi({
                tags: selected_,
                color: color
            })
            setSelected([])
            setUpdateLoading(false)
            toaster.success("Tags successfully updated")
            const newGlobalTags = [...state.community.tags.map(item => {
                if(selected_.includes(item._id)){
                    return {
                        ...item,
                        color: color,
                    }
                } else return item
            })] 
            saveMemberTags(newGlobalTags, dispatch)
            
        } catch(err){
            setUpdateLoading(false)
            toaster.error("Error occured! Please refresh and try again")
            console.log(err)
        }
    }

    const deleteTags = async () => {
        try{
            
            setDeleteLoading(true)
            const res = await deleteTagsApi({
                tags: selected
            })
            setDeleteLoading(false)
            const newTags = [...tags.filter(item => {
                return !selected.includes(item._id)
            })]
            toaster.success("Tags successfully deleted")
            const newGlobalTags = [...state.community.tags.filter(item => {
                return !selected.includes(item._id)
            })]
            saveMemberTags(newGlobalTags, dispatch)
            setSelected([])
            setTags(newTags)

            
        } catch(err){
            setDeleteLoading(false)
            toaster.error("Error occured! Please refresh and try again")
            console.log(err)
        }
    }


    return (
        <div id="account-tags" class="card mb-3 mb-lg-5">
            <div class="card-header">
                <h4 class="card-title">User tags</h4>
                {selected.length>0 && <div class='d-flex'>
                    <ChangeColorButton 
                        setTags = {setTags}
                        selected = {selected}
                        setSelected = {setSelected}
                        applyUpdate = {(sel, col) => updateTags(sel, col)}
                        loading = {updateLoading}
                    />
                    <Button 
                        size = "sm"
                        text = "Delete Tags"
                        color = "soft-danger"
                        submitResponse={() => deleteTags()}
                        loading = {deleteLoading}
                    />
                </div>}
            </div>
            <Table 
                headers = {headers} 
                data = {tags}
                size="lg"
                rowSelection = {true}
                selected = {selected}
                setSelected = {setSelected}
                applySelection = {() => {}}
            />
        </div>
    )
}

export default Tags




const ChangeColorButton = ({selected, setTags, setSelected, applyUpdate, loading}) => {

    const [color, setColor] = useState('');
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(color.length > 0){
            setShow(false);
            applyUpdate(selected, color)
            setTags(prev => prev.map(item => {
                if(selected.includes(item._id)){
                    return {
                        ...item,
                        name: {
                            value: item.name.value,
                            label: <span class={`badge badge-${color}`}>{item.name.value}</span>
                        },
                    }
                } else {
                    return item
                }
            }))
            setSelected([])
        }
    }, [color])

    return(
        <Select 
            value = {color}
            setValue = {(e) => setColor(e)}
            options = {colorOptions}
            show = {show}
            setShow = {setShow}
            position = ""
        >
            <Button 
                loading = {loading}
                size = "sm"
                text = "Change Color"
                color = "soft-primary"
                className="mr-2"
                submitResponse={() => {}}
            />
        </Select>
    )
}


const headers = [{
    value: 'name',
    label: 'Name'
}, {
    value: 'count',
    label: 'Times used'
}, {
    value: 'createdAt',
    label: 'Created At'
}]


const colorOptions = [
    {value: 'danger', label: 'Red', color: 'danger'},
    {value: 'primary', label: 'Blue', color: 'primary'},
    {value: 'warning', label: 'Yellow', color: 'warning'},
    {value: 'info', label: 'Light Blue', color: 'info'},
    {value: 'success', label: 'Green', color: 'success'},
]

