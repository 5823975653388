import { Button } from 'components/Forms/Buttons'
import moment from 'moment';
import React, {useState} from 'react'

const Confirmation = ({setStep, quest, editQuest, prevStep}) => {

    const [loading, setLoading] = useState();


    const handleLaunchQuest = async () => {
        try{
            setLoading('launch')
            await editQuest()
            setLoading()
        } catch(err){
            setLoading()
        }
    }

    return (
    <div id="addUserStepConfirmation" class="card card-lg active" >
        <div class="card-body">
            <dl class="row">
                {data.map(item => <>
                    <dt class="col-sm-3 text-sm-right mb-3">{item.label}</dt>
                    <dd class="col-sm-9 mb-3 text-wrap">{item.formatter(quest)}</dd>
                </>)}
            </dl>
        </div>
        <div class="card-footer d-sm-flex align-items-sm-center">
            <button type="button" class="btn btn-ghost-secondary mb-2 mb-sm-0" onClick={() => setStep(prevStep)}>
                <i class="tio-chevron-left"></i> Previous step
            </button>
            <div class="ml-auto">
                {/* <Button 
                    submitResponse = {() => handleSaveAsDraft()}
                    text = "Save as draft"
                    color = "white"
                    loading = {loading === 'draft'}
                    className = "mr-2"
                /> */}
                <Button 
                    submitResponse = {() => handleLaunchQuest()}
                    text = "Save campaign"
                    color = "primary"
                    loading = {loading === 'launch'}
                />
            </div>
        </div>
    </div>
  )
}

export default Confirmation



const data = [
    {
        value: 'title',
        label: 'Quest Name',
        type: 'text',
        formatter: (data) => data.title
    },
    {
        value: 'mission',
        label: 'Quest Mission',
        type: 'text',
        formatter: (data) => data.mission
    },
    {
        value: 'guide',
        label: 'Quest Guide',
        type: 'text',
        formatter: (data) => data.guide
    },
    {
        value: 'reward.xpValue',
        label: 'XPs to give',
        type: 'number',
        formatter: (data) => data.reward.xpValue + ' XPs'
    },
    {
        value: 'walletCommand',
        label: 'Discord Command Name',
        formatter: (data) => data?.startQuest?.metadata?.commandName || 'No name'
    },
    // {
    //     value: 'startDate',
    //     label: 'Quest start date',
    //     type: 'date',
    //     formatter: data => data.startDate ? moment(data.startDate).format('Do MMM YYYY'): ''
    // },
    // {
    //     value: 'endDate',
    //     label: 'Quest end date',
    //     type: 'date',
    //     formatter: data => data.endDate ? moment(data.endDate).format('Do MMM YYYY') : ''
    // },
    // {
    //     value: 'maxRecipients',
    //     label: 'Maximum recipients',
    //     formatter: (data) => data.maxRecipients
    // },
]


const quest = {
    name: 'Abhishek',
    xps: '10',
    tokens: '$INTRACT',
    amount: '100',
    startDate: '12th Aug 2022',
    endDate: '20th Aug 2022',
    maxRecipients: '100',
    awardMechanism: 'First come, First serve',
}


const getNestedProperty = (obj, prop) => {
    return prop.split('.').reduce((o, i) => o[i], obj)
}