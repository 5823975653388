import React, {useState, useRef} from 'react'

const AvatarInputRow = ({label, fileRef, icon, img}) => {

    
    const [avatar, setAvatar] = useState(img)

    const updateAvatar = async () => {
        setAvatar(URL.createObjectURL(fileRef.current.files[0]));
    }

   

    return (
        <div class="row form-group">
            <label class="col-sm-3 col-form-label input-label">
                {icon && <i class = {icon}></i>}
                {label}
            </label>
            <div class="col-sm-9">
                <div class="d-flex align-items-center">
                    <label class="avatar avatar-xl avatar-circle avatar-uploader mr-5" for="avatarUploader" onClick={() => fileRef.current.click()}>
                        <img id="avatarImg" class="avatar-img" src={avatar} alt="" />
                        <input ref = {fileRef} type="file" class="js-file-attach avatar-uploader-input" onChange={(e) => {
                            updateAvatar()
                        }}/>
                        <span class="avatar-uploader-trigger">
                            <i class="tio-edit avatar-uploader-icon shadow-soft"></i>
                        </span>
                    </label>
                    <button 
                        type="button" 
                        class="js-file-attach-reset-img btn btn-white"
                        onClick={() => {
                            setAvatar('/assets/img/bee.png')
                        }}
                    >Delete</button>
                </div>
            </div>
        </div>
    )
}

export default AvatarInputRow
