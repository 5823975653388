import { SimpleModal } from 'components/Models/Modal'
import React from 'react'
import toast from 'react-hot-toast'
import { getDiscordAuthLinkApi } from 'services/workplace'
import config from 'utilis/config'

const AddIntegration = ({show, setShow, setShowUpload}) => {



    const addDiscord = async () => {
        try{
            let href = await getDiscordAuthLinkApi();
            window.location = href
            // console.log(href)
        } catch(err){
            // toast.error(err.response.data.error)
            console.log(err)
        }
    }



    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
            title = "Add new data source"
            size = "lg"
        >   
        <div class='alert alert-soft-primary'>
            Connect your sources to bring community, product, and customer data together so you can unlock insights and take action
        </div>
        <div class=''>
            <div class='row'>
                <div class='col-6 mb-5'>
                    <div class='card border-dark card-hover-shadow pointer h-100 link-unstyled' onClick = {() =>addDiscord()}>
                        <div class='card-body card-body-centered text-center'>
                            <img src = "/assets/svg/brands/discord.svg" class='img-fluid' style = {{height: '40px'}}/>
                            <p className='mt-2 mb-0 bold'>Discord Server</p>
                            <p className='mt-1 mb-0'>Follow activities like enagagement, invitations or contests of your discord members</p>
                        </div>
                    </div> 
                </div>
                <div class='col-6 mb-5'>
                    <div class='card border-dark card-hover-shadow pointer h-100 link-unstyled' onClick = {() =>{
                        setShowUpload(true)
                        setShow(false)
                    }}>
                        <div class='card-body card-body-centered text-center'>
                            <img src = "/assets/svg/brands/metamask.png" class='img-fluid' style = {{height: '40px'}}/>
                            <p className='mt-2 bold  mb-0'>Wallet Addresses</p>
                            <p className='mt-1 mb-0'>Upload your users' wallet addresses to track their transactions and holdings</p>
                        </div>
                    </div> 
                </div>
                <div class='col-6 '>
                    <div class='card border-dark  h-100'>
                        <div class='card-body card-body-centered text-center'>
                            <img src = "/assets/svg/brands/eth.svg" class='img-fluid' style = {{height: '40px'}}/>
                            <p className='mt-2 bold  mb-0'>Add ERC20 Token</p>
                            <p className="mt-1 mb-0">Follow activities like transactions, holding time from your token smart contract</p>
                            <p className='badge badge-soft-primary mt-1 mb-0'>Coming Soon</p>
                        </div>
                    </div> 
                </div>
                <div class='col-6'>
                    <div class='card border-dark  h-100'>
                        <div class='card-body card-body-centered text-center'>
                            <img src = "/assets/svg/brands/twitter.svg" class='img-fluid' style = {{height: '50px'}}/>
                            <p className='mt-2 bold  mb-0'>Twitter</p>
                            <p className='mt-1 mb-0'>Follow activities like mentions, hashtags, and retweets from specific accounts</p>
                            <p className='badge badge-soft-primary mt-1 mb-0'>Coming Soon</p>
                        </div>
                    </div> 
                </div>
            </div>
        </div>

        </SimpleModal>
    )
}

export default AddIntegration