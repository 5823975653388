import React, {useState, useEffect} from 'react'
import useOuterClick from 'hooks/useOuterClick'


const SelectDropdown = ({children, value, setValue, disable, options, show, setShow, position, size, isMulti}) => {


    const [showMenu, setShowMenu] = useState(false);
    const innerRef = useOuterClick(ev => setShowMenu(false));

    useEffect(() => {
        if(show) setShowMenu(show);
    }, [show])
    
    useEffect(() => {
        if(!showMenu) {
            if(setShow) setShow(showMenu);
        }
    }, [showMenu])

    return (
        <div class="dropdown" ref = {innerRef}>
            <span className = "" onClick = {(e) => {
                setShowMenu(prev => !prev)
            }}>
                {children}
            </span>
            <div style = {{maxHeight: '240px', overflow: 'scroll', minWidth: '190px'}} class={`dropdown-menu shadow-lg mt-1 dropdown-menu-sm dropdown-menu-${position ? position : 'left'} ${showMenu ? 'show' : ''}`} >
                {options.map((item, index) => (<>
                    <span 
                        class={`dropdown-item mx-3 ${item.disabled ? 'disabled' : ''} rounded mb-1 ${isMulti ? (value.includes(item.value) ? ('bg-soft-' + (item.color ? item.color:'primary')):'') : (value === item.value ? ('bg-soft-' + (item.color ? item.color:'primary')):'')} ${item.color ? 'text-' + item.color : ''}`} 
                        style = {{width:'auto'}}
                        onClick={() => {
                            if(disable) return;
                            if(isMulti && !value.includes(item.value)) setValue([...value, item.value])
                            else if(isMulti && value.includes(item.value)) {
                                const newValue = [...value];
                                newValue.splice(newValue.indexOf(item.value), 1)
                                setValue(newValue)
                            }
                            else setValue(item.value);
                            if(!isMulti) setShowMenu(false);
                        }}
                    >
                        {isMulti && (
                            value.includes(item.value) ? <i class='text-success tio-checkmark-circle mr-2'>aa</i> : <i class='text-secondary tio-circle-outlined mr-2'></i>
                        )}
                        {item.icon && typeof item.icon === 'string' && <i className={`tio-${item.icon} mr-1`}></i>}
                        {item.icon && typeof item.icon !== 'string' && item.icon}
                        {item.label}
                    </span>
                    {/* {index < options.length-1 && <div class="dropdown-divider"></div>} */}
                </>))}
            </div>
        </div>
    )
}

export default SelectDropdown





export const BigDropdown = ({children, wrapperClassName, popoverClassName, popover, position, style}) => {

    const [show, setShow] = useState(false);
    const innerRef = useOuterClick(ev => setShow(false));


    return (
        <div class="dropdown" ref = {innerRef}>
            <span className = {wrapperClassName + ' btn'} onClick = {(e) => setShow(prev => !prev)}>
                {children}
            </span>
            <div 
                className={` dropdown-unfold dropdown-menu ${popoverClassName} dropdown-menu-${position ? position : 'right'} navbar-dropdown-menu  ${show ? 'slideInUp' : 'hs-unfold-hidden'}`} 
                style = {{...style}}
            >
                {popover}
            </div>
        </div>
    )
}
