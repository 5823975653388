import { InputField, InputFieldRow } from 'components/Forms/TextInput'
import NavSegment, { FullNavSegment } from 'components/Nav/NavSegment'
import { PageHeader } from 'components/Text'
import SegmentComponent from 'components/Segments'
import React, {useState} from 'react'
import LaunchAirdrop from './LaunchAirdrop'
import SelectCoin from './SelectCoin'

const UniqueLink = () => {

    const [show, setShow] = useState(false)
    const [details, setDetails] = useState({
        token: '',
        numToken: '',
        numRecipients: '',
        title: '',
    })

    const launchAirdrop = async () => {
        try{

            const transactionParameters = {
                nonce: '0x00', 
                gasPrice: '0x09184e72a000', 
                gas: '0x2710', 
                to: '0x0000000000000000000000000000000000000000', 
                from: '', 
                data: '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', 
            };

            const txHash = await ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
              


        } catch(err){
            console.log(err)
        }
    }


    return (
        <div>
            <PageHeader 
                title =  "Create reward direct to wallet addresses"
                description = "Let's get started with some details about your reward campaign"
            />
            <Details 
                details = {details}
                setDetails = {setDetails}
            />
            <Conditions />
            <div class='mt-5 mb-5 d-flex justify-content-end'>
                <div class='btn btn-lg btn-soft-primary mr-3'>
                    Save as draft
                </div>
                <div class='btn btn-lg btn-primary' onClick={() => launchAirdrop()}>
                    Launch reward
                </div>
            </div>
            <LaunchAirdrop
                show = {show}
                setShow = {setShow}
            />
        </div>
  )
}

export default UniqueLink




const Details = ({details, setDetails}) => {

    const [show, setShow] = useState(false)

    return (
    <div class="row mb-5">
        <div class="col-lg-4 mb-3 mb-lg-0">
            <h4>Enter airdrop details</h4>
            <p>Let's get started with some details about your airdrop campaign</p>
        </div>
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body">
                    <div className='form-group mb-5 mt-3'>
                        <label class='input-label bold'>Select your Token</label>
                        <p className='mt-n2 fs-sm'>Select a token you own from your connected wallet</p>
                        <input class='form-control' onClick={() => setShow(true)}/>
                    </div>
                    <InputField 
                        value = {details.numTokens}
                        label = "Number of tokens"
                        description="Amount Of Tokens You'd Like To Send Per Recipient"
                        setValue={(e) => setDetails({...details, numTokens: e})}
                        margin ="mb-5"
                        labelClass="bold"
                        placeholder="eg, 100"
                    />
                    <InputField 
                        value = {details.title}
                        label = "Reward Title"
                        description="Brief description of your reward campaign"
                        setValue={(e) => setDetails({...details, title: e})}
                        margin ="mb-5"
                        labelClass="bold"
                        placeholder="eg, Weekly rewards"
                    />
                </div>
            </div>
        </div>
        <SelectCoin 
            show = {show}
            setShow = {setShow}
        />
    </div>
    )
}


const Conditions = () => {

    const [selected, setSelected] = useState('all')

    return (
    <div class="row">
        <div class="col-lg-4 mb-3 mb-lg-0">
            <h4>Set conditions for claiming reward</h4>
            <p>Gate your reward to specific token holders, NFT holders or whitelisted set of members</p>
        </div>
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body py-5">
                    <div class="alert alert-soft-primary mb-4" role="alert">
                        You can select a custom audience to receiev this aidrop. You can either choose by their wallet, member or activity attributes. 
                    </div>
                    <div class='d-flex w-100 '>
                        <FullNavSegment 
                            navMap = {filtersNav}
                            selected = {selected}
                            setSelected = {setSelected}
                        />
                    </div>
                    {selected === 'custom' && <div class='mt-4'><SegmentComponent /></div>}
                    {selected === 'static' && <div class='mt-4'><Addresses /></div>}
                </div>
            </div>
        </div>
    </div>
    )
}


const Addresses = () => {

    const [value, setValue] = useState("")

    return (
        <div>
            <InputField 
                placeholder = ""
                label = "Write down addresses"
                type = "textarea"
                value = {value}
                setValue = {setValue}
                labelClass = ""
                textAreaHeigh = "5rem"
            />
        </div>
    )
}


const filtersNav = [{
    value: 'all',
    label: 'All members',
}, {
    value: 'custom',
    label: 'Build custom segment',
}, {
    value: 'static',
    label: 'To addresses',
}]





/**
 * Campaign type
 *  (One time, Recurring)
 * Campaign start date and end date
 *  
 */



