import React, {useContext, useEffect, useState} from 'react'
import {Table} from 'components/Table'
import Avatar from 'components/Avatar'
import HorizontalNav from 'components/Nav/HorizontalNav'
import NavSegment from 'components/Nav/NavSegment'
import {getNumberWithCommas, abbreviateNumber} from 'utilis/sanitisers'
import {getAvatar} from 'utilis/avatars'
import moment from 'moment'
import { getCryptoPriceApi } from 'services/workplace'
import { updateCryptoPrices } from 'store/actions/workplace'
import { Store } from 'store'
import Loading from 'components/Others/Loading'

const DiscoverMembers = ({data}) => {

    const {state, dispatch} = useContext(Store)
    const [selectedTab, setSelectedTab] = useState('members')
    const [activeMembers, setActiveMembers] = useState()

    useEffect(() => {
        formatData()
    }, [data])

    useEffect(() => {
        if(state.workplace.cryptoPrices){
        } else getCryptoPrices();
    }, [state.workplace.cryptoPrices])

    
    const getCryptoPrices = async () => {
        try{

            const prices = await getCryptoPriceApi()
            updateCryptoPrices(prices, dispatch)
            return prices.rates

        } catch(err){
            console.log(err)
        }
    }

    const formatData = async () => {
        let prices = state.workplace.cryptoPrices.rates
        if(!prices){
            prices = await getCryptoPrices()
        }
        setActiveMembers(data && data.map(item => ({
            name: {
                label: <Avatar 
                    name = {item.metadata.username} 
                    link = {`/app/member/${item._id}`}
                    avatar = {item.metadata.avatar.includes(null) ? '' : item.metadata.avatar}
                />,
                value: item.username,
            },
            sources: {
                label: <div >
                    {item.sources.map(item2 => <span class={`badge badge-soft-primary mr-2`}>
                        {item2.type.toLowerCase()}
                    </span>)}
                </div>,
                value: '',
            },
            activityCount: item.discordMemberProfiles && (item.discordMemberProfiles.reduce((a, b) => a + b.metrics.messages, 0) + ' messages'),
            balance: getTotalBalance(item.web3MemberProfiles, prices),
        })))
    }


    return (
    <div class="card mb-3 mb-lg-5">
        <div class="card-header">
            <div class="row align-items-center flex-grow-1">
                <div class="col-sm mb-2 mb-sm-0">
                    <h4 class="card-header-title">Most Active Members </h4>
                    <p class='mb-0'>Identify members who can become your community’s humming bees.</p>
                </div>
                {/* <div class='col-auto'>
                    <NavSegment
                        navMap={[
                            {value: 'members', label: 'Most active'},
                            // {value: 'hotspots', label: 'Recently inactive'},
                            // {value: 'details', label: 'New & Promising'},
                        ]}
                        selected = {selectedTab}
                        setSelected = {setSelectedTab}
                    />
                </div> */}
            </div>
        </div>
        {activeMembers && <Table 
            headers = {headers} 
            data = {activeMembers}
            isSort = {false}
        />}
        {!activeMembers && <Loading />}
    </div>
  )
}

export default DiscoverMembers




const headers = [{
    value: 'name',
    label: 'Name'
}, {
    value: 'sources',
    label: 'Sources'
}, {
    value: 'activityCount',
    label: 'Discord Activity Count'
}, {
    value: 'balance',
    label: 'Wallet Balance (USD)',
    tooltip: 'Token to USD conversion updates every 1 hour'
},]


const getTotalBalance = (web3Profiles, rates) => {
    let totalBalance = 0
    web3Profiles.forEach(profile => {
        if(profile.chain === 'polygon'){
            totalBalance = totalBalance + (profile.nativeBalance * rates.matic * 10E-19)
        }
        if(profile.chain === 'eth'){
            totalBalance = totalBalance + (profile.nativeBalance * rates.eth * 10E-19)
        }
        if(profile.chain === 'solana'){
            totalBalance = totalBalance + (profile.nativeBalance * rates.solana * 10E-10)
        }
        if(profile.chain === 'avalanche'){
            totalBalance = totalBalance + (profile.nativeBalance * rates.avax * 10E-19)
        }
        if(profile.chain === 'ronin'){
            totalBalance = totalBalance + (profile.nativeBalance * rates.ronin * 10E-19)
        }
    })
    return getNumberWithCommas(parseFloat(totalBalance).toFixed(2)) + ' USD'
}