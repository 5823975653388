import { SimpleModal } from 'components/Models/Modal'
import React, {useState, useEffect, useContext} from 'react'
import { useMoralis } from 'react-moralis'
import { saveMetaMaskApi } from 'services/user'
import {Store} from 'store'
import { ethers } from "ethers";
import toast from 'react-hot-toast'

const ConnectWallet = ({show, setShow, account, setAccount}) => {
    
    const {state} = useContext(Store)
    const {authenticate, user} = useMoralis();
    const [loading, setLoading] = useState(false);
    


    
    const connectWallet = async (type) => {
        if(type === 'eth'){
            if(!window.ethereum){
                toast.error("Please install metamask wallet");
                return;
            }
        }
        if(type === 'sol'){
            if(!window.phantom){
                toast.error("Please install phantom wallet");
                return;
            }
        }
        try{
            setLoading(type==='sol' ? 'phantom' : 'metamask');
            const user_ = await authenticate({
                signingMessage: "Hi there! Sign this message to prove you have access to this wallet and we’ll log you in. This won’t cost you any Ether." ,
                type: type === 'sol' ? 'sol' : 'metamask',
            })
            setLoading(false);
            setShow(false)
            if(user_){
                const address = user_.get(type === 'sol' ? 'solAddress' : 'ethAddress')
                accountChanged(address)
            }

        } catch(err){
            console.log(err)
        }
    };


    const accountChanged = async (newAccount) => {
        setAccount({address: newAccount, balance:''})
        try {
            // const balance = await window.ethereum.request({
            //     method: "eth_getBalance",
            //     params: [newAccount.toString(), "latest"],
            // });
            // console.log(balance)
            // setAccount(prev => ({...prev, balance: ethers.utils.formatEther(balance)}));
            // saveMetaMaskApi(newAccount, balance)
        } catch (err) {
            console.error(err);
        }
    };
    

    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
        >
            <div class='py-6'>
            <div class='px-6 text-center mb-6'>
                <div class='h2 mb-1'>Connect your wallet</div>
                <p>Get access to our airdrops and other exciting rewards</p>
            </div>

            <div class='row justify-content-center'>
                <div class='col-10 mb-4'>
                    <div class='card bg-light card-hover-shadow pointer' onClick={() => connectWallet('eth')}>
                        <div class='card-body d-flex align-items-center justify-content-center'>
                            <img src = "/assets/svg/brands/metamask.png" class='img-fluid' style = {{height:'50px'}}/>
                            <div class='bold lead ml-3'>
                                {loading === 'metamask' ? <span class='spinner-border spinner-border-sm mr-2'></span> : ''}
                                Connect Metamask
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-10 mb-4'>
                    <div class='card bg-light card-hover-shadow pointer' onClick={() => connectWallet('sol')}>
                        <div class='card-body d-flex align-items-center justify-content-center'>
                            <img src = "/assets/svg/brands/phantom.png" class='img-fluid' style = {{height:'50px'}}/>
                            <div class='bold lead ml-3'>
                                {loading === 'phantom' ? <span class='spinner-border spinner-border-sm mr-2'></span> : ''}
                                Connect Phantom
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            
        </SimpleModal>
    )
}

export default ConnectWallet