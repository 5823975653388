import React , {useState} from 'react'
import { SimpleModal } from 'components/Models/Modal';
import { saveReportApi } from 'services/reports'
import {InputField} from 'components/Forms/TextInput'
import { Button } from 'components/Forms/Buttons';
import toaster from 'react-hot-toast';

const SaveReport = ({show, setShow, filters}) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)

    const saveReport = async () => {
        try{
            setLoading(true)
            const appliedFilters = filters.filter(item => {
                if(item.filter.length > 0) return true;
                else if(new Date(item.filter.startDate).getTime() > 1) return true;
                else return false;
            })
            const res = await saveReportApi(name, description, appliedFilters);
            toaster.success("Report successfully saved! Go to saved report section to access")
            setShow(false)
            setName('')
            setDescription('')
            setLoading(false)
            
        } catch(err){
            toaster.error("Some error occured! Please refresh and try again!")
            setLoading(false)
            console.log(err)
        }
    }


    return (
        <SimpleModal 
            show = {show} 
            setShow = {setShow}
            title = {<>Save your report</>}
            size = "sm"
        >
            <div className='mt-n4 mb-4'>
                
            </div>
            <InputField
                placeholder = "Report name"
                value = {name}
                setValue = {setName}
            />
            <InputField
                placeholder = "Description"
                value = {description}
                setValue = {setDescription}
            />
            <div class='row justify-content-end   '>
                <div class='col-auto'>
                    <Button 
                        size = "sm"
                        color = "light"
                        className="mr-2"
                        submitResponse = {() => setShow(false)}
                        text = "Cancel"
                    />
                    <Button 
                        size = "sm"
                        loading = {loading}
                        submitResponse = {() => saveReport()}
                        text = "Save"
                    />
                </div>
            </div>
            

        </SimpleModal>
    )
}

export default SaveReport