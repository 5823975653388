import useQueryString from 'hooks/useQueryString';
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useLocation, useHistory } from "react-router-dom";
import { getAllSourcesApi } from 'services/sources';
import {addIntegrationApi} from 'services/workplace/';
import { Store } from 'store';
import { saveSources } from 'store/actions/community';
import Dashboard from '../../Layout';


const Discord = (props) => {
    
    const location = useLocation();
    const history = useHistory()
    const {state, dispatch} = useContext(Store)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getQueryParams();
    }, [])
    

    const getQueryParams = async () => {
        const data = {};
        const queryParams = new URLSearchParams(location.search)
        queryParams.forEach((value, key) => {
            data[key] = value;
        });
        queryParams.delete('code')
        queryParams.delete('guild_id')
        queryParams.delete('permissions')
        history.replace({
            search: queryParams.toString(),
        })
        if(data.code && data.guild_id && data.permissions){
            try{
                setLoading(true)
                await addIntegrationApi(data)
                setLoading(false)
                toast.success("Integration successfully added");

                await getAllSources()

                setTimeout(() => {
                    history.push('/app/data-sources')
                }, 400)
            } catch(err){
                console.log(err)
                if(err.response && err.response.data && err.response.data.error === "ALREADY_INTEGRATED"){
                    toast.error("This integration has already been added by you or some other owner")
                }
                setLoading(false);
                setTimeout(() => {
                    history.push('/app/data-sources')
                }, 400)
            }
        }
    }

    const getAllSources = async () => {
        try{
            const allSources_ = await getAllSourcesApi();
            saveSources(allSources_, dispatch)
        } catch(err){
            console.log(err)
        }
    }


    return (
            <div class = "d-flex flex-column align-items-center justify-content-center" style={{minHeight: '600px'}}>
                <h2 class='h1 mb-2'>Adding your discord channel<span class='spinner-border spinner-border ml-4'></span> </h2>
                
                <img class="img-fluid" src="/assets/img/discord.png" alt="" style = {{minHeight: '200px', objectFit: 'cover'}}/>
            </div>
    )
}

export default Discord