import React, {useEffect, useState} from 'react'
import moment from 'moment'

const Details = ({discordProfile}) => {

    const [basicInfo, setBasicInfo] = useState();
    const [activityData, setActivityData] = useState();

    useEffect(() => {
        if(discordProfile){
            setBasicInfo([
                {label: 'Joining Anniversary', value:  moment(discordProfile.joinedAt).format('Do MMM')},
                {label: 'Time since joined', value: moment(discordProfile.joinedAt).fromNow()},
                {label: 'Discord Id', value: discordProfile.memberId.split(':')[0]},
                {label: 'Intract Id', value: discordProfile._id},
                {label: 'Created On', value: moment(discordProfile.createdA).format('Do MMM')},
                {label: 'Updated On', value: moment(discordProfile.updatedAt).format('Do MMM')},
            ])
            setActivityData([
                {label: 'Total Messages', value: discordProfile.metrics.messages },
                {label: 'Total Replies', value: discordProfile.metrics.replies},
                {label: 'Total replied to', value: discordProfile.metrics.repliedTo},
            ])
        }
    }, [discordProfile])

  return (
    <div className='card'>
        <div class='card-body'>
            <div class='row'>
                <div class='col-7'>
                    {basicInfo && <BasicInfo basicInfo={basicInfo}/>}
                </div>
                <div className='col-5 column-divider-lg'>
                    {activityData && <ActivityData activityData = {activityData}/>}
                </div>
            </div>
        </div>
    </div>
  )
}
 
export default Details



const BasicInfo = ({basicInfo}) => {
    return (
        <div>
            <h5 class='mb-3'>Member's Info</h5>
                {basicInfo.map(item => (
                    <div class='row mb-2'>
                        <div class='col-5 text-muted'>
                            {item.label}
                        </div>
                        <div class='col-7 text-dark'>
                            {item.value}
                        </div>
                    </div>
                ))}
        </div>
    )
}





const ActivityData = ({activityData}) => {
    return (
        <div>
            <h5 class='mb-3'>Activity Data</h5>
            {activityData.map(item => (
                <div class='row mb-2'>
                    <div class='col-6 text-muted'>
                        {item.label}
                    </div>
                    <div class='col-6 text-dark'>
                        {item.value}
                    </div>
                </div>
            ))}
        </div>
    )
}


const basicInfo = [{
    label: "Joining anniversary",
    value: "April 11",
},{
    label: "Year joined",
    value: "2022",
},{
    label: "Time since joined",
    value: "less than 1 month",
},{
    label: "Time span of activities",
    value: "less than 1 month",
},{
    label: "Orbit ID",
    value: "5PS8L5V6",
},{
    label: "Source",
    value: "discord",
},{
    label: "Updated at",
    value: "Apr 27, 2022",
},{
    label: "Created at",
    value: "Apr 27, 2022",
}]

const activityData = [{
    label: 'Total activities',
    value: 8,
}, {
    label: 'Days since last activity',
    value: 7
}, {
    label: 'Last active',
    value: 'Apr 24, 2022'
}, {
    label: 'First active',
    value: 'Apr 24, 2022'
}]