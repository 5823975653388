import { Button } from 'components/Forms/Buttons'
import NavSegment from 'components/Nav/NavSegment'
import Address from 'components/Others/Address'
import { Table } from 'components/Table'
import React, {useEffect, useState} from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import {remindToConnectWalletApi, refreshWalletApi} from 'services/members'
import {getCoinDecimals, getCoinName} from 'utilis/coins'


const WalletActivity = ({web3Profiles}) => {

    console.log(web3Profiles)
    const history = useHistory();
    const [selected, setSelected] = useState('TOKENS');
    const [wallets, setWallets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    useEffect(() => {
        formatData()
        refreshData();
    }, [web3Profiles])

    const refreshData = async () => {
        try{
            setLoading1(true)
            // await refreshWalletApi(web3Profiles.memberId)
            setLoading1(false)
        } catch(err){
            setLoading1(false)
            console.log(err)
        }
    }


    const formatData = async () => {
        let wallets__ = []
        for(let i = 0; i < web3Profiles.length; i++){
            let web3Profile = web3Profiles[i];
            const wallet = web3Profile
            const chain = web3Profile.chain;
            const tokens = web3Profile.tokens || [];

            if(chain === 'solana'){
                const formattedTokens = tokens.map(item => ({
                    ...item,
                    _balance: {
                        label: getShortnedNumber(item.balance/(10**item.decimals)),
                        value: item.balance
                    },
                    _address:{
                        label: <Address address = {item.address}/>,
                        value: item.address
                    },
                    _mint:{
                        label: <Address address = {item.mint}/>,
                        value: item.mint
                    } 
                }))
                const nfts_ = wallet.nfts || [];
                const formattedNfts = nfts_.map(item => ({
                    ...item,
                    _address:{
                        label: <Address address = {item.address}/>,
                        value: item.address
                    },
                    _mint: {
                        label:  <Address address = {item.mint.toString()}/>,
                        value: item.mint
                    }
                }))
                wallet.tokens = formattedTokens;
                wallet.nfts = formattedNfts;
            } else {
                const formattedTokens = tokens.map(item => ({
                    ...item,
                    _balance: {
                        label: getShortnedNumber(item.balance/(10**item.decimals)),
                        value: item.balance
                    },
                    _address:{
                        label: <Address address = {item.address}/>,
                        value: item.address
                    } 
                }))
                const nfts_ = wallet.nfts || [];
                const formattedNfts = nfts_.map(item => ({
                    ...item,
                    _address:{
                        label: <Address address = {item.address}/>,
                        value: item.address
                    },
                    _tokenUri: {
                        label: <a target="_blank" href = {item.tokenUri}><i class='tio-link'></i></a>,
                        value: 'tokenUri'
                    },
                    _tokenId: {
                        label:  <Address size = "sm" address = {item.tokenId.toString()}/>,
                        value: item.tokenId
                    }
                }))
                wallet.tokens = formattedTokens;
                wallet.nfts = formattedNfts;
            }
            wallet.nativeBalance = wallet.nativeBalance
            wallets__.push(wallet)
        }
        setWallets(wallets__)
    }


    const remindMember = async () => {
        try{
            history.push('/app/id-connect')
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }


    return (
    <>
        {wallets.map(item => 
            <div class='card mt-4'>
            <div class='card-body'>
            <div class="row align-item-center mb-5 ">
                <div class="col-sm mb-2 mb-sm-0">
                    <div><span class="h1 mb-0">{parseFloat(item.nativeBalance*(getCoinDecimals(item.chain))).toFixed(4)}</span> {getCoinName(item.chain)}</div>
                    <div class="mt-1 small">{item.address}</div>
                </div>
                <div class="col-sm-auto align-self-sm-end">
                    <span class="badge badge-primary">{item.chain}</span>
                </div>
            </div>
            <div class='card'>
                <div class='card-header'>
                <h5 class="card-header-title">Assets owned by their wallet address</h5>
                    <NavSegment 
                        navMap={navMap}
                        setSelected = {setSelected}
                        selected = {selected}
                        color = "primary"
                    />
                </div>
                <Table 
                    data = {selected === 'TOKENS' ? item.tokens : item.nfts}
                    headers = {selected === 'TOKENS' ? ((item.chain === 'solana' ? tokensHeadersSolana: tokensHeadersEther)) : (item.chain === 'solana' ? nftHeadersSolana: nftHeadersEther)}
                />
            </div>
            </div>
        </div>)}
        {wallets.length === 0 && <div class='alert alert-soft-primary py-4 mt-4'>
            <div class='row align-items-center'>
                <div class='col'>
                    Run a quest to incentivise your members to connect their wallet
                </div>
                <div class='col-auto'>
                    <Button 
                        size = "sm"
                        text = "Launch quest now"
                        submitResponse = {remindMember}
                        loading = {loading}
                    />
                </div>
            </div>
        </div>}
    </>
    )
}

export default WalletActivity




const navMap = [
    {value: 'TOKENS', label: 'Tokens'},
    {value: 'NFTs', label: 'NFTs'},
]

const tokensHeadersEther = [{
    label: 'name',
    value: 'name',
}, {
    label: 'symbol',
    value: 'symbol',
}, {
    label: 'balance',
    value: '_balance',
}, {
    label: 'address',
    value: '_address',
}]

const tokensHeadersSolana = [{
    label: 'Mint',
    value: '_mint',
}, {
    label: 'balance',
    value: '_balance',
}, {
    label: 'address',
    value: '_address',
}]


const nftHeadersSolana = [{
    label: 'address',
    value: '_address',
}, {
    label: 'mint',
    value: '_mint',
}]


const nftHeadersEther = [{
    label: 'name',
    value: 'name',
}, {
    label: 'tokenId',
    value: '_tokenId',
}, {
    label: 'address',
    value: '_address',
}, {
    label: 'Url',
    value: '_tokenUri',
}, ]








const getShortnedNumber = (number_) => {
    let number = parseFloat(number_)
    //format large numbers into small numbers like 1000000 is 10E6
    if(number > 1000000000) return (number / 1000000000).toFixed(2) + 'B'
    if(number > 1000000) return (number / 1000000).toFixed(2) + 'M'
    if(number > 1000) return (number / 1000).toFixed(2) + 'K'
    return number.toFixed(2)

}