import React, {useEffect, useState} from 'react'

const ComingSoon = (props) => {

	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('Launch contests & quests right from the platform → referral campaigns, twitter follower quests etc.')

	useEffect(() => {
		if(props.location.pathname === '/app/members/twitter'){
			setTitle('View all your twitter followers here')
			setDescription('Check what your community is doing on twitter, view all your followers and much more.')
		}
		if(props.location.pathname === '/app/communicate'){
			setTitle('Run personalised communication campaigns')
			setDescription('Communicate with your members across various channels - EPNS,Discord, Twitter etc')
		}
		if(props.location.pathname === '/app/rewards'){
			setTitle('Send airdrops and rewards to your members')
			setDescription('Create whitelists, send targeted airdrops to users who bring most value to your projec.t')
		}
	}, [props])
	

  return (
    <div>
        <div class="text-center">
          	<div class="h3">
            	<span class="badge badge-primary badge-pill ">
              		<i class="tio-hourglass-quarter"></i> Coming Soon
            	</span>
          	</div>
          	<div class="mb-4">
            	<h2>{title}</h2>
          	</div>
			<div class="w-lg-75 mx-lg-auto mb-5">
				<figure class="device-browser">
					<div class="device-browser-header">
						<div class="device-browser-header-btn-list">
							<span class="device-browser-header-btn-list-btn"></span>
							<span class="device-browser-header-btn-list-btn"></span>
							<span class="device-browser-header-btn-list-btn"></span>
						</div>
						<div class="device-browser-header-browser-bar">https://dashboard.intract.buzz/</div>
					</div>
					<div class="device-browser-frame" style = {{backgroundColor: '#fefff9'}}>
						<img class="device-browser-img" src="/assets/img/reward.png" alt="" style = {{maxHeight: '400px', objectFit: 'cover'}}/>
					</div>
				</figure>
				<p class='mt-5'>{description} </p>
			</div>
        </div>
    </div>
  )
}

export default ComingSoon