import React from 'react'
import SideModel from 'components/Models/SideModel'


const WhatsNew = ({show, setShow}) => {

    return (
        <SideModel
            setShow = {setShow}
            show = {show}
            title = "What's new?"
        >
            <h1>A space to explore our new features as we launch them</h1>
            
        </SideModel>
    )
}

export default WhatsNew
