import Avatar, {AvatarGroup} from 'components/Avatar'
import React, {useState} from 'react'
import ActivitySteps from 'components/Steps'
import Progress from 'components/Charts/Progress'


const ActivityCard = ({activities, metadata}) => {


    return (
        <div class = 'card mb-5 mt-3'>
            
            <div class = 'card-body'>
                <div class = 'row'>
                    <div className= 'col-lg-3 d-flex flex-column'>
                        <div className='mb-4'>
                            <p class = 'text-muted bold small mb-0'>People in conversation</p>
                            <p class = 'bold h5'>{metadata.totalMembers} members, {metadata.totalTeamMembers} teammates</p>
                        </div>
                        <OriginalAuthor author = {metadata.author}/>
                        {metadata.participants.length > 0 && <Participants participants = {metadata.participants}/>}
                        {(metadata.sentimentMetrics.totalNegative > 0 || metadata.sentimentMetrics.totalPositive) > 0 && <SentimentAnalysis sentimentMetrics = {metadata.sentimentMetrics}/>}
                    </div>
                    <div className = 'col-lg-9 column-divider pl-6'>
                        <CardTitle 
                            channelName = {metadata.channelName}
                            sourceLink = {metadata.sourceLink} 
                            sourceName = {metadata.sourceName}
                        />
                        <hr class=''/>
                        <div class= 'pt-5 mt-n2  pb-4' style = {{maxHeight: '340px', overflow: 'scroll'}}>
                            <ActivitySteps 
                                data = {activities}
                            />
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ActivityCard







const OriginalAuthor = ({author}) => {
    return (
        <div class='mb-5'>
            <p class = 'text-muted bold mb-2 small'>Original Author</p>
            <Avatar 
                avatar = {author.avatar}
                name = {author.name}
                tags = {author.tags}
                size = "sm"
                link  = {author.link}
            />
        </div>
    )
}


const Participants = ({participants}) => {
    return (
        <div>
            <p class = 'text-muted bold mb-2 small'>1 other participant</p>
            <AvatarGroup 
                users = {participants}
                maxLength = {4}
            />
        </div>
    )
}


const Author = ({author}) => {
    return (
        <Avatar 
            avatar = {author.img}
            name = {author.title}
            description={author.description}
            tags = {author.tags}
            time = {author.time}
        />
    )
}


const SentimentAnalysis = ({sentimentMetrics}) => {

    return (
        <div class='mt-auto'>
            <p class = 'text-muted bold small mb-2'>Sentiment Anlaysis</p>
            <Progress 
                height='2rem'
                legends={["Positive", "Negative"]}
                colors = {['success', 'danger']}
                yAxis = {[sentimentMetrics.totalPositive, sentimentMetrics.totalNegative]}
                xAxis = {[sentimentMetrics.totalPositive, sentimentMetrics.totalNegative]}
            />
        </div>
    )
}


const CardTitle = ({channelName, sourceLink, sourceName}) => {
    return (
        <div class = 'd-flex align-items-center mb-2 '>
            <h5 class='mb-0'>#{channelName}</h5>
            <div class="media align-items-center  ml-auto">
                <span class="mr-2">
                    <img class="avatar avatar-xs" src="/assets/svg/brands/discord.svg" alt="" />
                </span>
                <div class="media-body text-truncate">
                    <a class="d-block font-size-sm text-dark text-truncate" href = {sourceLink} target="_blank">{sourceName}</a>
                </div>
            </div>
        </div>
    )
}



