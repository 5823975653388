import axiosInstance from '../axios';




export const getMembersAndInvitationsApi = async () => {
    try{

        const {data: {success, data}} = await axiosInstance.get('/workplace/team/all')
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}





export const removeMemberApi = async (userId) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/workplace/team/remove-member', {
            userId
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}




export const editMemberRoleApi = async (userId, role) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/workplace/team/update-role', {
            userId, 
            role
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}



export const resendInvitationApi = async (_id) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/workplace/team/resend-invite', {
            invitationId: _id
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const cancelInvitationApi = async (_id) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/workplace/team/cancel-invite', {
            invitationId: _id
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}



export const acceptInvite = async ({hash, password}) => {
    try{
        const {data: {success, data}} = await axiosInstance.post('/workplace/team/accept-invite', {
            hash,
            password
        })
        if(success) return data;

    } catch(err){
        console.log(err)
        throw err
    }
}