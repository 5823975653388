import React from 'react'

export const Divider = () => {
    return (
        <div class="text-center mb-4">
          <span class="divider text-muted">OR</span>
        </div>
    )
}

