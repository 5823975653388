import React, {useState, useContext, useEffect} from 'react'
import LetsBegin from './components/LetsBegin'
import DiscoverMembers from './components/DiscoverMembers'
import KpiCard from './components/KpiCard'
import { PageHeader } from 'components/Text'
import {Store} from 'store'
import {fetchHealthMetricsApi} from 'services/reports'
import {getTopMembersApi} from 'services/members'
import {saveReports} from 'store/actions/reports'
import formatter from './utils/formatter'

const Home = () => {

    const {state, context} = useContext(Store);
    const [metrics, setMetrics] = useState(sampleData);
    const [members, setMembers] = useState();

    useEffect(() => {
        getMetrics()
        getMembers()
    }, [])

    const getMetrics = async () => {
        try{

            // const res = await fetchHealthMetricsApi()
            // const data = formatter(res)
            // setMetrics(data)

        } catch(err){
            console.log(err)
        }
    }

    const getMembers = async () => {
        try{

            const activeMembers = await getTopMembersApi()
            setMembers(activeMembers)

        } catch(err){
            console.log(err)
        }
    }

    return (
        <>
            <PageHeader 
                title = {`Hello, ${state.user.data.firstName}`}
                description = "Here's how your stakeholders are doing this week"
                breadcrumb={false}
            />
            {/* <LetsBegin /> */}
            {/* <KpiCard  data = {metrics}/> */}
            <DiscoverMembers data = {members}/>
        </>
    )
}

export default Home






const sampleData = [{
    title: 'Total members',
    value: null,
    description: '5k orders',
    trend: 4.3,
    icon: 'group-junior'
}, {
    title: 'Engagament rate',
    value: null,
    description: '5k orders',
    trend: -4.3,
    icon: 'chat',
    help: 'This is the percentage of unique members who engaged at least once in last one week'
}, {
    title: 'Total off-chain Activity',
    value: null,
    description: '5k orders',
    trend: 4.3,
    icon: 'diamond',
    help: 'Total activity is defined as the sum of all messages & replies'
}, {
    title: 'Sentiment meter',
    value: null,
    description: '5k orders',
    trend: 4.3,
    icon: 'favorite-comment'
}]

