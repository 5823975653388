import React, {useContext, useState, useEffect} from 'react'
import CenteredCard from 'components/Layout/CenteredCard'
import {Store} from 'store'
import {resendEmailConfirmation} from 'services/user/auth'
import toast from 'react-hot-toast'

const VerifyEmail = () => {

    const {state} = useContext(Store);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(0);

    const resend = async () => {
        try{
            setLoading(true);
            await resendEmailConfirmation();
            toast.success("Confirmation email successfully sent!")
            setTimer(10)
            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

    useEffect(() => {
        let interval
        if(timer > 0){
             interval = setInterval(() => setTimer(prev => prev-1), 1000);
        }
        return () => clearInterval(interval)
    }, [timer])

    return (
        <CenteredCard>
           <div class="card-body text-center">
                <div class="mb-4">
                    <img class="avatar avatar-xxl " src="/assets/img/bee.png" alt="" />
                </div>
                <h1 class="display-4">Verify your email</h1>
                <p class="mb-1">We've sent a link to your email address:</p>
                <span class="d-block text-dark font-weight-bold mb-1">{state.user.data.email}</span>
                <p>Please follow the link inside to continue.</p>
                <p className = 'mb-1'>Didn't receive an email? </p>
                {timer > 0 ? <span>Please wait {timer} seconds to resend email</span> : (loading ? 'Sending...' : <span className = 'btn-link pointer' onClick = {resend}>Resend</span>)}
            </div>
        </CenteredCard>
    )
}

export default VerifyEmail


