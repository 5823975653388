import { Button } from 'components/Forms/Buttons'
import { InputFieldRow } from 'components/Forms/TextInput'
import React, {useState, useRef, useContext} from 'react'
import AvatarInpurRow from 'components/Forms/FileAttachment'
import { updateAvatarApi, updateUserProfileApi } from 'services/user'
import {Store} from 'store'
import { updateUser } from 'store/actions/auth'
import toaster from 'react-hot-toast'

const BasicInformation = ({}) => {

    const {state, dispatch} = useContext(Store)
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState({
        firstName: state.user.data.firstName,
        lastName: state.user.data.lastName,
        telegram: state.user.data.metadata?.telegram,
        twitter: state.user.data.metadata?.twitter,
        website: state.user.data.metadata?.website,
    })
    const fileRef = useRef(null)

   
    const saveChanges = async () => {
        try{
            if(!validate()) return;
            setLoading(true)
            if(fileRef.current.files.length > 0){
                await updateAvatarApi(fileRef.current.files[0])
            }
            await updateUserProfileApi({
                'firstName': profile.firstName,
                'lastName': profile.lastName,
                'metadata.telegram': profile.telegram,
                'metadata.website': profile.website,
                'metadata.twitter': profile.twitter
            })
            const changes = {...state.user.data};
            changes.firstName = profile.firstName;
            changes.lastName = profile.lastName;
            changes.metadata = changes.metadata || {}
            changes.metadata.telegram = profile.telegram;
            changes.metadata.website = profile.website;
            changes.metadata.twitter = profile.twitter;
            updateUser(state.user.data, changes, dispatch)
            setLoading(false)
            toaster.success("Your profile successfully updated")
        } catch (err) {
            setLoading(false)
            toaster.error("Error occured! Please refresh and try again")
            console.log(err)
        }
    }

    const validate = () => {
       return true;
    }


    return (
        <div class="card mb-3 mb-lg-5" id = 'account-basic'>
            <div class="card-header">
                <h2 class="card-title h4">Your information</h2>
            </div>
            <div class="card-body">
                <form>
                    {editMemberProfileLabels.map(item => (
                        <InputFieldRow
                            label = {item.label}
                            icon = {item.icon}
                            value = {profile[item.value]}
                            setValue = {(val) => {
                                setProfile(prev => ({
                                    ...prev,
                                    [item.value]: val
                                }))
                            }}
                            size = "sm"
                            tooltip ={item.tooltip}
                            marginBotton = {0}
                            columnSize = {3}
                    />))}
                    <AvatarInpurRow 
                        label = "Avatar"
                        icon = "tio-user"
                        fileRef = {fileRef}
                        img = {state.user.data.avatar}
                    />
                    <div class="d-flex justify-content-end border-top pt-3 mt-3 mb-n2">
                        
                        <Button
                            submitResponse = {saveChanges} 
                            text = "Save changes" 
                            loading={loading}
                            loadingText = "Saving..."
                            color = "primary"
                        />
                    </div>
                </form>
              </div>
            </div>
    )
}

export default BasicInformation



const editMemberProfileLabels = [{
    value: 'firstName',
    label: 'First name',
    icon: 'tio-user'
},{
    value: 'lastName',
    label: 'Last name',
    icon: 'tio-user'
},{
    value: 'telegram',
    label: 'Telegram',
    icon: 'tio-telegram'
},{
    value: 'website',
    label: 'Website',
    icon: 'tio-website'
}, {
    value: 'twitter',
    label: 'Twitter',
    icon: 'tio-twitter'
}]
