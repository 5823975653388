import React, {useState, useEffect} from 'react'
import { InputField, InputFieldRow } from 'components/Forms/TextInput'

const StepOne = ({setStep, quest, setQuest, nextStep, prevStep}) => {

 
    // const [show, setShow] = useState(false)
    const [errors, setErrors] = useState({tokenValue: false, xpValue: false})

    const handleNext = () => {
        const newErrors = {...errors}
        if(!quest.reward.xpValue || quest.reward.xpValue < 1){
            newErrors.xpValue = true;
        }
        if(quest.reward.tokenSymbol && (!quest.reward.tokenValue || quest.reward.tokenValue < 1)){
            newErrors.tokenValue = true;
        }
        setErrors(newErrors)
        if(!newErrors.tokenValue && !newErrors.xpValue){
            setStep(nextStep)
        }
    }
    
    useEffect(() => {
        setErrors({tokenValue: false, xpValue: false})
    }, [quest])

    return (
        <div id="addUserStepProfile" class="card card-lg active" >
            <div class="card-body">
                <div>
                    <h4>Reward details</h4>
                </div>
                <div>
                    <div className='form-group mb-5 mt-3'>
                        <InputField 
                            label = 'Enter XPs to reward members'
                            description = "XPs are off chain & free"
                            value = {quest.reward.xpValue}
                            setValue = {e => setQuest(prev => ({...prev, reward: {...prev.reward, xpValue: e}}))}
                            error = {errors.xpValue}
                            setError = {(e) => setErrors(prev => ({...prev, xpValue: e}))}
                            placeholder = 'Enter XPs to reward members'
                            errorText = "Required field"
                            type = 'number'
                            labelClass='bold'
                        />
                    </div>
                    {/* <div className='form-group mb-5 mt-3'>
                        <label class='input-label bold'>Tokens to reward (optional)</label>
                        <p className='mt-n2 fs-sm'>Select a token from your Metamask wallet</p>
                        <div class='row'>
                            <div class='col-6'>
                                <InputField 
                                    value = {quest.reward.tokenSymbol}
                                    setValue = {e => setQuest(prev => ({...prev, reward: {...prev.reward, tokenSymbol: e}}))}
                                    placeholder = 'Token Name'
                                />
                            </div>
                            <div class='col-6'>
                                <InputField 
                                    value = {quest.reward.tokenValue}
                                    setValue = {e => setQuest(prev => ({...prev, reward: {...prev.reward, tokenValue: e}}))}
                                    placeholder = 'Token Amount (to be rewarded per successful entry)'
                                    error = {errors.tokenValue}
                                    setError = {(e) => setErrors(prev => ({...prev, xpValue: e}))}
                                    errorText = "Required as you have added a token name"
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div class='divider mb-5'></div>
                <div class='card bg-light'>
                    <div class='card-body'>
                        <SwitchButton 
                            label = "Whether to auto evaluate?"
                            description ="Do you want us to give XPs immeditely after their submission"
                            columnSize={4}
                            value = {quest.reward.autoXp}
                            setValue = {(e) => setQuest(prev => ({...prev, reward: {...prev.reward, autoXp: e}}))}
                        />
                    </div>
                </div> */}

            </div>

            <div class="card-footer d-flex  align-items-center">
                <button type="button" class="btn btn-soft-primary mr-3" onClick={() => setStep(prevStep)}>
                    <i class="tio-chevron-left"></i> Back
                </button>
                <div class='ml-auto'>
                <button type="button" class="btn btn-primary" onClick={() => handleNext()}>
                    Next <i class="tio-chevron-right"></i>
                </button>
                </div>
            </div>
            {/* <SelectCoin 
                show = {show}
                setShow = {setShow}
            /> */}
        </div>
  )
}

export default StepOne



const rewardsOptions = [{
    value: 'fcfs',
    label: 'First come first serve'
}, {
    value: 'randomized',
    label: 'Randomized'
}]


/**
 * Campaign type
 *  (One time, Recurring)
 * Campaign start date and end date
 *  
 * Category
 * 
 */

