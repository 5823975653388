
import { FETCH_TAGS, FETCH_SOURCES, FETCH_CHANNELS, FETCH_ROLES } from "../actions/community";

const communityReducer = (state, action) => {
    switch (action.type) {
        case FETCH_TAGS:
            return {
                ...state,
                tags: action.payload.data,
            }
        case FETCH_SOURCES:
            return {
                ...state,
                sources: action.payload.data,
            }
        case FETCH_CHANNELS:
            return {
                ...state,
                channels: action.payload.data,
            }
        case FETCH_ROLES:
            return {
                ...state,
                discordRoles: action.payload.data,
            }
        default:
            return state;
    }
}

export default communityReducer;

