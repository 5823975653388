import Avatar from 'components/Avatar';
import HorizontalNav from 'components/Nav/HorizontalNav';
import DataTable, { Table } from 'components/Table';
import React, { useEffect, useState, useContext } from 'react'
import { getSubmissionsApi, changeStatusApi, completeQuestApi } from 'services/quests';
import moment from 'moment'
import Address from 'components/Others/Address'
import toast from 'react-hot-toast'
import {Button} from 'components/Forms/Buttons'
import {Store} from 'store'
import {updateQuests} from 'store/actions/workplace'
import Announcement from './components/Announcement';

const Quest = (props) => {

    const {state, dispatch} = useContext(Store)
    const [selectedNav, setSelectedNav] = useState('completed');
    const [submissions, setSubmissions] = useState([]);
    const [fetchedSubmissions, setFetchedSubmissions] = useState([]);
    const [quest, setQuest] = useState({});
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [showAnnouncement, setShowAnnouncement] = useState(false)
    
    useEffect(() => {
        const id = props.match.params.id
        fetchSubmissions(id)
    }, [])
   
    useEffect(() => {
        if(quest && fetchedSubmissions) formatSubmissions(fetchedSubmissions, quest)
    }, [fetchedSubmissions, quest])

    const fetchSubmissions = async (id) => {
        try {
            const data = await getSubmissionsApi(id)
            setQuest(data.quest)
            setFetchedSubmissions(data.submissions)
        } catch (err) {
            console.log(err)
        }
    }

    
    const formatSubmissions = (submissions_, quest_) => {
        setSubmissions(submissions_.map(item => ({
            ...item,
            memberLabel: {
                label: <Avatar 
                    name = {item.memberId.metadata.username}
                    avatar = {item.memberId.metadata.avatar}
                />,
                value: item.memberId.metadata.username
            },
            statusLabel: {
                value: item.status,
                label: <div class='badge badge-soft-success'>{item.status.toLowerCase()}</div>
            },
            dateLabel: moment(item.createdAt).format('Do MMM YY'),
            rewardLabel: {
                label: <RewardTags xpValue = {quest_?.reward?.xpValue} tokenValue = {quest_?.reward?.tokenValue}/>, 
                value: (quest_?.reward?.xpValue ? quest_?.reward?.xpValue + ' XP' : '') + ' ' + (quest_?.reward?.tokenValue ? quest_?.reward?.tokenValue + ' tokens' : ''),
            },
            rewardedLabel: {
                label: <RewardTags xpValue = {item.rewarded?.xpValue} tokenValue = {item.rewarded?.tokenValue}/>, 
                value: (item.rewarded?.xpValue ? item.rewarded?.xpValue + ' XP' : '') + ' ' + (item.rewarded?.tokenValue ? item.rewarded?.tokenValue + ' tokens' : ''),
            },
            buttonsLabel: {
                label: getButtonLabel(item.status) ? <div className='btn btn-xs btn-primary' onClick={() => changeStatus([item._id], getNextStatus(item.status))}>{getButtonLabel(item.status)}</div> : <></>,
                value: ''
            },
        })))
    }


    const changeStatus = async (submissionsIds_, newStatus) => {
        try{
            const newSubmissions = [...fetchedSubmissions];
            submissionsIds_.forEach(id => {
                const index = newSubmissions.findIndex(item => item._id === id)
                if(index > -1){
                    newSubmissions[index].status = newStatus;
                    
                }
            })
            setFetchedSubmissions(newSubmissions)
            const res = await changeStatusApi(quest._id, submissionsIds_, newStatus)

        } catch(err){
            toast.error('Please refresh, something went wrong')
            console.log(err)
        }
    }

    const selectedOptions = [
        {
            label: 'Mark Reviewed',
            function: (selected) => changeStatus(selected, 'REVIEWED')
        }, {
            label: 'Export',
            function: (selected) => exportMembers(selected)
    }]

  

    const markThisCompleted = async () => {
        try{
            setLoading1(true)
            await completeQuestApi(quest._id)
            setQuest(prev => ({...prev, status: 'COMPLETED'}))
            if(state.workplace.quests){
                const newQuests = [...state.workplace.quests];
                const index = newQuests.findIndex(item => item._id === quest._id);
                if(index > -1){
                    newQuests[index].status = 'COMPLETED';
                    updateQuests(newQuests, dispatch)
                }
            }
            setLoading1(false)
            toast.success("Quest marked as completed. Discord wallet connect command is not inactive.")
        } catch(err){
            setLoading1(false)
            console.log(err)
        }
    }
        

    return (
        <div>
            <div class="mb-5">
                <div class="row align-items-end mb-3">
                    <div class="col-sm mb-sm-0">
                        <h1 class="page-header-title mb-0">{quest.title}</h1>
                    </div>
                </div>
            </div>
            <div class='card mt-4'>
                <DataTable 
                    data = {submissions}
                    headers = {headers} 
                    searchPlaceholder = "Search by usernames"
                    size="lg"
                    searchable = {false}
                    rowsPerPage = {10}
                    loadingOnEmpty = {true}
                />
            </div>
            {quest && <Announcement 
                show = {showAnnouncement}
                setShow = {setShowAnnouncement}
                quest = {quest}
            />}
        </div>
    )
}

export default Quest



const headers = [
    {value: 'memberLabel', label: 'Member'},
    {value: 'dateLabel', label: 'Date'},
    // {value: 'rewardLabel', label: 'Reward'},
    {value: 'rewardedLabel', label: 'Rewarded'},
    {value: 'statusLabel', label: 'status'},
    // {value: 'buttonsLabel', label: ''},
]

const sampleData = [
    {
        member: {
            label: <Avatar 
                name = "@abhishek1804" 
                avatar = "/assets/img/bee.png"
            />,
            value: 'abhishek'
        },
        date: '1/1/2020',
        reward: '10 Tokens, 20 XP',
        buttons: {
            label: <div className='btn btn-sm btn-primary'>Approve</div>,
            value: ''
        }
    }
]




const RewardTags = ({xpValue, tokenValue}) => {
    return (
        <div>
            {xpValue ? <span className='badge badge-soft-primary mr-2'>{xpValue} XP</span> : ''}
            {tokenValue ? <span className='badge badge-soft-primary'>{tokenValue} tokens</span> : ''}
        </div>
    )
}


const getButtonLabel = (status) => {
    switch(status){
        case 'STARTED':
            return 'Mark Completed'
        case 'COMPLETED':
            return 'Mark Reviewed'
        case 'REVIEWED':
            return 'Mark Rewarded'
        case 'REWARDED':
            return ''
    }
}


const getNextStatus = status => {
    switch(status){
        case 'STARTED':
            return 'COMPLETED'
        case 'COMPLETED':
            return 'REVIEWED'
        case 'REVIEWED':
            return 'REWARDED'
        case 'REWARDED':
            return ''
    }
}



/**
 * .filter(item => {
                        if(selectedNav === 'completed'){
                            return item.status === 'COMPLETED'
                        } else if(selectedNav === 'REVIEWED'){
                            return item.status === 'REVIEWED'
                        } else if(selectedNav === 'rewarded'){
                            return item.status === 'REWARDED'
                        }
                    })
 */