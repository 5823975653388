export const getAvatar = (id, avatar, name) => avatar ? `https://cdn.discordapp.com/avatars/${id.split(':')[0]}/${avatar}.png` : name ? null : `/assets/img/160x160/bee${randomIntFromInterval(1, 3)}.png`

export const getUserAvatar = (id, avatar) => {
    if(!avatar) return null
    if(avatar.includes('https') || avatar.includes('assets') || avatar.includes('png')) return avatar
    else if (id && avatar) {
        return `https://cdn.discordapp.com/avatars/${id}/${avatar}.png` 
    }
    else return null;
}


function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}