import React, {useState, useEffect, useContext} from 'react'
import AddUser from './Components/AddUser'
import { Store } from 'store'
import { getMembersAndInvitationsApi, removeMemberApi, resendInvitationApi, cancelInvitationApi } from 'services/settings/team'
import {Table} from 'components/Table'
import Avatar, { UserAvatar } from 'components/Avatar'
import moment from 'moment'
import Dropdown from 'components/Forms/Dropdown'
import {fireSwal} from 'utilis/swal'
import toaster from 'react-hot-toast'
import { removeElementfromArrayUsingId, clickOutside } from 'utilis/helpers'
import {updateInvitations, updateTeam} from 'store/actions/workplace'

const Team = () => {

    const {state, dispatch} = useContext(Store)
    const [team, setTeam] = useState([])
    const [invitations, setInvitations] = useState([])
    const [showAddUser, setShowAddUser] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(state.workplace.team && state.workplace.invitations){
            formatTeam(state.workplace.team)
            formatInvitations(state.workplace.invitations)
        }
        else fetchMembersAndInvitations();
    }, [state.workplace.team, state.workplace.invitations])


    const fetchMembersAndInvitations = async () => {
        try{
            const data = await getMembersAndInvitationsApi()
            updateTeam(data.members, dispatch)
            updateInvitations(data.invitations, dispatch)
            
        } catch(err){
            console.log(err)
        }
    }


    const formatTeam = (data) => {
        
        setTeam(data.map(item => ({
            ...item,
            name: {
                value: item.username,
                label: <UserAvatar 
                    firstName = {item.firstName}
                    discordId = {item.discordId}
                    lastName = {item.lastName}
                    email = {item.email}
                    avatar = {item.avatar}
                    margin ="mr-3"
                    isBody
                    active
                />
            },
            createdAt: moment(item.createdAt).format('Do MMM'),
            role: {
                value: item.role,
                label: <span class={`badge badge-${item.role.toLowerCase() === 'owner' ? 'success': 'primary'}`}>{item.role.toLowerCase()}</span>
            },
            options: {
                value: item._id,
                label: (state.user.data.role.toLowerCase() === 'owner' && item.role.toLowerCase() !== 'owner') ? <Dropdown 
                    size = 'sm' 
                    position = 'right' 
                    minWidth = {140}
                    popover = {<>
                        <span class="dropdown-item text-danger" onClick={() => removeUser(item._id)}>Remove User</span>
                    </>}
                >
                    <a class="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle">
                        <i class="tio-more-vertical"></i>
                    </a>
                </Dropdown> : <></>
            }
        })).sort((b, a) => a.role.value.toLowerCase() === 'owner' ? 1 : -1))
        
    }

    const formatInvitations = (data) => {
        console.log(data)
        setInvitations(data.map(item => ({
            ...item,
            name: {
                value: item.username,
                label: <UserAvatar 
                    firstName = {item.firstName}
                    lastName = {item.lastName}
                    email = {item.email}
                    margin ="mr-3"
                    isBody
                    active
                />
            },
            createdAt: {
                value: 'pending',
                label: <span class='badge badge-soft-danger'>{loading === 'resend-invite' ? 'Resending invite...' : 'Pending invitation'}</span>
            },
            role: {
                value: item.role,
                label: <span class={`badge badge-${item.role.toLowerCase() === 'owner' ? 'success': 'primary'}`}>{item.role.toLowerCase()}</span>
            },
            options: {
                value: item._id,
                label: state.user.data.role.toLowerCase() === 'owner' ? <Dropdown 
                    size = 'sm' 
                    position = 'right' 
                    minWidth = {140}
                    popover = {<>
                        <span class="dropdown-item" onClick = {() => resendInvite(item._id)}>Resend Invite</span>
                        <div class="dropdown-divider"></div>
                        <span class="dropdown-item text-danger" onClick = {() => cancelInvite(item._id)}>Cancel Invite</span>
                    </>}
                >
                    <a class="js-hs-unfold-invoker btn btn-icon btn-sm btn-ghost-secondary rounded-circle">
                        <i class="tio-more-vertical"></i>
                    </a>
                </Dropdown> : <></>
            }
        })))
    }

    
    const cancelInvite = async (_id) => {
        try{
            clickOutside()
            const response = await fireSwal({
                title: 'Are you sure?', 
                text: 'Are you sure you want to cancel the invite? The already send invitation will become invalid. ', 
                icon: 'warning', 
                confirmText: 'Yes', 
                cancelText: 'No'
            })
            if(!response) return;
            setLoading('cancel-invite')
            await cancelInvitationApi(_id)
            toaster.success("Invitation was successfully deleted")
            const newInvitations = removeElementfromArrayUsingId(state.workplace.invitations, _id)
            updateInvitations(newInvitations, dispatch)

        } catch(err){
            console.log(err)
        }
    }
  

    const resendInvite = async (_id) => {
        try{
            clickOutside()
            setLoading('resend-invite')
            await resendInvitationApi(_id)
            toaster.success("Invitation was successfully sent!")
            setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    const removeUser = async (_id) => {
        try{
            clickOutside()
            const response = await fireSwal({
                title: 'Are you sure?', 
                text: 'Are you sure you want to cancel the invite? The already send invitation will become invalid. ', 
                icon: 'warning', 
                confirmText: 'Yes', 
                cancelText: 'No'
            })
            if(!response) return;
            setLoading('remove-user')
            await removeMemberApi(_id)
            setLoading(false);
            toaster.success("Invitation was successfully deleted")
            const newTeam = removeElementfromArrayUsingId(state.workplace.team, _id)
            updateTeam(newTeam, dispatch)

        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

    return (
        <div class = 'card mb-3 mb-lg-5' id = 'account-team'>
            <div className='card-header'>
                <h5>Team</h5>
                {state.user.data.role.toLowerCase() === 'owner' && <div className = 'btn btn-sm btn-primary' onClick = {() => setShowAddUser(true)}>
                    <i className = 'tio-user-add  mr-2'></i>Add User
                </div>}
            </div>
            <Table 
                headers = {tableHeaders} 
                data = {[...team, ...invitations]}
                size="lg"
            />
            <AddUser 
                show = {showAddUser} 
                setShow = {setShowAddUser}
                setInvitations = {setInvitations}
                invitations = {invitations}
            />
        </div>
    )
}

export default Team


const tableHeaders = [{
    value: 'name',
    label: 'Name'
}, {
    value: 'role',
    label: 'role'
}, {
    value: 'createdAt',
    label: 'Added on'
}, {
    value: 'options',
    label: ''
}]


const sortByOwner = (a, b) => a.role !== 'OWNER' ? 1 : -1