import { Switch, useRouteMatch, Redirect } from "react-router-dom";
import ProtectedRoute from 'components/Routes/ProtectedRoute';
import Home from 'pages/Dashboard/Home'
import Settings from 'pages/Dashboard/Settings'
import Complete from 'pages/Onboarding/Complete';
import CreateWorkplace from 'pages/Onboarding/CreateWorkplace';
import VerifyEmail from 'pages/Onboarding/VerifyEmail';
import Integrations from "pages/Dashboard/Integrations";
import AddDiscordIntegration from "pages/Dashboard/Integrations/Discord";
import Members from "pages/Dashboard/Members";
import Member from "pages/Dashboard/Member";
import Activity from "pages/Dashboard/Activity";
import Report from "pages/Dashboard/Report";
// import Rewards from "pages/Dashboard/Rewards";
import SavedReports from "pages/Dashboard/SavedReports/index.js";
import PublishedReport from "pages/Dashboard/PublishedReport";
import PublicRoute from "components/Routes/PublicRoute";
import Segments from "pages/Dashboard/Segments";
import Segment from "pages/Dashboard/Segment";
import Quests from "pages/Dashboard/Quests";
import AddQuest from "pages/Dashboard/CreateQuest";
import Quest from "pages/Dashboard/Quest";
import Rewards from "pages/Dashboard/Rewards";
import CreateAirdrop from "pages/Dashboard/CreateAirdrop";
import IdConnect from "pages/Dashboard/IdConnect";
import UniqueLink from "pages/Dashboard/CreateAirdrop/Components/UniqueLink";
import DirectWalletAddress from "pages/Dashboard/CreateAirdrop/Components/WalletAddress";
import CreateSegment from "pages/Dashboard/CreateSegment";
import Tokens from "pages/Dashboard/Tokens";
import ComingSoon from "pages/Dashboard/Comingsoon";
import DiscordRoles from "pages/Dashboard/DiscordRoles";
import DiscordMembers from "pages/Dashboard/Members/DiscordMembers";
import TokenHolders from "pages/Dashboard/Members/TokenHolders";
import NoAccess from "pages/Onboarding/NoAccess";
import IdConnectCampaign from 'pages/Dashboard/IdConnect/Submissions'
import EditIdConnectCampaign from 'pages/Dashboard/IdConnect/EditCampaign'




const DashboardRoutes = () => {

    let {path} = useRouteMatch();

 
    return (
        <>
            <Switch>
                <ProtectedRoute exact path={`${path}/verify-email`} component={VerifyEmail}  />
                <ProtectedRoute exact path={`${path}/create`} component={CreateWorkplace}  />
                <ProtectedRoute exact path={`${path}/onboarding-complete`} component={Complete}  />
                <ProtectedRoute exact path={`${path}/no-access`} component={NoAccess}  />
                <ProtectedRoute exact path={`${path}/`} component={Home} dashboard />

                <ProtectedRoute exact path = {`${path}/members`} component = {Members} dashboard />
                <ProtectedRoute exact path = {`${path}/members/discord`} component = {DiscordMembers} dashboard />
                <ProtectedRoute exact path = {`${path}/members/token-holders`} component = {TokenHolders} dashboard />
                <ProtectedRoute exact path = {`${path}/members/twitter`} component = {ComingSoon} dashboard />
                
                <ProtectedRoute exact path = {`${path}/member/:id`} component = {Member} dashboard />
                
                <ProtectedRoute exact path = {`${path}/report`} component = {Report} dashboard />
                <ProtectedRoute exact path = {`${path}/report/saved`} component = {SavedReports} dashboard />
                <ProtectedRoute exact path = {`${path}/report/:id`} component = {Report} dashboard />
                <PublicRoute exact path = {`${path}/published-report/:id`} component = {PublishedReport} dashboard />
                
                <ProtectedRoute exact path = {`${path}/activity`} component = {Activity} dashboard />
                <ProtectedRoute exact path = {`${path}/activity/:id`} component = {Activity} dashboard />
                
                <ProtectedRoute exact path = {`${path}/coming-soon`} component = {ComingSoon} dashboard />
                
                <ProtectedRoute exact path = {`${path}/communicate/`} component = {ComingSoon} dashboard />
                <ProtectedRoute exact path = {`${path}/rewards/`} component = {ComingSoon} dashboard />
                <ProtectedRoute exact path = {`${path}/rewards/add`} component = {CreateAirdrop} dashboard />
                <ProtectedRoute exact path = {`${path}/rewards/add/direct`} component = {DirectWalletAddress} dashboard />
                <ProtectedRoute exact path = {`${path}/rewards/add/link`} component = {UniqueLink} dashboard />
                
                <ProtectedRoute exact path = {`${path}/segments/`} component = {Segments} dashboard />
                <ProtectedRoute exact path = {`${path}/segments/add`} component = {CreateSegment} dashboard />
                <ProtectedRoute exact path = {`${path}/segments/:id`} component = {Segment} dashboard />
                
                <ProtectedRoute exact path = {`${path}/quests/`} component = {Quests} dashboard />
                <ProtectedRoute exact path = {`${path}/quest/add`} component = {AddQuest} dashboard />
                <ProtectedRoute exact path = {`${path}/quest/:id`} component = {Quest} dashboard />
                
                
                <ProtectedRoute exact path = {`${path}/id-connect`} component = {IdConnect} dashboard />
                <ProtectedRoute exact path = {`${path}/id-connect/:id`} component = {IdConnectCampaign} dashboard />
                <ProtectedRoute exact path = {`${path}/id-connect/edit/:id`} component = {EditIdConnectCampaign} dashboard />

                <ProtectedRoute exact path={`${path}/account/settings`} component={Settings} dashboard />
                <ProtectedRoute exact path = {`${path}/account/tokens`} component = {Tokens} dashboard />
                <ProtectedRoute exact path = {`${path}/account/discord-roles`} component = {DiscordRoles} dashboard />
                <ProtectedRoute exact path={`${path}/account/data-sources`} component={Integrations} dashboard />
                <ProtectedRoute exact path={`${path}/account/data-sources/discord`} component={AddDiscordIntegration} dashboard />

                <Redirect from = "*" to = "/app" />
            </Switch>
        </>
    );
}

export default DashboardRoutes;


