import { SimpleModal } from 'components/Models/Modal'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { getDiscordAuthLinkApi } from 'services/workplace'
import config from 'utilis/config'
import MyDropzone from 'components/Forms/Dropzone'
import {uploadWalletAddressesApi} from 'services/workplace'
import { InputField } from 'components/Forms/TextInput'
import { Button } from 'components/Forms/Buttons'

const UploadWalletAddresses = ({show, setShow}) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [file, setFile] = useState([])
    const [error, setError] = useState({title: true, description: true, file: true})
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setError(prev => ({...prev, title: false, description :false}))
    }, [name, description])
    
    useEffect(() => {
        setError(prev => ({...prev, file :false}))
    }, [file])

    
    const uploadFile = async () => {
        try{
            if(!validate()) return;
            const data = new FormData()
            data.append('file', file[0])
            data.append('name', name)
            data.append('description', description)
            setLoading(true)
            const response = await uploadWalletAddressesApi(data)
            setLoading(false)
            
            toast.success('File uploaded successfully! It might take few minutes to process.')
            setShow(false)
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }


    const validate = () => {
        let error = {title: false, description: false, file: false}
        if(name.length < 3) error.title = true
        if(description.length < 3) error.description = true
        if(file.length === 0) error.file = true
        setError(error)
        return !error.title && !error.description && !error.file
    }

    return (
        <SimpleModal
            show = {show}
            setShow = {setShow}
            title = "Add new data source"
            size = "lg"
        >   
            <div class='alert alert-soft-primary'>
                Connect your sources to bring community, product, and customer data together so you can unlock insights and take action
            </div>

            <InputField
                label = "Title"
                value = {name}
                setValue = {e => setName(e)}
                error = {error.title}
                errorMessage = "Title must be at least 3 characters long"
            />
            
            <InputField
                label = "Description"
                value = {description}
                setValue = {e => setDescription(e)}
                error = {error.description}
                errorMessage = "Description must be at least 3 characters long"
            />
            
            <MyDropzone
                onFileChange = {(file) => setFile(file)}
                files = {file} 
                height = "100px" 
                isMulti = {false} 
                type = {{'text/csv': ['.csv']}}
            />
            {error.file && <p class='text-danger'>Please upload a valid file</p>}

            <div class='mt-4'>
                <Button 
                    submitResponse = {uploadFile}
                    text = "Upload"
                    color = "primary"
                    loading = {loading}
                    block
                />
            </div>

       

        </SimpleModal>
    )
}

export default UploadWalletAddresses