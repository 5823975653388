import React, {useState, useEffect} from 'react'
import { InputField, InputFieldRow } from 'components/Forms/TextInput'
import SelectField from 'components/Forms/Select';

const Evaluation = ({setStep, nextStep, prevStep, quest, setQuest}) => {

    
    const [metadata, setMetadata] = useState('');
    const [alerts, setAlerts] = useState({
        alert1: '',
        alert2: '',
        alert3: '',
    });
    const [errors, setErrors] = useState({metadata: false, alerts: false})

    useEffect(() => {
        if(metadataInfo[quest.type]){
            setMetadata(quest.evaluation.metadata[metadataInfo[quest.type].metadataKey])
        }
        if(quest.type === 'QUEST::EMAIL::CONNECT'){
            setAlerts({
                alert1: quest.evaluation.metadata?.alerts?.length > 0 ? quest.evaluation.metadata.alerts[0]: '',
                alert2: quest.evaluation.metadata?.alerts?.length > 1 ? quest.evaluation.metadata.alerts[1]: '',
                alert3: quest.evaluation.metadata?.alerts?.length > 2 ? quest.evaluation.metadata.alerts[2]: '',
            })
        }
    }, [])

    useEffect(() => {
        if(metadataInfo[quest.type]){
            setQuest({
                ...quest, 
                evaluation: {
                    ...quest.evaluation, 
                    metadata: {
                        ...quest.evaluation.metadata,
                        [metadataInfo[quest.type].metadataKey]: metadata
                    }
                }
            })
        }
    }, [metadata])
    
    
    useEffect(() => {
        if(quest.type === 'QUEST::EMAIL::CONNECT'){
            setQuest({
                ...quest, 
                evaluation: {
                    ...quest.evaluation, 
                    metadata: {
                        ...quest.evaluation.metadata,
                        alerts: Object.values(alerts).filter(item => item)
                    }
                }
            })
        }
    }, [alerts])


    const editStartQuest = (label, value) => {
        setQuest(prev => ({
            ...prev, 
            startQuest: {
                ...prev.startQuest,
                metadata: {
                    ...prev.startQuest.metadata,
                    [label]: value
                }
            }
        }))
    }


    const handleNext = () => {
        const newErrors = {...errors}
        if(!metadata){
            newErrors.metadata = true;
        }
        if(quest.type === 'QUEST::EMAIL::CONNECT'){
            if(!alerts.alert1 && !alerts.alert2 && !alerts.alert3){
                newErrors.alerts = true;
            }
        }
        setErrors(newErrors)
        if(!newErrors.metadata && !newErrors.alerts){
            setStep(nextStep)
        }
    }

    useEffect(() => {
        setErrors({metadata: false, alerts: false})
    }, [metadata, alerts])
    
    return (
        <div id="addUserStepProfile" class="card card-lg active" >
            <div class="card-body">
                {/* <div class="alert alert-soft-secondary mb-4" role="alert">
                    You can select a custom audience to recieve this aidrop. You can either choose by their wallet, member or activity attributes. 
                </div> */}
                {(metadataInfo[quest.type] && !metadataInfo[quest.type].options) && <InputField 
                    label = {metadataInfo[quest.type].label}
                    columnSize={4}
                    value = {metadata}
                    setValue = {(e) => setMetadata(e)}
                    placeholder = {metadataInfo[quest.type].placeholder}
                    description={metadataInfo[quest.type].description}
                    errorText = {metadataInfo[quest.type].errorText}
                    labelClass="bold"
                    error = {errors.metadata}
                    setError = {e => setErrors(prev => ({...prev, metadata:e}))}
                />}
                {(metadataInfo[quest.type] &&  metadataInfo[quest.type].options) && <SelectField 
                    label = {metadataInfo[quest.type].label}
                    columnSize={4}
                    value = {metadata}
                    setValue = {(e) => setMetadata(e)}
                    placeholder = {metadataInfo[quest.type].placeholder}
                    description={metadataInfo[quest.type].description}
                    errorText = {metadataInfo[quest.type].errorText}
                    labelClass="bold"
                    error = {errors.metadata}
                    setError = {e => setErrors(prev => ({...prev, metadata:e}))}
                    options = {metadataInfo[quest.type].options}
                />}
                {(quest.type === "QUEST::EMAIL::CONNECT") && <>
                    <div class='form-group'>
                        <label class='form-label text-dark bold'>Set up alerts that you users can subscribe to</label>
                        <div class='row'>
                            <div class='col-4'>
                                <InputField
                                    value={alerts.alert1}
                                    setValue={(e) => setAlerts(prev => ({...prev, alert1:e}))}
                                    placeholder='Get company broadcasts'
                                    error = {errors.alerts}
                                />
                            </div>
                            <div class='col-4'>
                                <InputField
                                    value={alerts.alert2}
                                    setValue={(e) => setAlerts(prev => ({...prev, alert2:e}))}
                                    placeholder='Get transaction alerts'
                                    error = {errors.alerts}
                                />
                            </div>
                            <div class='col-4'>
                                <InputField
                                    value={alerts.alert3}
                                    setValue={(e) => setAlerts(prev => ({...prev, alert3:e}))}
                                    placeholder='Get liquidation alerts'
                                    error = {errors.alerts}
                                />
                            </div>
                        </div>
                        {errors.alerts && <div class='d-block invalid-feedback'>Please fill in at least one alert</div>}
                    </div>
                </>}
                <div className='form-group mb-3 mt-6'>
                    <label class='h3'>Discord Flow Configuration</label>
                </div>
                <div class='row'>
                    {(metadataInfo[quest.type] && metadataInfo[quest.type].startQuest.metadata) && metadataInfo[quest.type].startQuest.metadata.map(item => <div class='col-12 col-lg-12 mb-4'>
                        {item.options ? <SelectField 
                            value = {quest.startQuest.metadata[item.key]}
                            setValue = {(e) => editStartQuest(item.key, e)}
                            label = {item.label}
                            placeholder = {item.placeholder}
                            description={item.description}
                            labelClass="bold"
                            options = {item.options}
                        /> : <InputField 
                            value = {quest.startQuest.metadata[item.key]}
                            setValue = {(e) => editStartQuest(item.key, e)}
                            label = {item.label}
                            placeholder = {item.placeholder}
                            description={item.description}
                            labelClass="bold"
                        />}
                    </div>)}
                </div>
                
                {quest.startQuest.metadata.flowType === 'FROM_WEBSITE_WITH_BUTTON' && <div className='alert alert-soft-danger mt-n3'>After saving the quest, please send announcement to your preferred Discord channel for the button to get active</div>}
            </div>
            <div class="card-footer d-flex justify-content-end align-items-center">
                <button type="button" class="btn btn-soft-primary mr-3" onClick={() => setStep(prevStep)}>
                    <i class="tio-chevron-left"></i> Back
                </button>
                <div class='ml-auto'>
                <button type="button" class="btn btn-primary" onClick={() => handleNext()}>
                    Next <i class="tio-chevron-right"></i>
                </button>
                </div>
            </div>
        </div>
  )
}

export default Evaluation





const metadataInfo = {
    'QUEST::TWITTER::FOLLOW': {
        metadataKey: 'account',
        placeholder: '',
        label: 'Twitter account',
        errorText: 'Please enter the twitter account to be followed',
        description: 'Please enter the twitter account to be followed',
        
    },
    'QUEST::TWITTER::RETWEET': {
        metadataKey: 'tweet',
        placeholder: '',
        label: 'Tweet to be retweeted',
        errorText: 'Please enter the tweet link to be retweeted',
        description: 'Please enter the tweet link to be retweeted',
        startQuest: {
            description: 'You can add a link here from where you want your member to start the quest from. This could be discord invite link in case you are starting a Join Discord Quest.',
            metadata: [{
                key: 'label',
                label: 'Enter the button label',
                description: 'Please enter a slash command name, it should start with a / and be unique',
                placeholder: 'Retweet using this ink',
            }, {
                key: 'link',
                label: 'Enter the button link',
                placeholder: 'https://twitter.com/intent/retweet?tweet_id=1234',
            }]
        }
    },
    'QUEST::WALLET::CONNECT': {
        metadataKey: 'walletChain',
        placeholder: '',
        label: 'Allowed Chains',
        errorText: 'Please select chains you want to allow',
        description: 'Please select chains you want to allow for users to connect their wallet from',
        options: [{
            value: 'ethereum',
            label: 'Ethereum'
        }, {
            value: 'polygon',
            label: 'Polygon'
        }, {
            value: 'solana',
            label: 'Solana'
        }, {
            value: 'avalanche',
            label: 'Avalanche'
        }],
        isMultiSelect: true,
        startQuest: {
            description: 'You can add a link here from where you want your member to start thq quest from. This could be discord invite link in case you are starting a Join Discord Quest.',
            metadata: [ {
                key: 'flowType',
                label: 'Select the flow',
                description: 'Select the flow you want to use for your members to connect their wallet using a discord command',
                placeholder: 'Command to link your wallet to get exciting rewards',
                options: [
                    {value: 'FROM_DISCORD', label: 'From discord: users can directly add their wallet address from the discord channel'},
                    {value: 'FROM_WEBSITE', label: 'From website: users will be provided with a link using discord command from where they can add their wallet address'},
                    {value: 'FROM_WEBSITE_WITH_BUTTON', label: 'From website: users will be provided with a link using discord button from where they can add their wallet address'},
                ]
            }, {
                key: 'commandName',
                label: 'Enter the command name',
                description: 'Please enter a slash command name, it should start with a / and be unique',
                placeholder: '/wallet-connect',
            }, {
                key: 'commandDescription',
                label: 'Enter the command description',
                placeholder: 'Command to link your wallet to get exciting rewards',
            }]
        }
    },
    'QUEST::EMAIL::CONNECT': {
        metadataKey: 'walletChain',
        placeholder: '',
        label: 'Allowed Chains',
        errorText: 'Please select chains you want to allow',
        description: 'Please select chains you want to allow for users to connect their wallet from',
        options: [{
            value: 'ethereum',
            label: 'Ethereum'
        }, {
            value: 'polygon',
            label: 'Polygon'
        }, {
            value: 'solana',
            label: 'Solana'
        }, {
            value: 'avalanche',
            label: 'Avalanche'
        }],
        isMultiSelect: true,
        startQuest: {
            description: 'You can add a link here from where you want your member to start thq quest from. This could be discord invite link in case you are starting a Join Discord Quest.',
            metadata: [ {
                key: 'flowType',
                label: 'Select the flow',
                description: 'Select the flow you want to use for your members to connect their wallet using a discord command',
                placeholder: 'Command to link your wallet to get exciting rewards',
                options: [
                    {value: 'FROM_WEBSITE', label: 'From website: users will be provided with a link using discord command from where they can add their wallet address'},
                    {value: 'FROM_WEBSITE_WITH_BUTTON', label: 'From website: users will be provided with a link using discord button from where they can add their wallet address'},
                ]
            }, {
                key: 'commandName',
                label: 'Enter the command name',
                description: 'Please enter a slash command name, it should start with a / and be unique',
                placeholder: '/wallet-connect',
            }, {
                key: 'commandDescription',
                label: 'Enter the command description',
                placeholder: 'Command to link your wallet to get exciting rewards',
            }]
        }
    },

}