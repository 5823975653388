import React, {useRef, useEffect} from 'react'
import './chartjs/data'


const HalfDoughnut = (props) => {
    
    const chartRef = useRef();

    useEffect(() => {
        const ele = document.getElementById(props.id)
        var chart = window.HSCore.init(ele);
        chartRef.current = chart
    }, []);

    
    useEffect(() => {
        if(props.datasets && chartRef.current && chartRef.current.data) updateChartData()
    }, [props.datasets]);


    const updateChartData = () => {
        if(chartRef && chartRef.current){
            chartRef.current.data.datasets.forEach(function(dataset, key) {
                dataset.data = props.datasets[key];
            });
            chartRef.current.update()
        }
    }

    return (
        <>
            <div class="chartjs-doughnut-custom" style={{ height: props.height }}>
                <canvas class="js-chartjs-doughnut-half" id = {props.id} data-hs-chartjs-options={JSON.stringify(chartConfigMaker({...props}))}>
                </canvas>
                {props.customStat && props.customStat}
            </div>
        </>
    );
};

export default HalfDoughnut;



const chartConfigMaker = ({type, labels, datasets, gradient, yprefix, xprefix, stepsize, ypostfix, customColors}) => {

    const colors = {
        primary: customColors ? customColors : ['#377dff', '#00c9db', '#e7eaf3'],
        gradient: customColors ? customColors : ['rgba(55, 125, 255, .5)', 'rgba(0, 201, 219, .5)']
    }

    const data = {
        type,
        data: {
            "labels": labels,
            "datasets": datasets.map((dataset, key) => ({
                "data": dataset,
            }))
        },
        options: {
            "cornerRadius": 2, // cornerRadius
            cutoutPercentage: 80,
            rotation: 1 * Math.PI,
            circumference: 1 * Math.PI,
            "tooltips": {
                "prefix": yprefix,
                "hasIndicator": true,
                "mode": "index",
                "intersect": false,
                "postfix": ypostfix, 
                "lineWithLineColor": "rgba(19, 33, 68, 0.075)"
            },
            "hover": {
                "mode": "nearest",
                "intersect": true
            },
        }
    }
    if(type === 'doughnut'){
        data.data.datasets = data.data.datasets.map((item, index) => ({
            ...item,
            "backgroundColor": item.data.map((item, index2) => colors.primary[index2]),
            "borderWidth": 5,
            "hoverBorderColor": "#fff"
        }))
    }
    return data;
}

