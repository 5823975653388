import React, {useState, useContext, useEffect} from 'react'
import Tags from "components/Others/Tags"
import { AddTag } from 'components/Others/Tags'
import {addTagApi, deleteTagApi} from 'services/members'
import {getAllTagsApi} from 'services/workplace'
import { saveMemberTags } from 'store/actions/community'
import { Store } from 'store'


const MemberTags = ({userTags, setUserTags, memberId}) => {

    const {state, dispatch} = useContext(Store)
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('')


    useEffect(() => {
        if(state.community.tags){
            formatTags(state.community.tags)
        } else getAllTags();
    }, [state.community.tags])

    const getAllTags = async () => {
        try{

            const allTags = await getAllTagsApi();
            saveMemberTags(allTags, dispatch)

        } catch(err){
            console.log(err)
        }
    }

    const formatTags = (data) => {
        setTags(data.map(item => ({
            value: item._id, 
            label: item.tag,
            color: item.color
        })));
    }

    const createAndAddTag = async (label) => {
        try{
            const newTag_ = await addTagApi(memberId, label ? label : newTag);
            setNewTag('')
            if(newTag_){
                setUserTags([...userTags, {
                    value: newTag_._id, 
                    label: newTag_.tag,
                    color: newTag_.color
                }])
                const newTags = [...state.community.tags]
                const index = newTags.findIndex(item => item._id == newTag_._id)
                if(index < 0){
                    saveMemberTags([...newTags, newTag_], dispatch)
                }
            } 
            document.getElementById('outside-div').click()

        } catch(err){
            console.log(err)
        }
    }

    const clearTag = async (value) => {
        try{

            await deleteTagApi(memberId, value);
            const newUserTags = userTags.filter(item => item.value !== value);
            setUserTags(newUserTags)
             
        } catch(err){
            console.log(err)
        }
    }

    return (
      <div class='mb-3 row no-gutters'>
          <div class= 'col-auto mr-2'>
              <i class='tio-label mr-2'></i>
              Tags:
          </div>
          <div class='col-auto mr-2 h4'>
            <Tags 
                tags = {userTags} 
                isClearOption = {true}
                clearTag = {(value) => clearTag(value)}
            />
          </div>
          <div class='col-auto h4'>
                <AddTag 
                    newTag = {newTag}
                    setNewTag = {setNewTag}
                    createNew = {(label) => createAndAddTag(label)}
                    tags = {tags.filter(item => !userTags.map(item => item.value).includes(item.value))}
                />
          </div>
      </div>
    )
}




export default MemberTags;