import { PageHeader } from "components/Text";
import React, { useEffect, useState, useContext } from "react";
import MostActiveMembers from "./components/MostActiveMembers";
import ChartComponent from './components/ChartComponent'
import HealthAtGlance from "./components/HealthAtGlance";
import {channelEngagementData, getTimeOfActivityData, getRolesActivityData, getRolesAssignedData, formatReport} from './utilis/formatter'
import {sampleMetadata, sampleMetrics} from './utilis/sampleData'
import filtersJson, {initialFilters} from './utilis/filters.js'
import {getAllSourcesApi} from 'services/sources'
import useDidMount from 'hooks/useDidMount'
import Filters from './components/Filters'
import {
    fetchReportApi, 
    fetchEngagementReportApi, 
    fetchActivityTimeApi, 
    fetchChannelReportApi, 
    fetchResponsivenessApi,
    getReportApi,
    fetchRolesReportApi,
    fetchRolesAssignedReportApi
} from 'services/reports'
import { Store } from "store";
import { saveSources, saveDiscordRoles } from "store/actions/community";
import {getTopMembersApi} from 'services/members'
import { getDiscordRolesApi } from 'services/members/roles';
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";


const Report = (props) => {

    const history = useHistory()
    const {state, dispatch} = useContext(Store)
    const [metrics, setMetrics] = useState(sampleMetrics);
    const [metadata, setMetadata] = useState(sampleMetadata)
    const [topMembers, setTopMembers] = useState([])
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [filters, setFilters] = useState(filtersJson);
    const [isSavedReport, setIsSavedReport] = useState(false);
    const [savedReport, setSavedReport] = useState({})


    useEffect(() => {
        if(!state.user.data.workplaceId.primaryGuildId){
            toast.error("Please integrate your discord server first")
            window.setTimeout(() => {
                history.push('/app')
            }, 1000)
        }
    }, [])

    useEffect(() => {
        const id = props.match?.params?.id
        if(id && id.length > 4){
            fetchSavedReport(id)
        } else {
            setIsSavedReport(false)
            setAppliedFilters(initialFilters)
        }
    }, [props.match])


    useDidMount(() => {
        setMetrics(sampleMetrics)
        fetchReport()
    }, [appliedFilters])


    useEffect(() => {
        if(state.community.sources){
            formatSources(state.community.sources)
        } else getAllSources();
    }, [state.community.sources])




    const getAllSources = async () => {
        try{
            
            const allSources = await getAllSourcesApi();
            saveSources(allSources, dispatch)

        } catch(err){
            console.log(err)
        }
    }
   


    const formatSources = (data) => {
        try{
            const allFilters = [...filters]
            const index = allFilters.findIndex(item => item.value === 'source')
            allFilters[index].enums = data.map(item => ({value: item.integrationId, label: item.name}))
            setFilters(allFilters)
        } catch(err){
            console.log(err)
        }
    }

    const fetchReport = async () => {
        try{
            console.log("Fetching report")
           
            const urlParams = new URLSearchParams();
            let startDate, endDate
            if(appliedFilters.length > 0){
                appliedFilters.forEach(filter => {
                    if(filter.filter.length > 0){
                        urlParams.append(filter.value, filter.filter)
                    }
                    if(new Date(filter.filter.startDate).getTime() > 0){
                        startDate = filter.filter.startDate
                        endDate = filter.filter.endDate
                        urlParams.append(filter.value + 'StartDate', filter.filter.startDate)
                        urlParams.append(filter.value + 'EndDate', filter.filter.endDate)
                    }
                })
            }


            formatTrendsData(urlParams, startDate, endDate)
            formatChannelData(urlParams)
            formatActivityTimeData(urlParams)
            formatMembers()
            formatRolesData(urlParams)
            formatRolesAssignedData(urlParams)
           
        } catch (err) {
            console.log(err)
        }
    }

    const formatRolesData = async(urlParams) => {
        try{

            const roles = await fetchRolesReportApi(urlParams);
            console.log(roles)
            const data_ = getRolesActivityData(roles)
            console.log('data_ ', data_)
            setMetadata(prev => ({
                ...prev,
                roles: data_.metadata
            }))
            setMetrics(prev => ({
                ...prev,
                roles: data_.metrics
            }))

        } catch(err){
            console.log(err)
        }
    }
   
    const formatRolesAssignedData = async(urlParams) => {
        try{

            const roles = await fetchRolesAssignedReportApi(urlParams);
            console.log(roles)
            const data = getRolesAssignedData(roles)
            console.log(data)
            setMetadata(prev => ({
                ...prev,
                rolesAssigned: data.metadata
            }))
            setMetrics(prev => ({
                ...prev,
                rolesAssigned: data.metrics
            }))

        } catch(err){
            console.log(err)
        }
    }



    const formatMembers = async () => {
        try{
            const activeMembers = await getTopMembersApi()
            setTopMembers(activeMembers)

        } catch(err){
            console.log(err)
        }
    }


    const formatTrendsData = async(urlParams, startDate, endDate) => {
        try{

            const {tsData, totalCounts} = await fetchReportApi(urlParams);
            const responsiveness = await fetchResponsivenessApi(urlParams)
            const engagement = await fetchEngagementReportApi(urlParams);

            const data = formatReport({
                tsData,
                totalCounts,
                engagement,
                responsiveness
            }, startDate, endDate)
            setMetadata(prev => ({
                ...prev,
                ...data.metadata
            }))
            setMetrics(prev => ({
                ...prev,
                ...data.metrics
            }))

        } catch(err){
            console.log(err)
        }
    }


    const formatChannelData = async(urlParams) => {
        try{

            const channels = await fetchChannelReportApi(urlParams);
            const data = channelEngagementData(channels)
            setMetadata(prev => ({
                ...prev,
                channelWiseEngagement: data.metadata
            }))
            setMetrics(prev => ({
                ...prev,
                channelWiseEngagement: data.metrics
            }))

        } catch(err){
            console.log(err)
        }
    }


    const formatActivityTimeData = async (urlParams) => {
        try{

            const activitiesTime = await fetchActivityTimeApi(urlParams);
            const data = getTimeOfActivityData(activitiesTime)
            console.log(data)
            setMetadata(prev => ({
                ...prev,
                timeOfActivity: data.metadata
            }))
            setMetrics(prev => ({
                ...prev,
                timeOfActivity: data.metrics
            }))


        } catch(err){
            console.log(err)
        }
    }

    const fetchSavedReport = async (id) => {
        try{
            const report = await getReportApi(id)
            if(report){
                setAppliedFilters(report.filters)
                setIsSavedReport(true)
                setSavedReport(report)
            }
        } catch(err){
            console.log(err)
        }
    }

    return (
        <div>
            <PageHeader 
                title = {(isSavedReport && savedReport) ? 'Saved Report: ' + savedReport.name : "Report"}
                description={(isSavedReport && savedReport) ? savedReport.description : "Keep track of OKRs to measure community engagement & growth."}
            /> 
            <Filters 
                filters = {filters}
                isSavedReport = {isSavedReport}
                appliedFilters = {appliedFilters}
                setAppliedFilters = {setAppliedFilters}
            />
            <HealthAtGlance data = {metrics.healthMetrics}/>
            <ChartComponent 
                title = "Members"
                description = ""
                timeseriesData = {metrics.members}
                metadata = {metadata.members}
            />
            <ChartComponent 
                title = "Engagement"
                description = ""
                timeseriesData = {metrics.engagement}
                metadata = {metadata.engagement}
                type = 'line'
            />
            <ChartComponent 
                title = "Activity"
                description = ""
                timeseriesData = {metrics.activity}
                metadata = {metadata.activity}
            />
            <ChartComponent 
                title = "Sentiment"
                description = ""
                timeseriesData = {metrics.sentiment}
                metadata = {metadata.sentiment}
            />
            <ChartComponent 
                title = "Channel wise engagement"
                description = ""
                timeseriesData = {metrics.channelWiseEngagement}
                metadata = {metadata.channelWiseEngagement}
                type = 'bar'
            />
            <ChartComponent 
                title = "Responsivness"
                description = ""
                timeseriesData = {metrics.responsiveness}
                metadata = {metadata.responsiveness}
                type = 'bar'
            />
            <ChartComponent 
                title = "Number of messages per role"
                description = ""
                timeseriesData = {metrics.roles}
                metadata = {metadata.roles}
                type = 'bar'
            />
            <ChartComponent 
                title = "Number of members assigned to roles"
                tooltip = "represents number of members per role as of the current date"
                timeseriesData = {metrics.rolesAssigned}
                metadata = {metadata.rolesAssigned}
                type = 'bar'
            />
            <ChartComponent 
                title = "Time of activity"
                description = ""
                timeseriesData = {metrics.timeOfActivity}
                metadata = {metadata.timeOfActivity}
                type = 'bar'
            />
            <MostActiveMembers data = {topMembers}/>
            
        </div>
    )
}

export default Report







