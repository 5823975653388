import React, { useEffect, useState } from 'react'
import Select from 'components/Forms/Select2'
import { PunctualityDateRangePicker } from 'components/Forms/Datepicker'
import moment from 'moment'

const Filters = ({position, position2, clearable, disable, filters, appliedFilters, setAppliedFilters, colSize, filterButtonPosition}) => {

    const [showDropdown, setShowDropdown] = useState();

    return (
        
        <div class = {`row no-gutters align-items-center justify-content-${position}`}>
            <div class={`col px-1`}>
                <div class={`row no-gutters align-items-center justify-content-${position2} mb-n2`}>
                    <div class ='col-auto px-1 h2 text-muted mb-2'>
                        <i class = 'tio-filter-outlined'></i>
                    </div>
                    {[...appliedFilters].map(item => <FilterButton 
                        header = {filters.filter(item2 => item2.value === item.value)[0]}
                        filter = {item.filter}
                        setAppliedFilters = {setAppliedFilters}
                        appliedFilters = {appliedFilters}
                        showDropdown = {showDropdown}
                        setShowDropdown = {setShowDropdown}
                        disable = {disable}
                    />)}
                </div>
            </div>
            {!disable && <AddFilterButton 
                headers = {filters}
                appliedFilters = {appliedFilters}
                setAppliedFilters = {setAppliedFilters}
                setShowDropdown = {setShowDropdown}
                filterButtonPosition = {filterButtonPosition}
                clearable=  {clearable}
            />}
        </div>
  )
}

export default Filters



const AddFilterButton = ({headers, appliedFilters, setAppliedFilters, clearable, setShowDropdown, filterButtonPosition}) => {

    const [selectedHeader, setSelectedHeader] = useState({})
    
    const addFilter = (value) => {
        const newAppliedFilters = [...appliedFilters]
        const type = headers.filter(item => item.value === value)[0].type
        newAppliedFilters.push({value: value, filter: type === "DATE" ? dateFilterNull : []})
        setAppliedFilters(newAppliedFilters)
        setShowDropdown(value)
    }

    const resetFilters = () => {
        setAppliedFilters(headers.filter(item => item.default).map(item => ({
            value: item.value, 
            filter: item.type === 'DATE' ? dateFilterNull : item.isMulti ? [] : ''
        })))
    }

    return (
        <div class ='col-auto text-right px-1 d-flex justify-content-end'>
            <Select 
                options = {headers.map(item => ({
                    value: item.value,
                    label: item.label,
                    disabled: appliedFilters.map(item2 => item2.value).includes(item.value) ? true : false,
                    icon: item.icon,
                }))}
                position = {filterButtonPosition}
                value = {selectedHeader}
                setValue = {(values) => addFilter(values)}
            >
                <div class='btn btn-sm btn-outline-dark-2'>
                    <i class='tio-add-circle'></i> Add Filter
                </div>
            </Select>
            {(appliedFilters.filter(item => {
                if(item.filter.length > 0) return true;
                if(new Date(item?.filter?.startDate).getTime() > 0) return true;
                else return false;
            }).length > 0 && clearable) && <span class='btn btn-icon btn-sm btn-soft-danger ml-2 mr-1' onClick={() => resetFilters()}>
                <i class="tio-replay"></i>
            </span>}
        </div>

    )
}




const FilterButton = (props) => {
    
    return (
        <div class='col-auto px-1 mb-2'>
            {(props.header && props.header.enums) && <SelectFilter {...props} />}
            {(props.header && props.header.type === 'DATE') && <DateFilter {...props} />}
        </div>
    )
}



const SelectFilter = ({header, filter, setAppliedFilters, appliedFilters, showDropdown, setShowDropdown, disable, isMulti}) => {
    
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(showDropdown && showDropdown !== '') {
            if(showDropdown === header.value) setShow(true);
            setShowDropdown('');
        } else {
            setShow(false);
        }
    }, [showDropdown])
    
    const applyFilter = (value) => {
        const index = appliedFilters.findIndex(item => item.value === header.value);
        const newAppliedFilters = [...appliedFilters]
        newAppliedFilters[index].filter = value
        setAppliedFilters(newAppliedFilters)
    }

    return (
        <Select 
            options = {header.enums}
            value = {filter}
            setValue = {(values) => applyFilter(values)}
            show = {show}
            setShow = {setShow}
            isMulti = {header.isMulti}
            disable = {disable}
        >
            <div class=" btn btn-sm btn-outline-dark-2">
                <i class={`tio-${header.icon} mr-2`}></i> 
                {header.label} {filter.length > 0 && <>:<span class='badge badge-pill badge-success ml-1 mr-1'>{header.isMulti ? filter.length : '1'}</span></>}
                <i class='tio-clear text-secondary ml-1' onClick = {(e) => {
                    e.stopPropagation()
                    setAppliedFilters(appliedFilters.filter(item => item.value !== header.value))
                }}></i>
            </div>
        </Select>
    )
}




const DateFilter = ({header, filter, setAppliedFilters, appliedFilters, disable, showDropdown, setShowDropdown}) => {

    const [show, setShow] = useState(false);

    useEffect(() => {
        if(showDropdown && showDropdown !== '') {
            if(showDropdown === header.value) setShow(true);
            setShowDropdown('');
        } else {
            setShow(false);
        }
    }, [showDropdown])
    
    const applyFilter = (value) => {
        if(disable) return;
        const index = appliedFilters.findIndex(item => item.value === header.value);
        const newAppliedFilters = [...appliedFilters]
        newAppliedFilters[index].filter = value
        setAppliedFilters(newAppliedFilters)
    }


    return(
        <PunctualityDateRangePicker 
            value = {filter}
            onChange = {(value) => applyFilter(value)} 
            label = {
                <div class=" btn btn-sm btn-outline-dark-2">
                    <i class={`tio-${header.icon} mr-2`}></i> 
                    {header.label} {new Date(filter.startDate).getTime() > 110 && <>:<span class='badge badge-pill badge-success ml-1 mr-1'>
                        {Math.floor((new Date(filter.endDate).getTime() - new Date(filter.startDate).getTime())/(60*60*24*1000)) + 1} days
                    </span></>}
                    <i class='tio-clear text-secondary ml-1' onClick = {(e) => {
                        e.stopPropagation()
                        setAppliedFilters(appliedFilters.filter(item => item.value !== header.value))
                    }}></i>
                </div>
            }
            show = {show}
            disable = {disable}
            setShow = {setShow}
            minDate = {new Date(moment().subtract(3, 'month').format('YYYY-MM-DD'))} 
            maxDate = {new Date()} 
        />
    )
}


const dateFilterNull = {
    startDate: new Date(null), 
    endDate:new Date(null)
}