
export const UserRoles = {
    MEMBER: 'MEMBER',
    PUBLISHER: 'PUBLISHER',
    GUEST: 'GUEST',
}

export const EVENT_NAMES = {
    SOURCE_CREATE: 'DISCORD::SOURCE::CREATE',
    SOURCE_UPDATE: 'DISCORD::SOURCE::UPDATE',
    SOURCE_DELETE: 'DISCORD::SOURCE::DELETE',
    
    CHANNEL_CREATE: 'DISCORD::CHANNEL::CREATE',
    CHANNEL_UPDATE: 'DISCORD::CHANNEL::UPDATE',
    CHANNEL_DELETE: 'DISCORD::CHANNEL::DELETE',
    
    MESSAGE_CREATE: 'DISCORD::MESSAGE::CREATE',
    MESSAGE_UPDATE: 'DISCORD::MESSAGE::UPDATE',
    MESSAGE_DELETE: 'DISCORD::MESSAGE::DELETE',

    MEMBER_CREATE: 'DISCORD::MEMBER::CREATE',
    MEMBER_UPDATE: 'DISCORD::MEMBER::UPDATE',
    MEMBER_DELETE: 'DISCORD::MEMBER::DELETE',
}

export const ERROR_TYPES = {
    INCORRECT_EMAIL: 'INCORRECT_EMAIL',
    INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
    USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
    NO_CODE_PROVIDED: 'NO_CODE_PROVIDED',
    INVALID_TOKEN: 'INVALID_TOKEN',
    INVALID_HASH: 'INVALID_HASH',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS',
    INVALID_CODE: 'INVALID_CODE'

}