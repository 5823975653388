import { PageHeader } from 'components/Text'
import React, {useState, useContext, useEffect} from 'react'
import { getTokensApi } from 'services/tokens';
import ImportToken from './components/ImportToken'
import {Table} from 'components/Table';
import moment from 'moment'
import { Store } from 'store';
import { updateTokens } from 'store/actions/workplace';


const Tokens = () => {

    const {state, dispatch} = useContext(Store);
    const [showImport, setShowImport] = useState(false);
    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        if(state.workplace.tokens){
            formatTokens(state.workplace.tokens)
        } else {
            fetchTokens();
        }
    }, [state.workplace.tokens])

    const fetchTokens = async () => {
        try{

            const _tokens = await getTokensApi();
            updateTokens(_tokens, dispatch)

        } catch(err){
            console.log(err)
        }
    }
  
  
    const formatTokens = async (tokens_) => {
        try{

            setTokens(tokens_)

        } catch(err){
            console.log(err)
        }
    }


    return (
        <div class='row justify-content-center'>
            <div class='col-lg-10'>
                <PageHeader 
                    title = "Your Token"
                    description="Import tokens you wish to see in Connected Wallets Member table. You can only add ERC20 tokens as your organization tokens (not NFTs) as of now."
                    button = {<div class='btn btn-primary' onClick={() => setShowImport(true)}>Import Token</div>}
                />
        
                <div class='row row-cols-1'>
                    {tokens.map(item => <TokenItem {...item}/>)}
                </div>

                <ImportToken 
                    show = {showImport}
                    setShow = {setShowImport}
                />
            </div>
        </div>
    )
}

export default Tokens




const TokenItem = ({address, createdAt, icon, decimals, symbol}) => {
    return (
        <div class="col mb-3 mb-lg-5">
            <div class="card card-body">
                <div class="media">
                    <div class="avatar avatar-md avatar-primary avatar-circle mr-3">
                        <span class='avatar-initials'>{symbol[0].toUpperCase()}</span>
                    </div>
                    <div class="media-body">
                        <div class="row align-items-sm-center">
                            <div class="col">
                                <h3 class="mb-1">{symbol}</h3>
                            </div>
                            <div class="col-md-3 d-none d-md-flex justify-content-md-end ml-n3">
                            <span class="badge font-size-xs badge-soft-primary p-2 mb-2">{address}</span>
                            </div>
                        </div>
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <span class="font-size-sm">Added on: </span>
                                <span class="font-weight-bold text-dark">{moment(createdAt).format('Do MMM YY')}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}