import React, { useEffect } from 'react'
import StickyNav from 'components/Layout/StickyNav'
import { PageHeader } from 'components/Text'
import BasicInformation from './Components/BasicInformation'
import Tags from './Components/Tags'
import Security from './Components/Security'
import WorkplaceSettings from './Components/WorkplaceSettings'
import TeamSettings from './Components/Team'


const Settings = (props) => {



    return (
        <>
            <PageHeader title = "Your Account"/>
            <StickyNav navMap = {navMap}>
                <WorkplaceSettings />
                <TeamSettings />
                <BasicInformation />
                <Tags />
                <Security />
            </StickyNav>
        </>
    )
}

export default Settings



const navMap = [{
    id: 'account-workplace',
    label: 'Your Community',
    icon: 'tio-company'
}, {
    id: 'account-team',
    label: 'Team',
    icon: 'tio-group-equal'
},{
    id: 'account-basic',
    label: 'Your Information',
    icon: 'tio-user-outlined'
},{
    id: 'account-tags',
    label: 'Tags',
    icon: 'tio-label'
}, {
    id: 'account-security',
    label: 'Security',
    icon: 'tio-password'
}]

