export const TRACKING_EVENTS = {
    'SIGNED_UP::ONBOARDING': 'SIGNED_UP::ONBOARDING',
    'BASIC_DETAILS::ONBOARDING': 'BASIC_DETAILS::ONBOARDING',
    'ADDED_DISCORD::ONBOARDING': 'ADDED_DISCORD::ONBOARDING',
    'COMFIRM_EMAIL::ONBOARDING': 'COMFIRM_EMAIL::ONBOARDING',
    'SUCCESS::ONBOARDING': 'SUCCESS::ONBOARDING',
    'PAGE_VIEW::DASHBOARD': 'PAGE_VIEW::DASHBOARD',
    'LOG_IN::DASHBOARD': 'LOG_IN::DASHBOARD',
    'SEARCHED::DASHBOARD': 'SEARCHED::DASHBOARD',
    'CONNECTED_WALLET::DASHBOARD': 'CONNECTED_WALLET::DASHBOARD',
    'SWITCHED_THEME::DASHBOARD': 'SWITCHED_THEME::DASHBOARD',
    'FILTERED::MEMBERS': 'FILTERED::MEMBERS',
    'SEARCHED::MEMBERS': 'SEARCHED::MEMBERS',
    'SORTED::MEMBERS': 'SORTED::MEMBERS',
    'EXPORTED::MEMBERS': 'EXPORTED::MEMBERS',
    'APPLIED_TAG::MEMBERS': 'APPLIED_TAG::MEMBERS',
    'APPLIED_ROLE::MEMBERS': 'APPLIED_ROLE::MEMBERS',
    'PAGE_CHANGE::MEMBERS': 'PAGE_CHANGE::MEMBERS',
    'TOGGLE_ACTIVE::ID_CONNECT': 'TOGGLE_ACTIVE::ID_CONNECT',
    'EDITED::ID_CONNECT': 'EDITED::ID_CONNECT',
    'SUBMISSIONS::ID_CONNECT': 'SUBMISSIONS::ID_CONNECT',
    'DISCORD_ANNOUNCEMENT::ID_CONNECT': 'DISCORD_ANNOUNCEMENT::ID_CONNECT',
    'VIEWED::SEGMENTS': 'VIEWED::SEGMENTS',
    'DELETED::SEGMENTS': 'DELETED::SEGMENTS',
    'EXPORTED::SEGMENTS': 'EXPORTED::SEGMENTS',
    'APPLIED_ROLE::SEGMENTS': 'APPLIED_ROLE::SEGMENTS',
    'FILTERED::REPORT': 'FILTERED::REPORT',
    'SAVED::REPORT': 'SAVED::REPORT',
    'UPLOADED_WALLET::DATA_SOURCES': 'UPLOADED_WALLET::DATA_SOURCES',
    'ADDED_TOKEN::SETTINGS': 'ADDED_TOKEN::SETTINGS',
    'EDITED_BASIC::SETTINGS': 'EDITED_BASIC::SETTINGS',
    'ADDED_TEAM::SETTINGS': 'ADDED_TEAM::SETTINGS',
    'REMOVED_TEAM::SETTINGS': 'REMOVED_TEAM::SETTINGS',
    'EDITED_TAG::SETTINGS': 'EDITED_TAG::SETTINGS',
    'EDITED_PASSWORD::SETTINGS': 'EDITED_PASSWORD::SETTINGS',
}

