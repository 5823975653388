import React, {useEffect, useState} from 'react'
import {CheckBox} from 'components/Forms/Checkbox'
import {HrefButton, Button} from 'components/Forms/Buttons'
import {InputField, PasswordField} from 'components/Forms/TextInput'
import {resetPassword} from 'services/user/auth'
import toast from 'react-hot-toast'
import { ERROR_TYPES } from 'config/constants'
import {useHistory} from 'react-router-dom'

const Form = () => {

    const history = useHistory()
    const [values, setValues] = useState({password: '', password1: ''});
    const [errors, setErrors] = useState({password: false, password1: false})
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors({password: false, password1: false})
    }, [values])
    
    const submitResponse = async () => {
        try{
            if(!validateData()) return;
            setLoading(true);
            const hash= new URL(window.location.href).searchParams.get('hash')
            const response = await resetPassword({
                password: values.password,
                hash: hash
            });
            toast.success("Your password has been updated!!")
            setValues({password: '', password1: ''})
            setTimeout(() => {
                history.push('/')
            }, 1000)
            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err.response.data)
            if(err.response && err.response.data && err.response.data.error === ERROR_TYPES.INVALID_HASH){
                toast.error("This link is now invalid")
                setTimeout(() => {
                    history.push('/')
                }, 1000)
            }
            console.log(err);
        }
    }


    const validateData = () => {
        const new_errors = {password: false, password1: false}
        if(values.password.length < 8) new_errors.password = true;
        if(values.password !== values.password1) new_errors.password1 = true;
        setErrors(new_errors)
        if(Object.values(new_errors).includes(true)) return false;
        else return true;
    }


    const editValues = (name) => (value) => {
        setValues(prev => ({...prev, [name]: value }));
    };

    const editErrors = (name) => (value) => {
        setErrors(prev => ({...prev, [name]: value }));
    };


    return(
        <form>
            <div class="text-center mb-5">
                <h1 class="display-4">Reset your password</h1>
            </div>
            <PasswordField 
                name = "password"
                value = {values.password}
                error = {errors.password}
                setValue = {editValues('password')}
                setErrors = {editErrors('password')}
                label = "Your new password"
                errorText = "Password must be at least 8 characters long"
                size = "lg"
            />
            <PasswordField 
                name = "password"
                value = {values.password1}
                error = {errors.password1}
                setValue = {editValues('password1')}
                setErrors = {editErrors('password1')}
                label = "Confirm password"
                errorText = "Password dont match"
                size = "lg"
            />
            <Button 
                text = "Update your password"
                loadingText = "Updating..."
                className = "btn-lg btn-block"
                submitResponse = {submitResponse}
                loading = {loading}
                size = "lg"
                block = {true}
                color = "primary"
            /> 
      </form>
    )
}

export default Form
