import axiosInstance from '../axios';


export const resendInvitation = async (invitationId) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/workplace/team/resend-invite', {invitationId})
        if(success) return data
        else throw new Error(data)

    }catch(err){
        console.log(err)
        throw err
    }
}




export const inviteUser = async ({email, firstName, lastName, role}) => {
    try{

        const res = await axiosInstance.post('/workplace/team/invite-member', {
            email: email.toLowerCase().trim(), 
            firstName, 
            lastName, 
            role
        })
        if(res.data.success) return res.data.data
        else throw new Error(res.data.error)

    }catch(err){
        console.log(err)
        throw err
    }
}





export const cancelInvitation = async (invitationId) => {
    try{

        const {data: {success, data}} = await axiosInstance.post('/workplace/team/cancel-invite', {invitationId})
        if(success) return data
        else throw new Error(data)


    }catch(err){
        console.log(err)
        throw err
    }
}


