import React from 'react';
import combineReducers from 'react-combine-reducers';
import authReducer from './reducers/auth'
import workplaceReducer from './reducers/workplace';
import communityReducer from './reducers/community';
import {authState, workplaceState, communityState} from './state'



const [rootReducerCombined, initialStateCombined] = combineReducers({ 
    user: [authReducer, authState], 
    workplace: [workplaceReducer, workplaceState], 
    community: [communityReducer, communityState], 
})


export const Store = React.createContext("");

export const StoreProvider = (props) => {
    
    const [state, dispatch] = React.useReducer(rootReducerCombined, initialStateCombined);
    const value = { state, dispatch };
    return <Store.Provider value={value}>{props.children}</Store.Provider>;
}





