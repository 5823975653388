import React from 'react'
import { SimpleModal } from 'components/Models/Modal';
import { hideMemberApi } from 'services/members'
import toaster from 'react-hot-toast'

const Hide = ({show, setShow, members, setMembers, selected, setSelected, filterMembers}) => {

    const hideMember = async () => {
        try{

            const res = await hideMemberApi(selected);
            toaster.success("We have hidden these members for you")
            const newMembers = members.filter(item => {
                if(selected.includes(item._id)){
                    return false
                } return true;
            })
            setMembers(newMembers)
            setSelected([])
            setShow(false)
            filterMembers()

        } catch(err){
            console.log(err)
        }
    }


    return (
        <SimpleModal 
            show = {show} 
            setShow = {setShow}
            title = {<>Are you sure you want to hide members?</>}
            size = "sm"
        >
            <div className='mt-n4 mb-2'>These members and their activities will no longer show up in your Intract.</div>
            <div class='row justify-content-end mt-2 pt-3 '>
                <div class='col-auto'>
                    <div class='btn btn-sm btn-light mr-2' onClick={() => setShow(false)}>Cancel</div>
                    <div class='btn btn-sm btn-primary' onClick={() => hideMember()}>Hide</div>
                </div>
            </div>
            

        </SimpleModal>
    )
}

export default Hide