import React, {useState, useContext} from 'react'
import { SimpleModal } from 'components/Models/Modal';
import { AddTag } from 'components/Others/Tags';
import {addMultipleTagApi} from 'services/members'
import toaster from 'react-hot-toast'
import {saveMemberTags} from 'store/actions/community'
import { Store } from 'store'


const AddTags = ({show, setShow, selected, setSelected, allTags, members, setMembers}) => {

    const {state, dispatch} = useContext(Store)
    const [newTag, setNewTag] = useState('');

    const createAndAddTag = async (label, color) => {
        try{
            const newMembers = [...members];
            for (let i = 0; i < selected.length; i++) {
                const index = newMembers.findIndex(member => member._id === selected[i]);
                if(index == -1) continue;
                if(!newMembers[index]) continue;
                let allTags_ = [{label: label ? label : newTag, value: label ? label : newTag, color: color ? color : 'primary'}];
                if(newMembers[index]?.metadata?.tags){
                    allTags_ = [...newMembers[index].metadata.tags.map(item => ({value: item.label, label: item.label, color: item.color})), ...allTags_];
                }

                newMembers[index].tags = {
                    label: <div style = {{maxWidth: '140px'}}>
                        {allTags_.map(item => <span class={`badge badge-soft-${item.color} mr-2`}>
                            {item.label}
                        </span>)}
                    </div>,
                    value: allTags_.map(item => item.label).join(', '),
                }
                newMembers[index].metadata = {
                    ...newMembers[index].metadata,
                    tags: allTags_
                }
            }

            setMembers(newMembers)
            let newTag_ = await addMultipleTagApi(selected, label ? label : newTag);
            toaster.success("Tags successfully added")
            setShow(false)
            setSelected([])

            const newTags = [...state.community.tags]
            const index = newTags.findIndex(item => item._id == newTag_._id)
            if(index < 0){
                saveMemberTags([...newTags, newTag_], dispatch)
            }

        } catch(err){
            toaster.error("Some error occured, please refresh and try again")
            console.log(err)
        }
    }
 
    return (
        <SimpleModal 
            show = {show} 
            setShow = {setShow}
            title = "Tag your members"
            size = "sm"
        >
            <div className='mt-n4 mb-2'>
                
            </div>
            <h2>
                <AddTag 
                    newTag = {newTag} 
                    setNewTag = {setNewTag} 
                    createNew = {(label, color) => createAndAddTag(label, color)} 
                    tags =  {allTags}
                />
            </h2>
            

        </SimpleModal>
    )
}

export default AddTags