import React, {useEffect, useState, useRef, useContext} from 'react'
import useDidMountEffect from 'hooks/useDidMount'
import Avatar from 'components/Avatar'
import PaginatedTable from 'components/Table/PaginatedTable'
import Filters from 'components/Filters'
import {discordFiltersJson} from './utilis/filters'
import moment from 'moment'
import { getMembersApi, getTotalMembersApi, downloadMembersApi } from 'services/members'
import { getAllTagsApi } from 'services/workplace'
import { getAllSourcesApi } from 'services/sources'
import { PageHeader } from 'components/Text'
import HideModal from './components/Hide'
import AssignRoleModal from './components/AssignRole'
import TagsModal from './components/Tags'
import Papa from 'papaparse'
import {getAvatar} from 'utilis/avatars'
import toaster from 'react-hot-toast'
import {Store} from 'store'
import { saveDiscordRoles, saveMemberTags, saveSources } from 'store/actions/community'
import { getDiscordRolesApi } from 'services/members/roles'
import { addOpacity, VBColorToHEX } from 'utilis/helpers'

const DiscordMembers = (props) => {

    const {state, dispatch} = useContext(Store)
    const [fetchedMembers, setFetchedMembers] = useState([])
    const [members, setMembers] = useState();
    const [showAddTags, setShowAddTags] = useState(false);
    const [showHide, setShowHide] = useState(false);
    const [showAssignRole, setShowAssignRole] = useState(false);
    
    const [filters, setFilters] = useState(discordFiltersJson)
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [selected, setSelected] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [sortBy, setSortBy] = useState({key: 'joinedDate', asc: true});
    const [totalPages, setTotalPages] = useState(4);
    const [searchInput, setSearchInput] = useState('');
    const rowsPerPage = 10;
    const prevFilterCount = useRef()

    const [allTags, setAllTags] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        if(props && props.location && props.location.search){
            const search = new URLSearchParams(props.location.search).get("search")
            if(search && search.length > 0) {
                setSearchInput(search)
            } 
        }
        setAppliedFilters(discordFiltersJson.filter(item => item.default).map(item => ({
            value: item.value, 
            filter: item.defaultValues ? item.defaultValues : (item.type === 'DATE' ? dateFilterNull : item.isMulti ? [] : '')
        })))
    }, [props.location.search])

  

    useEffect(() => {
        if(state.community.tags){
            formatTags(state.community.tags)
        } else getAllTags();
    }, [state.community.tags])
    
    useEffect(() => {
        if(state.community.sources){
            formatSources(state.community.sources)
        } else getAllSources();
    }, [state.community.sources])

     useEffect(() => {
        if(state.community.discordRoles){
            formatRoles(state.community.discordRoles)
        } else getAllRoles();
    }, [state.community.discordRoles])


    useEffect(() => {
        fetchTotalMembers()
    }, [searchInput, appliedFilters])
  
    
    useEffect(() => {
        filterMembers()
    }, [currentPage])

    useDidMountEffect(() => {
        if(currentPage === 1) filterMembers()
        else setCurrentPage(1)
        // filterMembers()
    }, [searchInput])
    
    useDidMountEffect(() => {
        let prevFiltersCount = prevFilterCount.current ? (prevFilterCount.current) : 0
        let currentFilterCount = getFilterCount(appliedFilters)
        if(currentFilterCount === prevFiltersCount){
            return;
        }
        if(currentPage === 1) filterMembers()
        else setCurrentPage(1)
        prevFilterCount.current = getFilterCount(appliedFilters)
    }, [appliedFilters])
    
    useDidMountEffect(() => {
        if(currentPage === 1) filterMembers()
        else setCurrentPage(1)
    }, [sortBy])



    const getAllTags = async () => {
        try{
            const allTags_ = await getAllTagsApi();
            saveMemberTags(allTags_, dispatch)
        } catch(err){
            console.log(err)
        }
    }

     
    const getAllSources = async () => {
        try{
            const allSources_ = await getAllSourcesApi();
            saveSources(allSources_, dispatch)
        } catch(err){
            console.log(err)
        }
    }


    const getAllRoles = async () =>{
        try{
            const roles_ = await getDiscordRolesApi();
            saveDiscordRoles(roles_, dispatch)
            return roles_

        } catch(err){
            console.log(err)
        }
    }


    const formatRoles = (roles_) => {
        try{
            const allFilters = [...filters]
            setRoles(roles_.map(item => ({value: item.roleId, label: item.name, color: item.color, sourceId: item.sourceId})))
            const index = allFilters.findIndex(item => item.value === 'discordRoles')
            allFilters[index].enums = roles_.map(item => ({value: item.roleId, label: item.name, color: item.color, sourceId: item.sourceId}))
            setFilters(allFilters)

        } catch(err){
            console.log(err)
        }
    }
   

    const formatTags = (tags_) => {
        try{

            const allFilters = [...filters]
            setAllTags(tags_.map(item => ({value: item.tag, label: item.tag, color: item.color})))
            const index = allFilters.findIndex(item => item.value === 'tags')
            allFilters[index].enums = tags_.map(item => ({value: item.tag, label: item.tag, color: item.color}))
            setFilters(allFilters)

        } catch(err){
            console.log(err)
        }
    }
   
    const formatSources = (sources_) => {
        try{
            const allFilters = [...filters]
            const index = allFilters.findIndex(item => item.value === 'source')
            allFilters[index].enums = sources_.map(item => ({value: item.integrationId, label: item.name}))
            setFilters(allFilters)
        } catch(err){
            console.log(err)
        }
    }
    
    const filterMembers = async () => {
        await fetchMembers(getUrlParams())
    }

    const getUrlParams = () => {
        const urlParams = new URLSearchParams();
        urlParams.append('sourceType', 'DISCORD')
        urlParams.append('page', currentPage)
        urlParams.append('limit', rowsPerPage)
        urlParams.append('search', searchInput)
        if(sortBy && sortBy.key){
            urlParams.append('sortBy', getSortKey(sortBy.key))
            urlParams.append('sortDirection', sortBy.asc)
        }
        if(appliedFilters.length > 0){
            appliedFilters.forEach(filter => {
                if(filter.filter.length > 0){
                    urlParams.append(filter.value, filter.filter)
                    if(filter.value === 'level'){
                        urlParams.delete('sortBy')
                        urlParams.delete('sortDirection')
                        urlParams.append('sortBy', getSortKey('activityCount'))
                        urlParams.append('sortDirection', true)
                    }
                }
                if(new Date(filter.filter.startDate).getTime() > 0){
                    urlParams.append(filter.value + 'StartDate', filter.filter.startDate)
                    urlParams.append(filter.value + 'EndDate', filter.filter.endDate)
                }
            })
        }
        return urlParams
    }


    const fetchTotalMembers = async () => {
        try{
            let urlParams = getUrlParams()
            const totalMembers_ = await getTotalMembersApi(urlParams);
            setTotalPages(Math.ceil(totalMembers_ / rowsPerPage))

        } catch(err){
            console.log(err)
        }
    }


    const fetchMembers = async (urlParams) => {
        try {
            setMembers()
            const fetchedMembers = await getMembersApi(urlParams);
            const data_ = fetchedMembers.docs.map(item => ({
                ...item.discordMemberProfiles[0],
                _id: item._id,
                memberId: item.discordMemberProfiles[0].memberId,
                adminInputs: item.adminInputs,
                metadata: item.metadata,
                isWeb3Connected: item.sources.find(item => item.type === 'WEB3') ? true : false
            }))
            setFetchedMembers(data_)
            await formatMembers(data_)
        } catch (error) {
            console.log(error)
        }
    }
    
    const formatMembers = async (fetchedMembers) => {
        let roles_ = state.community.discordRoles
        if(!roles_){
            roles_ = await getAllRoles()
        }   

        setMembers(fetchedMembers.map(item => ({
            ...item,
            username: {
                label: <Avatar 
                    name = {item.username}
                    avatar = {item.metadata.avatar.includes(null) ? '' : item.metadata.avatar}
                    link = {`/app/member/${item._id}`}
                />,
                value: item.username,
            },
            tags: {
                label: (item.adminInputs && item.adminInputs.tags && item.adminInputs.tags.length > 0) ? <div style = {{maxWidth: '140px',  'whiteSpace': 'pre-line'}}>
                    {item.adminInputs.tags.map(item2 => <span class={`badge badge-soft-${item2.color ? item2.color : 'primary'} mr-2`}>
                        {item2.label}
                    </span>)}
                </div> : <></>,
                value: (item.adminInputs && item.adminInputs.tags && item.adminInputs.tags.length > 0) ? item.adminInputs.tags.map(item => item.label).join(', ') : '',
            },
            lastActivity: moment(item.updatedAt).diff(item.createdAt, 'h') === 0 ? '' : moment(item.updatedAt).fromNow(),
            activityCount: {
                label: <>
                <i class="tio-chat-outlined mr-2"></i>{item.metrics.messages}</>,
                value: item.metrics.messages
            },
            joinedDate: item.joinedAt ? moment(item.joinedAt).format('Do MMM YY') : 'na',
            walletConnected: (item.isWeb3Connected) ? <div class='badge badge-soft-success'><i class='tio-ethereum-outlined mr-1'></i>Connected</div>: '',
            roleIds: item.roles,
            roles: (item.roles) ? <div style = {{maxWidth: '130px', 'whiteSpace': 'pre-line'}}>{item.roles.slice(0, 3).map(item2 => {
                const role_ = roles_?.find(item3 => item3.roleId === item2.roleId);
                if(role_){
                    return ( 
                        <span class={`badge mr-2`} style = {{color: VBColorToHEX(role_.color), backgroundColor: addOpacity(VBColorToHEX(role_.color), 0.1)}}>
                            {role_.name}
                        </span>)
                }
            })}{
                item.roles.length > 3 ? <span class={`badge badge-soft-primary mr-2`}>+{item.roles.length - 3}</span> : <></>
            }</div> : ''
        })))
    }


    const exportMembers = (selected) => {
        try{

            const dataToBeDownloaded = members.filter(item => selected.includes(item._id)).map(item => ({
                'Username': item.username.value,
                'Organization Name': item.adminInputs?.organization?.name,
                'Tags': item.tags.value,
                'Activity Count': item.activityCount.value,
                'Joined Date': item.joinedDate,
            }))
            const csv = Papa.unparse(dataToBeDownloaded)
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'members.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch(err){
            console.log(err)
        }
    }

    const downloadData = async (type) => {
        try{
            const params = getUrlParams()
            
            const res = await downloadMembersApi(params)
            toaster.success("We have emailed you a copy of your members data")

        } catch(err){
            console.log(err)
        }
    }

    const selectedOptions = [
    {
        label: 'Assign Role',
        function: () => setShowAssignRole(true)
    }, 
    {
        label: 'Tag',
        function: () => setShowAddTags(true)
    }, {
        label: 'Export',
        function: (selected) => exportMembers(selected)
    }]
    
    return (
        <div>
            <PageHeader 
                title = "👨‍👩‍👧‍👦 Your Discord Members"
                description="View all community members imported from your Discord server here."
            />
            <div class='card mt-4'>
                <PaginatedTable 
                    headers = {headers} 
                    data = {members}
                    rowSelection = {true}
                    searchPlaceholder = "Search by username, nickname or discrimator"
                    headerButtons = {
                        <Filters 
                            position="end"
                            position2="end"
                            filters = {filters}
                            appliedFilters = {appliedFilters}
                            setAppliedFilters = {setAppliedFilters}
                            clearable
                        />
                    }
                    selectionOptions = {selectedOptions}
                    rowsPerPage = {rowsPerPage}
                    currentPage = {currentPage} 
                    setCurrentPage = {setCurrentPage}
                    sortBy = {sortBy} 
                    setSortBy = {setSortBy}
                    totalPages = {totalPages} 
                    setTotalPages = {setTotalPages}
                    searchInput = {searchInput} 
                    setSearchInput = {setSearchInput}
                    selected = {selected}
                    setSelected = {setSelected}
                    downloadData = {downloadData}
                    loadingOnEmpty = {true}
                    downloadable
                />
            </div>
            <HideModal 
                show = {showHide}
                setShow = {setShowHide}
                members = {members}
                setMembers = {setMembers}
                selected = {selected}
                setSelected = {setSelected}
                filterMembers = {filterMembers}
            />
            <AssignRoleModal 
                show = {showAssignRole}
                setShow = {setShowAssignRole}
                members = {members}
                setMembers = {setMembers}
                selected = {selected}
                setSelected = {setSelected}
            />
            <TagsModal 
                show = {showAddTags}
                setShow = {setShowAddTags}
                members = {members}
                setMembers = {setMembers}
                selected = {selected}
                setSelected = {setSelected}
                allTags = {allTags}
            />
        </div>
    )
}

export default DiscordMembers





const headers = [{
    value: 'username',
    label: 'Name'
}, {
    value: 'tags',
    label: 'tags'
},  {
    value: 'roles',
    label: 'Discord roles'
}, {
    value: 'activityCount',
    label: 'Activity Level'
}, {
    value: 'joinedDate',
    label: 'Joined On',
    tooltip: 'na value for deleted members'
}, {
    value: 'walletConnected',
    label: 'Wallet Connected'
}]









const getSortKey = (value) => {
    switch(value){
        case 'username':
            return 'metadata.username'
        case 'tags':
            return 'adminInputs.tags'
        case 'roles':
            return 'sources.discordRoles'
        case 'lastActivity':
            return 'updatedAt'
        case 'activityCount':
            return 'sources.numMessages'
        case 'joinedDate':
            return 'sources.joinedAt'
        case 'walletConnected':
            return 'sources.web3Metadata'
        default:
            return 'username'
    }
}
  



const dateFilterNull = {
    startDate: new Date(null), 
    endDate:new Date(null)
}




const getFilterCount = (appliedFilters) => {
    let filterCount = 0
    if(appliedFilters.length > 0){
        appliedFilters.forEach(filter => {
            if(filter.filter.length > 0){
                filterCount = filterCount + getHashFromArray(filter.filter)
            }
            if(new Date(filter.filter.startDate).getTime() > 0){
                filterCount = filterCount + new Date(filter.filter.startDate).getTime() + new Date(filter.filter.endDate).getTime()
            }
        })
    }
    return filterCount
}

const getHashFromArray = (array) => {
    let hash = 0
    if(typeof array === 'number') return array
    if(typeof array === 'string') return uniqueNumberFromString(array)

    if(array.length > 0){
        array.forEach(item => {
            hash = hash + uniqueNumberFromString(item)
        })
    }
    return hash
}

const uniqueNumberFromString = ( string ) => {
    var hash = 0, i, chr, len;
    if (string.length === 0) return hash;
    for (i = 0, len = string.length; i < len; i++) {
      chr   = string.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };