export const discordFiltersJson = [
    {
        label: 'Member Type',
        value: 'memberType',
        enums: [
            {label: 'Team Members', value: 'team-only'}, 
            {label: 'Active Community Members', value: 'members-only'}, 
            {label: 'Deleted Community Members', value: 'deleted-members'}, 
            {label: 'Discord Bots', value: 'bots-only'}, 
        ],
        icon: 'group-equal',
        isMulti: false,
        default: true,
    }, 
    {
        label: 'Connected Wallet',
        value: 'connectedWallet',
        enums: [{label: 'Connected', value: 'connected'}, {label: 'Not Connected', value: 'not-connected'}],
        icon: 'wallet',
        isMulti: false,
    }, 
    {
        label: 'Joined On',
        value: 'firstSeen',
        icon: 'calendar',
        type: 'DATE',
    }, 
    // {
    //     label: 'Last Seen',
    //     value: 'lastSeen',
    //     icon: 'calendar',
    //     type: 'DATE',
    // }, 
    {
        label: 'Member Tags',
        value: 'tags',
        enums: [{label: 'Abhsihek', value: 'abhsihek'}, {label: 'In Progress', value: 'as'}, {label: 'Done', value: 'done'}],
        icon: 'label',
        isMulti: true
    }, 
    {
        label: 'Discord Roles',
        value: 'discordRoles',
        enums: [],
        icon: 'group-equal',
        isMulti: true
    }, 
    {
        label: 'Source',
        value: 'source',
        enums: [{label: 'Mothership', value: '963005999973810226'}, {label: 'Intract', value: '969240073361055794'}],
        icon: 'company',
        isMulti: true
    }, 
    {
        label: 'Activity Level',
        value: 'level',
        enums: [
            {label: 'Top 1%', value: 'top-1'}, {label: 'Top 10%', value: 'top-10'}, {label: 'Top 25%', value: 'top-25'}, {label: 'Top 50%', value: 'top-50'},
            {label: 'Bottom 1%', value: 'bottom-1'}, {label: 'Bottom 10%', value: 'bottom-10'}, {label: 'Bottom 25%', value: 'bottom-25'}, {label: 'Bottom 50%', value: 'bottom-50'},
        ],
        icon: 'sms-active-outlined',
        isMulti: false
    }, 
    {
        label: 'Notes',
        value: 'notes',
        enums: [
            {label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}
        ],
        icon: 'notebook-bookmarked',
        isMulti: false
    } 
]


export const tokenFiltersJson = [
    {
        label: 'Member Type',
        value: 'memberType',
        enums: [
            {label: 'Team Members', value: 'team-only'}, 
            {label: 'Active Community Members', value: 'members-only'}, 
            {label: 'Deleted Community Members', value: 'deleted-members'}, 
            {label: 'Discord Bots', value: 'bots-only'}, 
        ],
        icon: 'group-equal',
        isMulti: false,
    }, 
    {
        label: 'Member Tags',
        value: 'tags',
        enums: [{label: 'Abhsihek', value: 'abhsihek'}, {label: 'In Progress', value: 'as'}, {label: 'Done', value: 'done'}],
        icon: 'label',
        isMulti: true
    }, 
    {
        label: 'Source',
        value: 'source',
        enums: [{label: 'Mothership', value: '963005999973810226'}, {label: 'Intract', value: '969240073361055794'}],
        icon: 'company',
        isMulti: true
    }, 
    {
        label: 'Notes',
        value: 'notes',
        enums: [
            {label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}
        ],
        icon: 'notebook-bookmarked',
        isMulti: false
    } 
]


export const overallFiltersJson = [
    {
        label: 'Member Type',
        value: 'memberType',
        enums: [
            {label: 'Team Members', value: 'team-only'}, 
            {label: 'Active Community Members', value: 'members-only'}, 
            {label: 'Deleted Community Members', value: 'deleted-members'}, 
            {label: 'Discord Bots', value: 'bots-only'}, 
        ],
        icon: 'group-equal',
        isMulti: false,
    },  
    {
        label: 'Member Tags',
        value: 'tags',
        enums: [{label: 'Abhsihek', value: 'abhsihek'}, {label: 'In Progress', value: 'as'}, {label: 'Done', value: 'done'}],
        icon: 'label',
        isMulti: true
    }, 
    {
        label: 'Discord Roles',
        value: 'discordRoles',
        enums: [],
        icon: 'group-equal',
        isMulti: true
    }, 
    {
        label: 'Source',
        value: 'source',
        enums: [{label: 'Mothership', value: '963005999973810226'}, {label: 'Intract', value: '969240073361055794'}],
        icon: 'company',
        isMulti: true
    }, 
    {
        label: 'Notes',
        value: 'notes',
        enums: [
            {label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}
        ],
        icon: 'notebook-bookmarked',
        isMulti: false
    } 
]




// export const initialFilters = [...filters].filter(item => item.default).map(item => ({
//     value: item.value, 
//     filter: item.defaultValues ? item.defaultValues : (item.type === 'DATE' ? dateFilterNull : item.isMulti ? [] : '')
// }))

const dateFilterNull = {
    startDate: new Date(null), 
    endDate:new Date(null)
}