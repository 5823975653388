import { useState, useCallback, useEffect } from "react";
import qs from 'query-string'



function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export default useQueryString;


export const getQueryStringValue = ( 
  key, 
  queryString = window.location.search
) => { 
  const values = qs.parse(queryString); 
  return values[key];
};


const setQueryStringValue = ( 
  key, 
  value, 
  queryString = window.location.search
) => { 
   const values = qs.parse(queryString); 
   const newQsValue = qs.stringify({...values, [key]: value }); 
   setQueryStringWithoutPageReload(`?${newQsValue}`);
};


const setQueryStringWithoutPageReload = qsValue => { 
  const newurl = window.location.protocol + "//" +
                 window.location.host + 
                 window.location.pathname + 
                 qsValue;

  window.history.pushState({ path: newurl }, "", newurl);
};





export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
      const observer = new IntersectionObserver(
          ([entry]) => {
              setState(entry.isIntersecting);
          }, { rootMargin }
      );

      element && observer.observe(element);

      return () => observer.unobserve(element);
  }, []);

  return isVisible;
};
