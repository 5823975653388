import axiosInstance from '../axios';



export const getSegmentsApi = async (workplace) => {
    try{
        const {data: {success, data}} = await axiosInstance.get('/workplace/segments')
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const getSegmentApi = async (segmentId) => {
    try{
        const {data: {success, data}} = await axiosInstance.get(`/workplace/segments/${segmentId}`)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}
export const deleteSegmentApi = async (segmentId) => {
    try{
        const {data: {success, data}} = await axiosInstance.delete(`/workplace/segments/${segmentId}`)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const getSegmentMembersApi = async (segmentId, urlParams) => {
    try{
        const {data: {success, data}} = await axiosInstance.get(`/workplace/segments/${segmentId}/members`, {
            params: urlParams
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


export const assignRoleToSegmentApi = async (segmentId, body) => {
    try{
        const {data: {success, data}} = await axiosInstance.post(`/workplace/segments/${segmentId}/members/assign/role`, body)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}

export const downloadSegmentMembersApi = async (segmentId) => {
    try{
        const {data: {success, data}} = await axiosInstance.get(`/workplace/segments/${segmentId}/members/download`)
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}



export const createSegmentApi = async (body) => {
    try{
        const {data: {success, data}} = await axiosInstance.post('/workplace/segments', {
            ...body
        })
        return data

    }catch(err){
        console.log(err)
        throw err
    }
}


