/* eslint-disable no-unused-expressions */
import $ from 'jquery';
import Chart from "chart.js";


(function (t) {
  window.HSCore = {
      defaults: {
          options: {
              responsive: !0,
              maintainAspectRatio: !1,
              legend: { display: !1 },
              tooltips: {
                  enabled: !1,
                  mode: "nearest",
                  prefix: "",
                  postfix: "",
                  hasIndicator: !1,
                  indicatorWidth: "8px",
                  indicatorHeight: "8px",
                  transition: "0.2s",
                  lineWithLineColor: null,
                //   yearStamp: !0,
              },
              gradientPosition: { x0: 0, y0: 0, x1: 0, y1: 0 },
          },
      },
      init: function (el, a) {
        let ele = $(el);
          if (ele.length) {
              var s = Object.assign({}, this.defaults);
              var n = ele.attr("data-hs-chartjs-options") ? JSON.parse(ele.attr("data-hs-chartjs-options")) : {},
                  i = {};


              i = extend(
                  !0,
                  n.type,
                  s,
                  "line" === n.type
                      ? {
                            options: {
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                callback: function (t, e, a) {
                                                    var s = i.options.scales.yAxes[0].ticks.metric,
                                                        n = i.options.scales.yAxes[0].ticks.prefix,
                                                        o = i.options.scales.yAxes[0].ticks.postfix;
                                                    return s && t > 100 && (t = t < 1e6 ? t / 1e3 + "k" : t / 1e6 + "kk"), n && o ? n + t + o : n ? n + t : o ? t + o : t;
                                                },
                                            },
                                        },
                                    ],
                                },
                                elements: {
                                    line: { borderWidth: 3 },
                                    point: {
                                        pointStyle: "circle",
                                        radius: 5,
                                        hoverRadius: 7,
                                        borderWidth: 3,
                                        hoverBorderWidth: 3,
                                        backgroundColor: "#ffffff",
                                        hoverBackgroundColor: "#ffffff",
                                    },
                                },
                            },
                        }
                      : "bar" === n.type
                      ? {
                            options: {
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                callback: function (t, e, a) {
                                                    var s = i.options.scales.yAxes[0].ticks.metric,
                                                        n = i.options.scales.yAxes[0].ticks.prefix,
                                                        o = i.options.scales.yAxes[0].ticks.postfix;
                                                    return s && t > 100 && (t = t < 1e6 ? t / 1e3 + "k" : t / 1e6 + "kk"), n && o ? n + t + o : n ? n + t : o ? t + o : t;
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        }
                      : {}
              );

              "line" ===
                  (i = extend(
                      !0,
                      i,
                      {
                          options: {
                              tooltips: {
                                  custom: function (t) {
                                      var a = document.getElementById("chartjsTooltip");
                                      if (
                                          (a ||
                                              (((a = document.createElement("div")).id = "chartjsTooltip"),
                                              (a.style.opacity = 0),
                                              a.classList.add("hs-chartjs-tooltip-wrap"),
                                              (a.innerHTML = '<div class="hs-chartjs-tooltip"></div>'),
                                              i.options.tooltips.lineMode ? ele.parent(".chartjs-custom").append(a) : document.body.appendChild(a)),
                                          0 === t.opacity)
                                      )
                                          return (a.style.opacity = 0), void a.parentNode.removeChild(a);
                                      if ((a.classList.remove("above", "below", "no-transform"), t.yAlign ? a.classList.add(t.yAlign) : a.classList.add("no-transform"), t.body)) {
                                          var s = t.title || [],
                                              n = t.body.map(function (t) {
                                                  return t.lines;
                                              }),
                                              o = new Date(),
                                              r = '<header class="hs-chartjs-tooltip-header">';
                                          
                                          
                                              s.forEach(function (t) {
                                                r = i.options.tooltips.yearStamp ? t + ", " + o.getFullYear() : t;
                                                return t;
                                            }),
                                              (r += '</header><div class="hs-chartjs-tooltip-body">'),
                                              n.forEach(function (e, a) {
                                                  r += "<div>";
                                                  var s = e[0],
                                                      n = s,
                                                      o = t.labelColors[a].backgroundColor instanceof Object ? t.labelColors[a].borderColor : t.labelColors[a].backgroundColor;
                                                  (r +=
                                                      (i.options.tooltips.hasIndicator
                                                          ? '<span class="d-inline-block rounded-circle mr-1" style="width: ' +
                                                            i.options.tooltips.indicatorWidth +
                                                            "; height: " +
                                                            i.options.tooltips.indicatorHeight +
                                                            "; background-color: " +
                                                            o +
                                                            '"></span>'
                                                          : "") +
                                                      i.options.tooltips.prefix +
                                                      (s.length > 3 ? n : e) +
                                                      i.options.tooltips.postfix),
                                                      (r += "</div>");
                                                  return ''
                                              }),
                                              (r += "</div>"),
                                              (a.querySelector(".hs-chartjs-tooltip").innerHTML = r);
                                      }
                                      var l = this._chart.canvas.getBoundingClientRect();
                                      (a.style.opacity = 1),
                                          i.options.tooltips.lineMode ? (a.style.left = t.caretX + "px") : (a.style.left = l.left + window.pageXOffset + t.caretX - a.offsetWidth / 2 - 3 + "px"),
                                          (a.style.top = l.top + window.pageYOffset + t.caretY - a.offsetHeight - 25 + "px"),
                                          (a.style.pointerEvents = "none"),
                                          (a.style.transition = i.options.tooltips.transition);
                                  },
                              },
                          },
                      },
                      n,
                      i,
                      a
                  )).type &&
                  i.data.datasets.forEach(function (t) {
                      if (Array.isArray(t.backgroundColor)) {
                          var a = ele[0].getContext("2d").createLinearGradient(i.options.gradientPosition.x0, i.options.gradientPosition.y0, i.options.gradientPosition.x1, i.options.gradientPosition.y1);
                          for (let e = 0; e < t.backgroundColor.length; e++) a.addColorStop(e, t.backgroundColor[e]);
                          t.backgroundColor = a;
                      }
                  });


                  
              var o = new Chart(ele, i);


              if ("line" === i.type && i.options.tooltips.lineMode) {
                  var r = o.draw;

                  (o.draw = function (e) {
                      if ((r.call(this, e), this.chart.tooltip._active && this.chart.tooltip._active.length)) {
                          this.chart.tooltip._active[0];
                          var a = t(this.chart.canvas),
                              s = t(".hs-chartjs-tooltip-wrap"),
                              n = t("#chartjsTooltipLine"),
                              o = i.options.tooltips.lineWithLineTopOffset >= 0 ? i.options.tooltips.lineWithLineTopOffset : 7,
                              l = i.options.tooltips.lineWithLineBottomOffset >= 0 ? i.options.tooltips.lineWithLineBottomOffset : 43;
                          t("#chartjsTooltip #chartjsTooltipLine").length || t("#chartjsTooltip").append('<div id="chartjsTooltipLine"></div>'),
                              s.css({ top: a.height() / 2 - s.height() }),
                              n.css({ top: -(s.offset().top - a.offset().top) + o }),
                              s.offset().left + s.width() > a.offset().left + a.width() - 100
                                  ? t(".hs-chartjs-tooltip").removeClass("hs-chartjs-tooltip-right").addClass("hs-chartjs-tooltip-left")
                                  : t(".hs-chartjs-tooltip").addClass("hs-chartjs-tooltip-right").removeClass("hs-chartjs-tooltip-left"),
                              n.length &&
                                  n.css({
                                      position: "absolute",
                                      width: "2px",
                                      height: a.height() - l,
                                      backgroundColor: i.options.tooltips.lineWithLineColor,
                                      left: 0,
                                      transform: "translateX(-50%)",
                                      zIndex: 0,
                                      transition: "100ms",
                                  });
                      }
                  }),
                      ele.on("mouseleave", function () {
                          t("#lineTooltipChartJSStyles").attr("media", "max-width: 1px");
                      }),
                      ele.on("mouseenter", function () {
                          t("#lineTooltipChartJSStyles").removeAttr("media");
                      }),
                      ele.on("mousemove", function (a) {
                          a.pageY - ele.offset().top > t(".hs-chartjs-tooltip-wrap").height() / 2 &&
                              a.pageY - ele.offset().top + t(".hs-chartjs-tooltip-wrap").outerHeight() / 2 < ele.height() &&
                              t(".hs-chartjs-tooltip").css({
                                  top: a.pageY + t(".hs-chartjs-tooltip-wrap").height() / 2 - (ele.offset().top + ele.height() / 2),
                              });
                      });
              }
              return {
                  chart: o,
                  options: i
              };
          }
      },
  };
})($);






var extend = function () {

	// Variables
	var extended = {};
	var deep = false;
	var i = 0;
	var length = arguments.length;

	// Check if a deep merge
	if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
		deep = arguments[0];
		i++;
	}

	// Merge the object into the extended object
	var merge = function (obj) {
		for ( var prop in obj ) {
			if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
				// If deep merge and property is an object, merge properties
				if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
					extended[prop] = extend( true, extended[prop], obj[prop] );
				} else {
					extended[prop] = obj[prop];
				}
			}
		}
	};

  if(typeof arguments[1] === 'object'){
    i++;
    extended = arguments[1]
  }

	// Loop through each object and conduct a merge
	for ( ; i < length; i++ ) {
		var obj = arguments[i];
		merge(obj);
	}

	return extended;

};



