import React from 'react'

const Text = ({align, marginBottom, title, subTitle}) => {
    return (
        <div class={`text-${align || 'center'} mb-${marginBottom || 3}`}>
            <h1 class="display-4">{title}</h1>
            <p>{subTitle}</p>
        </div>
    )
}




export const PageHeader = ({title, description, button, breadcrumb}) => {
    return (
        <div class="page-header">
            <div class="row align-items-end">
                <div class="col-sm mb-sm-0">
                    {breadcrumb && <Breadcrumb />}
                    <h1 class="page-header-title mb-0">{title}</h1>
                    {description && <p class="page-header-text mt-2">{description}</p>}
                </div>
                {button && <div class="col-sm-auto">
                    {button}
                </div>}
            </div>
        </div>
    )
}



const Breadcrumb = ({}) => {
    return (
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-no-gutter">
                <li class="breadcrumb-item"><a class="breadcrumb-link" href="javascript:;">Pages</a></li>
                <li class="breadcrumb-item"><a class="breadcrumb-link" href="javascript:;">Account</a></li>
                <li class="breadcrumb-item active" aria-current="page">Settings</li>
            </ol>
        </nav>
    )
}










export default Text
