import React, { useEffect, useState } from 'react'
import AuthLayout from 'components/Layout/Auth'
import Form from './Form'
import { useHistory } from "react-router-dom";
import toast from 'react-hot-toast'


const Login = (props) => {
 
    return (
        <AuthLayout>
            <Form {...props}/>
        </AuthLayout>
    )
}

export default Login


