import React, {useState} from 'react'
import { SimpleModal } from 'components/Models/Modal'

const SelectCoin = ({show, setShow}) => {

    const [searchResults, setSearchResults] = useState(['', '', ''])

    return (
        <SimpleModal
            show={show}
            setShow = {setShow}
            title = "Select a token"
            footer = {<Footer />}
        >
            <Search />
            <NativeCurrencies />
            <ul class='list-group list-group-flush mx-n3' >
                {searchResults.map(item => <SearchRow {...item}/>)}
            </ul>
        </SimpleModal>
    )
}

export default SelectCoin


const Search = () => {
    return (
        <div class="form-group">
            <div class="input-group input-group-merge mb-2 mb-sm-0">
                <div class="input-group-prepend" id="fullName">
                    <span class="input-group-text">
                        <i class="tio-search"></i>
                    </span>
                </div>
                <input type="text" class="form-control" name="fullName" placeholder="Search your token" />
            </div>
            <a class="btn btn-block btn-primary d-sm-none">Invite</a>
        </div>
    )
}


const Footer = () => {
    return (
        <div class="row align-items-center flex-grow-1 mx-n2">
            <div class="col-sm-8 mb-2 mb-sm-0">
                <input type="hidden" id="publicShareLinkClipboard" value="https://themes.getbootstrap.com/product/front-multipurpose-responsive-template/" />
                <p class="modal-footer-text">Cant find your token here?</p>
            </div>
            <div class="col-sm-4 text-sm-right">
                <a class="js-clipboard btn btn-sm btn-white text-nowrap">
                    <i class="tio-link mr-1"></i> Import your token
                </a>
            </div>
        </div>
    )
}



const SearchRow = () => {
    return (
        <li class='list-group-item list-group-item-action'>
            <div class="media">
                <div class="avatar avatar-sm avatar-circle avatar-soft-primary mr-3">
                    <span class="avatar-initials ">A</span>
                </div>
                <div class="media-body">
                    <div class="row align-items-center">
                        <div class="col-12 col-sm">
                            <h5 class="text-body mb-0">Ethers</h5>
                            <span class="d-block font-size-sm">Etherium</span>
                        </div>
                        <div class="col-12 col-sm">
                            <span class='d-sm-flex justify-content-sm-end'>
                                24 coins
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}



const NativeCurrencies = () => {
    return (
        <div class="alert py-3 alert-soft-secondary text-center card-alert mx-n4 mb-4" role="alert">
            <div class='mb-2 bold'>Native Currencies & Stablecoins</div>
            <div className='d-flex justify-content-around'>
                <div class='btn btn-soft-primary w-100 btn-sm mx-3'>Matic</div>
                <div class='btn btn-soft-primary w-100 btn-sm mx-3'>USDC</div>
                <div class='btn btn-soft-primary w-100 btn-sm mx-3'>DAI</div>
            </div>
        </div>
    )
}