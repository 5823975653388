import React, {useState, useEffect} from 'react'
import Dropdown, { BigDropdown } from 'components/Forms/Dropdown'
import {searchMemebrApi} from 'services/members'
import {getAvatar} from 'utilis/avatars'
import {Link, useHistory} from 'react-router-dom'
import { clickOutside } from 'utilis/helpers'
import { getDefaultAvatar } from 'components/Avatar'

const Search = () => {

    const history = useHistory()
    const [searchInput, setSearchInput] = useState('');
    const [results, setResults] = useState([])

    useEffect(() => {
        searchMembers(searchInput)
    }, [searchInput])

    useEffect(() => {
        setSearchInput('')
    }, [history.location])

    const searchMembers = async () => {
        try{
            const data = await searchMemebrApi(searchInput)
            setResults(data)
        } catch(err){
            console.log(err)
        }
    }
    

    return (
        <BigDropdown
            wrapperClassName = "position-relative" 
            popoverClassName = "navbar-dropdown-account py-0 overflow-hidden"
            popover = {<SearchResults 
                data = {results}
                searchInput=  {searchInput}
            />}
            style = {{width: '20rem'}}
            position = "left"
        >
            <div class="input-group input-group-merge input-group-borderless input-group-hover-light navbar-input-group" style = {{minWidth: '420px'}}>
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <i class="tio-search"></i>
                    </div>
                </div>
                <input 
                    type="search" 
                    class="js-form-search form-control" 
                    placeholder = "Search by username, nickname or discrimator"
                    value = {searchInput}
                    onChange = {e => setSearchInput(e.target.value)}
                />
                <span class="input-group-append" >
                    <span class="input-group-text">
                        <i class="tio-clear" style={{display: "none"}}></i>
                    </span>
                </span>
            </div>
        </BigDropdown>
    )
}


export default Search;


const SearchResults = ({data, searchInput}) => {

    const history = useHistory()

    return (
        <>
        <div class="card-body-height py-3">
            {(searchInput === '') && <div>
                <small class="dropdown-header mb-n2">Resources</small>
                <a class="dropdown-item my-2" href = "https://www.intract.buzz" target="_blank">
                    <div class="media align-items-center" >
                        <span class="icon icon-xs icon-soft-dark icon-circle mr-2">
                            <i class="tio-website"></i>
                        </span>
                        <span class="media-body text-truncate" >
                            <span>Check out Intract website!</span>
                        </span>
                    </div>
                </a>
                <div class="dropdown-divider my-3"></div>
            </div>}
            <small class="dropdown-header mb-n2">Members</small>

            {data.map(item => <Link class="dropdown-item my-2" to = {`/app/member/${item._id}`}>
                <div class="media align-items-center">
                    <div class='avatar avatar-xs avatar-primary avatar-circle mr-2'>
                        {(item.avatar && !item.avatar.includes('null')) ? <img class="avatar-img  mr-2" src={(item.avatar)} alt="" /> : 
                        <img class="avatar-img  mr-2" src={getDefaultAvatar(item.username)} alt="" />}
                    </div>
                    <div class="media-body text-truncate">
                        <span>{item.username}</span>
                    </div>
                </div>
            </Link>)}
        </div>
        <div class="card-footer text-center pointer btn-link" onClick = {() => {
            history.push(`/app/members?search=${searchInput}`)
            clickOutside()
        }}>
            See all results
            <i class="tio-chevron-right"></i>
        </div>
        </>

    )
}