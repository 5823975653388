import React, {useState, useEffect} from 'react'
import { StickyContainer, Sticky } from 'react-sticky';
import {useHistory} from 'react-router-dom'

const StickyNav = ({children, navMap}) => {
    return (
        <StickyContainer>
        <div class="row">
            <div class="col-lg-3">
                <div class="navbar-vertical navbar-expand-lg mb-3 mb-lg-5">
                    <button type="button" class="navbar-toggler btn btn-block btn-white mb-3" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navbarVerticalNavMenu" data-toggle="collapse" data-target="#navbarVerticalNavMenu">
                        <span class="d-flex justify-content-between align-items-center">
                            <span class="h5 mb-0">Nav menu</span>
                            <span class="navbar-toggle-default">
                                <i class="tio-menu-hamburger"></i>
                            </span>
                            <span class="navbar-toggle-toggled">
                                <i class="tio-clear"></i>
                            </span>
                        </span>
                    </button>
                    {true ? <Sticky>{({ style, isSticky }) =>
                        <div style = {true ? {...style, paddingTop: isSticky ? '80px' :'0px', transition: 'all 0.3s ease'} : {}}>
                            <Navbar navMap = {navMap}/>
                        </div>
                    }</Sticky> : <Navbar navMap = {navMap}/>}
                </div>
            </div>
            <div class="col-lg-9">
                {children}
                <div id="stickyBlockEndPoint"></div>
            </div>
        </div>
        </StickyContainer>
    )
}

export default StickyNav



const Navbar = ({navMap}) => {

    const history = useHistory();
    const [active, setActive] = useState(navMap[0].id)

    useEffect(() => {
        const a = new URLSearchParams(history.location.search).get("id")
        if(navMap.map(item => item.id).includes(a)){
            setActive(a)
            scrollIntoView(a)
        }
    }, [history.location.search])

    useEffect(() => {
        scrollListner();
        window.addEventListener('scroll', scrollListner)
        return () => window.removeEventListener('scroll', scrollListner)
    }, [])



    const scrollListner = () => {
        for(let i=navMap.length-1; i>-1; i--){
            const ele_id = (navMap[i].id);
            const ele = document.getElementById(ele_id);
            const isVisible = isInViewport(ele)
            if(isVisible) {
                setActive(ele_id);
                return;
            }
        }
    }

    const scrollIntoView = (id) => {
        const element = document.getElementById(id)
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - 80;
        window.scrollBy({
            top: offsetPosition,
            behavior: "smooth"
       });
    }

    return (
        <div  class="collapse navbar-collapse">
            <ul class="js-sticky-block js-scrollspy navbar-nav navbar-nav-lg nav-tabs card card-navbar-nav" >
                {navMap.map((item, index) => <li class="nav-item" key = {index}>
                    <span class={`nav-link mx-3 px-2 pointer ${active === item.id ? 'active' : ''}`} onClick={() => scrollIntoView(item.id)}>
                        <i class = {`${item.icon} nav-icon`}></i> 
                        {item.label}
                    </span>
                </li>)}
            </ul>
        </div>
    )
}


function isInViewport(element) {
    if(!element) return false
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
}
