import axiosInstance from '../axios';




export const getDiscordRolesApi = async () => {
    try{

        
        const {data: {success, data}} = await axiosInstance.get(`/data-sources/discord/sources/roles`)
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}



export const createDiscordRoleApi = async (discordId, role) => {
    try{

        const {data: {success, data}} = await axiosInstance.post(`/data-sources/discord/${discordId}/roles`, {
            ...role
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}


export const assignDiscordRoleApi = async (memberId, sourceId, roleId) => {
    try{

        const {data: {success, data}} = await axiosInstance.post(`/data-sources/discord/members/${memberId}/discord/${sourceId}/role/${roleId}`)
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}



export const unassignDiscordRoleApi = async (memberId, sourceId, roleId) => {
    try{

        const {data: {success, data}} = await axiosInstance.delete(`/data-sources/discord/members/${memberId}/discord/${sourceId}/role/${roleId}`)
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}




export const assignBulkDiscordRoleApi = async (memberIds, roleId, discordId) => {
    try{

        const {data: {success, data}} = await axiosInstance.put(`/data-sources/discord/members/roles`, {
            memberIds: memberIds,
            roleId: roleId,
            discordId
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}



export const unassignBulkDiscordRoleApi = async (memberIds, roleId) => {
    try{

        const {data: {success, data}} = await axiosInstance.delete(`/data-sources/discord/members/roles`, {
            memberIds: memberIds,
            roleId: roleId
        })
        return data

    }catch(err){
        console.log(err)
        throw err;
    }
}


