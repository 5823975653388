import React from 'react'
import AuthLayout from 'components/Layout/Auth'
import Form from './Form'

const NewPassword = () => {
    return (
        <AuthLayout>
            <Form />
        </AuthLayout>
    )
}

export default NewPassword


