const data = [
    {
        "label": "Team",
        "value": "team",
        "enums": [{"label": "Team Members", "value": "team-only"}, {"label": "Community Members", "value": "member-only"}],
        "icon": "group-equal",
        "default": true
    }, 
    {
        "label": "Member Tags",
        "value": "memberTags",
        "enums": [],
        "icon": "label",
        "default": true,
        "isMulti": true
    }, 
    {
        "label": "Message Date",
        "value": "timestamp",
        "icon": "calendar",
        "type": "DATE",
        "default": true
    }, 
    {
        "label": "Channels",
        "value": "channels",
        "enums": [
            {"label": "Yes", "value": "yes"}, {"label": "No", "value": "no"}
        ],
        "icon": "hashtag",
        "isMulti":true
    }, 
    {
        "label": "Source",
        "value": "source",
        "enums": [],
        "icon": "company",
        "default": true,
        "isMulti": true
    },
    {
        "label": "Replies",
        "value": "replies",
        "enums": [
            {"label": "Yes", "value": "yes"}, {"label": "No", "value": "no"}
        ],
        "icon": "company",
        "default": false
    },
    {
        "label": "Sentiment",
        "value": "sentiment",
        "enums": [
            {"label": "Positive", "value": "positive"}, {"label": "Negative", "value": "negative"}
        ],
        "icon": "company",
        "default": false
    }
]

export default data

export const initialFilters = data.filter(item => item.default).map(item => ({
    value: item.value, 
    filter: item.defaultValues ? item.defaultValues : (item.type === 'DATE' ? {
        startDate: new Date(0),
        endDate:  new Date(0)
    } : item.isMulti ? [] : '')
}))
