import React, {useState, useEffect, useContext} from 'react'
import Header from './components/Header'
import Details from './components/Details'
import Rewards from './components/Rewards'
import Confirmation from './components/Confirmation'
import Evaluation from './components/Evaluation'
import {useLocation} from 'react-router-dom'
import getTemplate, {typeOptions} from './utilis/getTemplate'
import moment from 'moment'
import {editQuestApi, getQuestApi} from 'services/quests'
import toast from 'react-hot-toast'
import { updateQuests } from 'store/actions/workplace'
import {Store} from 'store'


const EditIdConnectCampaign = (props) => {

    const {state, dispatch} = useContext(Store);
    const [step, setStep] = useState('details');
    const [quest, setQuest] = useState({
        title: '',
        mission: '',
        guide: '',
        reward: {
            xpValue: '10',
            tokenSymbol: '',
            tokenValue: '',
            autoXp: true,
        },
        startQuest: {
            type: 'DISCORD_COMMAND',
            metadata: {}
        },
        evaluation: {
            type: '',
            metadata: {},
        },
    })

    useEffect(() => {
        if(state.workplace.quests){
            const quest_ = state.workplace.quests.find(item => item._id === props.match.params.id)
            if(quest_) setQuest({
                title: quest_.title,
                mission: quest_.mission,
                guide: quest_.guide,
                reward: quest_.reward,
                startQuest: quest_.startQuest,
                evaluation: quest_.evaluation,
                type: quest_.type
            }) 
            else {
                getQuest();
            }
        }
        else getQuest();
    }, [props.match.params.id])



    const getQuest = async () => {
        try {
            const data = await getQuestApi(props.match.params.id)
            setQuest({
                title: data.title,
                mission: data.mission,
                guide: data.guide,
                reward: data.reward,
                startQuest: data.startQuest,
                evaluation: data.evaluation,
                type: data.type,
        
            })
        } catch (err) {
            console.log(err)
        }
    }
    



    const editQuest = async () => {
        try{
            console.log(quest)
            const quest_ = await editQuestApi(props.match.params.id, {
                ...quest,
            });
            toast.success("Quest updated successfully");
            if(state.workplace.quests && quest_){
                const allQuests = [...state.workplace.quests]
                const index = allQuests.findIndex(item => item._id === quest_._id)
                if(index !== -1){
                    allQuests[index] = quest_
                }
                updateQuests(allQuests, dispatch)
            }
            props.history.push(`/app/id-connect/`)


        } catch(err){
            toast.error("Error updating campaign");
            console.log(err)
        }
    }

    

    return (
        <div class='row justify-content-center mt-5'>
            <div class='col-lg-10'>
                <Header step = {step}/>
                {step === 'details' && <Details 
                    setStep={setStep}
                    quest = {quest}
                    setQuest = {setQuest}
                    nextStep = "evaluation"
                />}
                {step === 'evaluation' && <Evaluation 
                    setStep={setStep}
                    quest = {quest}
                    setQuest = {setQuest}
                    prevStep = "details"
                    nextStep = "rewards"
                />}
                {step === 'rewards' && <Rewards 
                    setStep={setStep}
                    quest = {quest}
                    setQuest = {setQuest}
                    prevStep = "evaluation"
                    nextStep = "confirm"
                />}
                {step === 'confirm' && <Confirmation 
                    setStep={setStep}
                    quest = {quest}
                    setQuest = {setQuest}
                    editQuest = {editQuest}
                    prevStep = "rewards"
                />}
            </div>
        </div>
    )
}

export default EditIdConnectCampaign



