import { PageHeader } from 'components/Text'
import React, {useEffect, useState} from 'react'
import SelectField from 'components/Forms/Select'
import {InputField} from 'components/Forms/TextInput'
import { DatePicker } from 'components/Forms/Datepicker'
import {Button} from 'components/Forms/Buttons'
import {createSegmentApi} from 'services/workplace/segments'
import SegmentComponent from './components/SegmentComponent'
import validations from 'utilis/validation'
import { useHistory } from 'react-router-dom'

const CreateSegment = (props) => {

	const location = useHistory()
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [criterias, setCriterias] = useState([]);
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState({name: false, description: false, criterias: {}, noCriterias: false});


	useEffect(() => {
		const newErrors = {...errors};
		for(let key in errors.criterias){
			if(errors.criterias[key]){
				newErrors.criterias[key] = false;
			}
		}
		setErrors(prev => ({...newErrors, name: false, description: false, noCriterias: false}))
	}, [name, description, criterias])

	const saveSegment = async () => {
		try{
			if(!validate()) return;
			setLoading(true)
			const segment_ = await createSegmentApi({
				name,
				description,
				criterias
			})
			location.push(`/app/segments/${segment_._id}`)
			setLoading(false)
		} catch(err){
			console.log(err)
			setLoading(false)
		}
	}

	const validate = () => {
		let errors_ = {...errors};
		if(!name) errors_.name = true;
		if(!description) errors_.description = true;
		const criteriaIds = criterias.map(item => item.id)
		if(criteriaIds.length === 0) errors_.noCriterias = true;

		for(let i=0; i<criteriaIds.length; i++){
			const id = criteriaIds[i];
			const criteria = criterias.find(item => item.id === id);
			if(!criteria.attribute || !criteria.value) errors_.criterias[id] = true;
		}
		setErrors(errors_)
		console.log(errors_)
		if(errors_.name || errors_.description) return false;
		if(errors_.noCriterias) return false;
		for(let key in errors_.criterias){
			if(errors_.criterias[key]) return false;
		}
		return true;
	}

  	return (
    <div class='row justify-content-center'>
		<div class='col-12 col-lg-10'>
			<div class='card'>
				<div class='card-header'>
					<div className='h3 mb-0'>Create a segment</div>
				</div>
				<div class='card-body'>

					<InputField 
						label = "Segment name"
						type = "text"
						placeholder = "Most active members"
						value = {name}
						setValue = {e => setName(e)}
						errorText = "Please enter a name for your segment"
						error = {errors.name}
						setError = {e => setErrors(prev => ({...prev, name: e}))}
						labelClass=""
					/>
					
					<InputField 
						label = "Segment description"
						type = "text"
						placeholder = "Members who had top 10% activity during Mar-July 2022 period"
						value = {description}
						setValue = {e => setDescription(e)}
						errorText = "Please enter a description for your segment"
						error = {errors.description}
						setError = {e => setErrors(prev => ({...prev, description: e}))}
						labelClass=""
					/>

					<hr class='pb-2'/>
					<SegmentComponent 
						criterias = {criterias}
						setCriterias = {setCriterias}
						errors = {errors.criterias}
						setErrors = {e => setErrors(prev => ({...prev, criterias: e}))}
					/>

					{errors.noCriterias && <div class='invalid-feedback d-block'>Please add at least one criteria</div>}

				</div>
				<div class='card-footer text-right'>
					<div class='btn mr-2 btn-soft-danger' onClick={() => location.push('/app/segments')}>Cancel</div>
					<Button
						text = "Create Segment"
						loading = {loading}
						submitResponse = {saveSegment}
					/>
				</div>
			</div>
		</div>
    </div>
  )
}

export default CreateSegment














//user attributes
//activity attributes
//wallet attributes


