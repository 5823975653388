
import { FETCH_USER, FETCH_WORKPLACE, LOGIN_SUCCESS, LOGOUT_SUCCESS } from "../actions/auth";

const authReducer = (state, action) => {
    switch (action.type) {
        case FETCH_USER:
            return {
                ...state,
                data: action.payload.data,
            }
        case FETCH_WORKPLACE:
            return {
                ...state,
                workplace: action.payload.data,
            } 
        case LOGIN_SUCCESS:
            return {
                data: action.payload.data,
                workplace: action.payload.workplace,
                isLoggedIn: true,
            } 
        case LOGOUT_SUCCESS:
            return {
                data: {},
                workplace: {},
                isLoggedIn: false,
            } 
        default:
        return state;
    }
}

export default authReducer;

