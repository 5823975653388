import React from 'react'
import DashboardView from 'pages/Dashboard/Layout'
import {Link} from 'react-router-dom'

const Complete = () => {
    return (
        <DashboardView>
            <div class="row justify-content-sm-center text-center py-10">
                <div class="col-sm-7 col-md-5">
                    <img class="img-fluid mb-5" src="/assets/svg/illustrations/graphs.svg" alt="" style={{maxWidth: "21rem"}} />
                    <h1>Hello, nice to see you!</h1>
                    <p>You are now minutes away from creativity than ever before. Enjoy!</p>
                    <Link to = "/" className = 'btn btn-primary'>Create my first campaign</Link>
                </div>
            </div>
        </DashboardView>
    )
}

export default Complete
